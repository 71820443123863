import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ACCOUNT_TYPES_MAP, DEBT_PENALTY_2_ADD_FIRST_INVOICE } from 'utils/constants';
import request from 'helpers/request';

export default function ListItem(props) {
	const [isCompleted, setCompleted] = useState(props.email.isCompleted);

	const getUserName = (user) => user.accountType === ACCOUNT_TYPES_MAP.legal ?
		user.company :
		`${user.firstName} ${user.lastName}`;

	const markAsCompleted = async () => {
		await request('POST', 'iqacdocument/martk-as-completed', { id: props.email._id });
		setCompleted(true);
	};

	const getTextByNotificationPeriod = (notificationPeriod) => {
		switch (notificationPeriod) {
		case DEBT_PENALTY_2_ADD_FIRST_INVOICE:
			return props.languageSchema.HomePage.issueTheFirstPenaltyInvoice;
		default:
			return props.languageSchema.HomePage.unknownNotification;
		}
	};

	return (
		<tr className="text-center" key={props.email._id}>
			<td>
				{(props.email.userId && props.email.userId._id) ?
					<Link to={`/users/${props.email.userId._id}`}>
						{getUserName(props.email.userId)}
					</Link> :
					'-'
				}
			</td>


			<td>
				{props.email.documentID}
			</td>

			<td>
				{getTextByNotificationPeriod(props.email.notificationPeriod)}
			</td>

			<td>
				{
					isCompleted ?
						<div className="text-success">
							<i className="fas fa-check" /> {' '}
							{props.languageSchema.HomePage.completed}
						</div> :
						<button onClick={markAsCompleted} className="btn btn-secondary btn-sm mx-2">
							{props.languageSchema.HomePage.markAsCompleted}
						</button>
				}
			</td>
		</tr>
	);
}
