export default [
	{
		alias: 'cities',
		href: '/cities',
		permissions: ['superUser']
	},
	{
		alias: 'stocks',
		href: '/stocks',
		permissions: ['superUser']
	},
	// {
	//		 alias: 'Клиенты',
	//		 href: '/clients',
	//		 isDisabled: true,
	//		 permissions: ['manager', 'admin', 'superUser']
	// },
	{
		alias: 'accounts',
		href: '/users',
		permissions: ['superUser']
	},
	// {
	// 	alias: 'reports',
	// 	href: '/reports',
	// 	isDisabled: true,
	// 	permissions: ['superUser']
	// },
	{
		alias: 'myBoxes',
		href: '/my-boxes',
		permissions: ['client'],
		icon: 'cubes'
	},
	{
		alias: 'support',
		href: '/support',
		permissions: ['client'],
		icon: 'headset',
	},
	{
		alias: 'appeals',
		href: '/appeal',
		permissions: ['superUser'],
	},
	// {
	// 	alias: 'notifications',
	// 	href: '/notifications',
	// 	permissions: ['superUser'],
	// },
	// {
	// 	alias: 'monthlyBills',
	// 	href: '/monthly-bills',
	// 	permissions: ['superUser'],
	// },
	{
		alias: 'book',
		href: '/book',
		permissions: ['superUser', 'client'],
		icon: 'warehouse',
	},
];
