import React, { useState } from 'react';

import Heading from 'components/UI/Heading';
import request from 'helpers/request';

export default function StripeKeys(props) {
	let [isLoading, setLoading] = useState(false);
	let [stripePublicKey, setStripePublicKey] = useState(props.stock.stripePublicKey || '');
	let [stripePrivateKey, setStripePrivateKey] = useState(props.stock.stripePrivateKey || '');

	let saveForm = async () => {
		setLoading(true);

		try {
			await request('PUT', `stocks/${props.stock._id}`, {
				stripePublicKey,
				stripePrivateKey,
			});

			setLoading(true);
		} catch(error) {
			setLoading(false);
		}

		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};

	let { languageSchema, isSuperUser } = props;

	return (
		<div className="row">
			<div className="col-md-12">
				<Heading>Stripe API Keys:</Heading>

				<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
					<label htmlFor="insuranceCoverageAmount" className="col-sm-2 col-form-label">
						Public key:
					</label>
					<div className="col-sm-10">
						<div className="input-group">
							<input
								name="stripePublicKey"
								placeholder='Public Key'
								className="form-control"
								value={stripePublicKey}
								disabled={!isSuperUser}
								onChange={(e) => setStripePublicKey(e.target.value)}
							/>
						</div>
					</div>
				</div>

				<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
					<label htmlFor="insuranceCoverageAmount" className="col-sm-2 col-form-label">
						Private key:
					</label>
					<div className="col-sm-10">
						<div className="input-group">
							<input
								name="stripePrivateKey"
								placeholder='Private Key'
								className="form-control"
								value={stripePrivateKey}
								disabled={!isSuperUser}
								onChange={(e) => setStripePrivateKey(e.target.value)}
							/>
						</div>
					</div>
				</div>

				<div style={{ textAlign: 'left' }}>
					<button
						className="btn btn-success"
						onClick={saveForm}
						disabled={!isSuperUser}
					>
						{isLoading && <span className="spinner-border spinner-border-sm"> </span>}
						<span> {languageSchema.Buttons.apply} </span>
					</button>
				</div>

			</div>
		</div>
	);
}
