import {
	BOX_BOOKING_SET_ARIVIAL_DATE,
	BOX_BOOKING_TOGGLE_CONTRACT_ACCEPT,
	BOX_BOOKING_SET_ERROR,
	BOX_BOOKING_DEL_ERROR,
} from '../actionTypes';

const initialState = {
	arrivalDate: null,
	areDocsConfirmed: false,
	errors: {},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case BOX_BOOKING_SET_ARIVIAL_DATE:
		return Object.assign({}, state, { arrivalDate: action.payload.arrivalDate, errors: {} });
	case BOX_BOOKING_TOGGLE_CONTRACT_ACCEPT:
		return Object.assign({}, state, { areDocsConfirmed: !state.areDocsConfirmed, errors: {} });
	case BOX_BOOKING_SET_ERROR:
		return Object.assign({}, state, { errors: { [action.payload.errorType]: true } });
	case BOX_BOOKING_DEL_ERROR:
		return Object.assign({}, state, { errors: {} });
	default:
		return state;
	}
}
