import React from 'react';
import moment from 'moment';
import { Field, Form } from 'formik';
import { Link } from 'react-router-dom';

import { DATE_FORMAT } from 'utils/constants';
import CountryPicker from 'components/common/CountryPicker';

function CityForm(props) {
	const { type, values, touched, errors, isSubmitting, isSuccess, language, languageSchema } = props;

	let isError = field => errors[field] && touched[field];
	let isEdit = type === 'edit';
	let buttonText = isEdit ? languageSchema.Buttons.save : languageSchema.Buttons.create;

	return (
		<Form>
			<div className="form-group">
				<label htmlFor="country" className="col-form-label">
					{languageSchema.Cities.country}
				</label>
				<div>
					<Field
						component={CountryPicker}
						id="country"
						name="country"
						className={`form-control ${isError('country') ? 'is-invalid' : ''}`}
						value={values.country}
						languageSchema={languageSchema}
						language={language}
					/>
					<div>
						{isError('country') &&
							<div className="invalid-feedback">
								{errors.country}
							</div>}
					</div>
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="city" className="col-form-label">
					{languageSchema.Cities.city}
				</label>
				<div>
					<Field
						type="text"
						className={`form-control ${isError('city') ? 'is-invalid' : ''}`}
						id="city"
						name="city"
						value={values.city}
					/>
					{isError('city') &&
						<div className="invalid-feedback">
							{errors.city}
						</div>}
				</div>
			</div>

			<hr />
			
			<div>{languageSchema.Cities.cityCenterCoordinates}</div>

			<div className="form-group">
				<label htmlFor="latitude" className="col-form-label">
					{languageSchema.Cities.latitude}
				</label>
				<div>
					<Field
						type="text"
						className={`form-control ${isError('latitude') ? 'is-invalid' : ''}`}
						id="latitude"
						name="latitude"
						value={values.latitude}
					/>
					{isError('latitude') &&
						<div className="invalid-feedback">
							{errors.latitude}
						</div>}
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="longitude" className="col-form-label">
					{languageSchema.Cities.longitude}
				</label>
				<div>
					<Field
						type="text"
						className={`form-control ${isError('longitude') ? 'is-invalid' : ''}`}
						id="longitude"
						name="longitude"
						value={values.longitude}
					/>
					{isError('longitude') &&
						<div className="invalid-feedback">
							{errors.longitude}
						</div>}
				</div>
			</div>

			{isEdit &&
				<div className="form-group">
					<label htmlFor="created" className="col-form-label">
						{languageSchema.Etc.createdAt}
					</label>
					<div>
						<input type="text" readOnly className="form-control-plaintext" id="created"
							value={values.created && moment(values.created).format(DATE_FORMAT)} />
					</div>
				</div>
			}

			<div>
				<div className="col-auto">
					<button type="submit" className="btn btn-sm btn-secondary mr-3" disabled={isSubmitting}>
						{isSubmitting ? languageSchema.Etc.wait : buttonText}
					</button>
					<Link to="/cities" className="btn btn-sm btn-outline-secondary">
						{languageSchema.Buttons.back}
					</Link>
				</div>
				{isEdit &&
					<div className="col text-right">
						<i>{values.updated ?
							languageSchema.Etc.lastUpdate + ' ' + moment(values.updated).format(DATE_FORMAT) :
							languageSchema.Etc.noUpdates}</i>
					</div>
				}
			</div>
			{isSuccess &&
				<div className="alert alert-success mt-3" role="alert">
					{languageSchema.Etc.success}
				</div>
			}
		</Form>
	);
}

export default CityForm;
