import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import DatePicker from 'components/common/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import BoxBookingContext from 'screens/BoxBookingNew/Context/';

import { handleKeyboardDisabled } from 'helpers/common';

import './styles.css';

function ChoiceOfArrivalDate(props) {
	const boxBookingContext = React.useContext(BoxBookingContext);
	const { bookingMeta: { arrivalDate, isArrivalDateNotChosenError } } = boxBookingContext;

	let handleArrivalDateSelection = (arrivalDate) => {
		boxBookingContext.setArrivalDate(arrivalDate);
	};

	return (
		<div id='arrival_date'>
			<div style={{ fontSize: '20px', marginBottom: '1px' }}>{props.title}</div>
			<p style={{ fontSize: '1rem' }}>{props.text}?</p>
			<div className={`input-container ${isArrivalDateNotChosenError ? 'error' : ''}`}>
				<DatePicker
					wrapperClassName="choice-of-arrival-date"
					language={props.language}
					selected={arrivalDate}
					minDate={moment().toDate()}
					maxDate={moment().add(1, 'months').subtract(1, 'days').toDate()}
					onChange={handleArrivalDateSelection}
					placeholderText={props.placeholderText}
					dateFormat="dd.MM.yyyy"
					onKeyDown={handleKeyboardDisabled}
				/>
			</div>
		</div>
	);
}

let mapStateToProps  = (state) => ({
	language: state.common.language,
});

export default connect(mapStateToProps)(ChoiceOfArrivalDate);
