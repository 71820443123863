import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TopboxLogo from 'assets/images/topbox_large_logo.png';
import { getLanguageSchema } from 'dashboard-commons/languages';

let SomethingWentWrong = (props) => {
	let { languageSchema } = props;

	return (
		<div className="row mt-5">
			<div className="card col-md-6 offset-md-3">
				<div className="text-center">
					<img
						src={TopboxLogo}
						alt="Rentabox"
						title="Rentabox"
						width="200" 
					/>
				</div>
				<hr />
				<div className="text-center text-secondary">
					<i className="fas fa-exclamation-circle text-danger fs-9"></i>
					<h2 className="mt-4 mb-2 text-danger">
						{languageSchema.Etc.somethingWentWrong}
					</h2>
					<h6 className="mb-4 mt-2" dangerouslySetInnerHTML={{ __html: languageSchema.Etc.somethingWentWrongDescription }}>
					</h6>
				</div>
				<div className="text-center">
					<Link to="/" className="btn btn-md btn-success mr-1">
						{languageSchema.HomePage.toHome}
					</Link>
					<Link to="/support" className="btn btn-md btn-info ml-1">
						{languageSchema.HomePage.support}
					</Link>
				</div>
			</div>
		</div>
	);
};

let mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};

export default connect(mapStateToProps)(SomethingWentWrong);
