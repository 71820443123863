import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import BoxForm from 'components/Box/Form';
import Heading from 'components/UI/Heading';
import validationSchema from './validation';
import { timeout } from 'utils/functions';
import { fetchStocks, createBox } from 'redux/actions';

class BoxCreatePage extends React.Component {
		state = {
			isSuccess: false,
		}
		
		componentDidMount() {
			const {
				isFetchedStocks,
				fetchStocks
			} = this.props;

			if (!isFetchedStocks) {
				fetchStocks();
			}
		}

		handleSubmit = async (values, { setSubmitting }) => {
			try {
				await this.props.createBox(values);
				this.setState({ isSuccess: true }, async () => {
					await timeout(2000);
					this.setState({ isSuccess: false });
				});
			} finally {
				setSubmitting(false);
			}
		}

		render() {
			const { stocks } = this.props;

			return (
				<div className="row py-3">
					<div className="col-md-6 offset-md-3">
						<Heading>Создание бокса</Heading>
						<Formik
							initialValues={{ size: 1, description: '', stockId: '', lockId: '' }}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema()}
							render={props => <BoxForm type="create" stocks={stocks} {...props} />}
						/>
					</div>
				</div>
			);
		}
}

const mapStateToProps = ({ stocks }) => {
	return {
		stocks: stocks.data,
		isFetchedStocks: stocks.isFetched,
	};
};
export default connect(mapStateToProps, { fetchStocks, createBox })(BoxCreatePage);