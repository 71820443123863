import React, { Component } from 'react';
import { Tooltip, Modal } from 'antd';
import Checkbox from 'react-simple-checkbox';
import querystring from 'querystring';
import { InfoCircleOutlined } from '@ant-design/icons';

import { apiStartLoading, apiEndLoading, setError } from 'redux/actions/common';
import Heading from 'components/UI/Heading';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { connect } from 'react-redux';
import request from 'helpers/request';
import { timeout } from 'utils/functions';
import { round } from 'helpers/amount';
import InvoiceItem from 'components/Contract/InvoiceListItem';
import Loader from 'components/common/OverlayLoader';
import { ACCOUNT_TYPES_MAP, ROLES_MAP, DEF_BLUE_MEDIA_PROCESSOR } from 'utils/constants';

class InvoicesList extends Component {
	state = {
		areInvoicesLoaded: false,
		invoices: [],
		paymentProcessor: null,
		isAutomaticPayments: false,
		paymentResponse: null,
		sendedNotifications: [],
		isConfirmationModalOpen: false,
		isConfirmInfoAccepted: false,
	};

	async componentDidMount() {
		const ADMIN_ROLES = [ROLES_MAP.superUser];

		await this.fetchInvoices();
	}

	showConfirmationModal = () => {
		this.setState({ isConfirmationModalOpen: true, isConfirmInfoAccepted: false });
	};

	hideConfirmationModal = () => {
		this.setState({ isConfirmationModalOpen: false, isConfirmInfoAccepted: false });
	};

	fetchInvoices = async () => {
		let { contract } = this.props;

		const { closed } = querystring.parse(window.location.search.slice(1));

		let invoices = await request('GET', `contracts/${contract.ContractID}/invoices${closed === 'true' ? '?closed=true' : ''}`);

		this.setState({ invoices, areInvoicesLoaded: true });

		return invoices;
	}

	handleCancelSubscriptionButton = async () => {
		try {
			this.props.apiStartLoading();

			await request('POST', `payments/subscription/deactivate/${this.props.contract.subscriptionId.subscriptionId}?paymentMethod=${DEF_BLUE_MEDIA_PROCESSOR}`);
			await timeout(3000);
		} catch (e) {
			this.props.setError(e.message);
		} finally {
			this.props.apiEndLoading();

			this.props.fetchContract();
		}
	};

	handleInitSubscriptionButton = async () => {
		try {
			this.props.apiStartLoading();

			let result = await request('POST', `payments/subscription/activate?paymentMethod=${DEF_BLUE_MEDIA_PROCESSOR}&ContractID=${this.props.contract.ContractID}`);
			if (result.redirectionUrl) {
				window.location = result.redirectionUrl;
			}
		} catch (e) {
			this.props.setError(e.message);
		} finally {
			this.props.apiEndLoading();
			this.hideConfirmationModal();
			this.props.fetchContract();
		}
	};

	togglePaymentProcessor = (paymentProcessor) => this.setState({ paymentProcessor });

	toggleAutomaticPayments = () => this.setState({ isAutomaticPayments: !this.state.isAutomaticPayments });

	handlePayButton = async (paymentParams) => {
		try {
			this.props.apiStartLoading();

			let result = await request('POST', 'payments', paymentParams);

			if (result.paymentData) {
				this.setState({ paymentResponse: result.paymentData });
			} else {
				if (result.redirectionUrl) {
					window.location = result.redirectionUrl;
				}
			}
		} catch (e) {
			this.props.setError(e.message);
		} finally {
			this.props.apiEndLoading();
		}
	}

	render() {
		let { languageSchema, language, user, contract } = this.props;
		let { invoices, areInvoicesLoaded, sendedNotifications } = this.state;

		let ADMIN_ROLES = [ROLES_MAP.superUser];

		let isUserAdmin = user.role && ADMIN_ROLES.includes(user.role);

		let filteredInvoices = isUserAdmin ?
			invoices :
			invoices.filter(({ DocumentType, Amount }) => {
				let isLateCharge = DocumentType.includes('Late Charge');// can be Late Charge 1, 2,3 and etc
				let isZeroAmount = Amount === 0;

				return ['Invoice', 'Deposit'].includes(DocumentType) || (isLateCharge && !isZeroAmount);
			});

		let vatRate = user.accountType === ACCOUNT_TYPES_MAP.legal ?
			contract.VATRate : 0;

		return (
			<div className="card">
				<div className="mb-1">
					{
						(!this.props.contract.subscriptionId || !this.props.contract.subscriptionId.isActive) &&
							<div className="text-right ml-2">
								<div className="btn btn-sm btn-success mt-1" onClick={this.showConfirmationModal}>
									{languageSchema.Payment.initSubscription}
								</div>
								<Tooltip className="pointer ml-1" placement='bottom' title={languageSchema.Payment.subscriptionInfo}>
									<div className="btn btn-sm btn-success mt-1">
										<InfoCircleOutlined />
									</div>
								</Tooltip>
							</div>
					}
					<Heading className="d-flex justify-content-between align-items-start">
						{languageSchema.Contract.invoices}
					</Heading>
				</div>

				{
					!areInvoicesLoaded &&
				<div className="text-center">
					<i className="fas fa-2x fa-spinner fa-spin"></i>
				</div>
				}

				<Loader />

				{
					this.props.contract && this.props.contract.subscriptionId && this.props.contract.subscriptionId.isActive &&
				<div style={{ fontSize: 19 }} className="font-green">
					<p>{languageSchema.Payment.subscriptionIsActive}</p>
				</div>
				}

				{
					(contract.ContractID && filteredInvoices.length > 0) &&
				<div className="accordion" id="accordionExample">
					{
						filteredInvoices.map(invoice =>
							<InvoiceItem
								fetchInvoices={this.fetchInvoices}
								manualPay={() => this.handlePayButton({
									userId: contract.userId._id,
									DocumentID: invoice.DocumentId || invoice.DocumentID,
									boxId: contract?.boxId?._id,
									amount: round(invoice.debt - (invoice.VATAmount || 0)),
									totalAmount: invoice.debt,
									vatAmount: invoice.VATAmount || 0,
									discountAmount: 0,
									discountPercent: 0,
									currency: contract.CurrencyCode,
									vatRate,
									processor: 'manual',
								})}
								key={invoice.DocumentId || invoice.DocumentID}
								stockId={contract.SiteID}
								contract={contract}
								boxNumber={contract.UnitNumber}
								languageSchema={languageSchema}
								currency={contract.CurrencyCode}
								invoice={invoice}
								language={language}
								isSuperUser={isUserAdmin}
								payment={invoices
									.find(({ InvoiceID, DocumentType }) =>
										InvoiceID === invoice.InvoiceID && DocumentType === 'Payment')
								}
								invoiceEmailSending={
									sendedNotifications.find(n => n.invoiceId_sm === (invoice.DocumentId || invoice.DocumentID))
								}
								sendInvoiceEmailCallback={() => {}}
								setError={this.props.setError}
							/>
						)
					}
				</div>
				}

				{
					this.props.contract && this.props.contract.subscriptionId && this.props.contract.subscriptionId.isActive &&
						<div className="text-center ml-2">
							<div className="btn btn-success mt-4" onClick={this.handleCancelSubscriptionButton}>
								{languageSchema.Payment.cancelSubscription}
							</div>
						</div>
				}
				<Modal footer={null} title="Subscription confirmation" visible={this.state.isConfirmationModalOpen} onCancel={this.hideConfirmationModal}>
					<div className="d-flex">
						<Checkbox
							color="#3cab26"
							size={3}
							tickAnimationDuration={100}
							backAnimationDuration={100}
							borderThickness={2}
							checked={this.state.isConfirmInfoAccepted}
							onChange={(value) => this.setState({ isConfirmInfoAccepted: value })}
						/>
						<div className="ml-3">{languageSchema.Payment.subscriptionConfirmationInfo}</div>
					</div>
					<div className="mt-4">{languageSchema.Payment.subscriptionInfo}</div>
					<div className="mt-4 d-flex justify-content-center align-items-center">
						<button className="btn btn-success m-2" onClick={this.handleInitSubscriptionButton} disabled={!this.state.isConfirmInfoAccepted}>
							{languageSchema.Payment.initSubscription}
						</button>
						<button className="btn btn-secondary m-2" onClick={this.hideConfirmationModal}>
							{languageSchema.Buttons.back}
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}

let mapStateToProps = (state) => ({
	user: state.auth.me,
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language)
});

let mapDispatchToProps = {
	apiStartLoading,
	apiEndLoading,
	setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList);
