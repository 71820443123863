import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import querystring from 'querystring';
import { connect } from 'react-redux';

import { displayAmount } from 'helpers/amount';
import { getToken } from 'helpers/request';
import { DATE_FORMAT, apiUrl, DEF_BLUE_MEDIA_PROCESSOR } from 'utils/constants';
import { isAdmin } from 'utils/functions';
import TextLine from './TextLine';
import { fetchStocks, setIqacDocumentAttribute } from 'redux/actions';
import request from 'helpers/request';

const InvoiceListItem = (props) => {
	const {
		invoice, languageSchema, currency,
		language, contract, boxNumber,
	} = props;

	const invoiceIdFromQueryString = querystring.parse(window.location.search.slice(1)).invoiceId;

	const isNeedToBePaid = Number(invoice.debt) > 0;
	const invoiceId = invoice.DocumentId || invoice.DocumentID;	// SM dirrerent fields format
	const isCurrentUserAdmin = props.user.role && isAdmin(props.user.role);
	const isCreditNote = invoice.DocumentType === 'Credit Note';

	useEffect(() => {
		// scroll to needed invoice
		if (invoiceIdFromQueryString && document.getElementById(invoiceIdFromQueryString)) {
			document.getElementById(invoiceIdFromQueryString).scrollIntoView();
		}
	}, []);

	const isSubscription = useMemo(() => {
		return Boolean(contract && contract.subscriptionId && contract.subscriptionId.isActive);
	}, [contract]);

	const getInvoiceInPdfUrl = () => {
		const paymentConfig = {
			invoiceId,
			language,
			stockId: contract.stockId,
			boxNumber
		};

		const queryString = querystring.stringify(paymentConfig);
		const authToken = getToken();

		return `${apiUrl}/payments/pdf?${queryString}&authToken=${authToken}`;
	};

	// const handleManualPay = async () => {
	// 	await props.manualPay();
	// 	await props.fetchInvoices();
	// };

	// const sendInvoiceEmail = async () => {
	// 	await request('POST', 'notifications/invoices-emailing', {
	// 		stockId: contract.stockId,
	// 		contractId: contract.contractId_sm,
	// 		invoiceId: invoice.DocumentID,
	// 	});
	//
	// 	props.sendInvoiceEmailCallback();
	// };

	const handlePayButton = async () => {
		try {
			let result = await request('POST', `payments/${invoice.DocumentID}?paymentMethod=${DEF_BLUE_MEDIA_PROCESSOR}`);
			if (result.redirectionUrl) {
				window.location = result.redirectionUrl;
			}

			await props.fetchInvoices();
		} catch (e) {
			props.setError(e.message);
		}
	};

	return (
		<div id={invoice.DocumentID}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="card-header">
				<h2 className="mb-0">
					<button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${invoice.DocumentID}`} aria-controls={`collapse${invoice.DocumentID}`}>
						<div style={{ flexDirection: 'row', alignItems: 'center' }}>
							{
								isNeedToBePaid && !isCreditNote &&
								<i className="fas fa-exclamation-triangle font-red"></i>
							}
							{
								!isNeedToBePaid && !isCreditNote &&
								<i className="fas fa-check font-green"></i>
							}
							<span style={{ fontSize: 17 }}>
								{`#${invoice.InvoiceID}(${invoice.DocumentID})`} {invoice.DocumentType}
							</span>
						</div>
					</button>
				</h2>

				<div className="horizontal-centered">
					{
						props.isSuperUser && props.invoiceEmailSending &&
						<div className="mx-2">
							<span style={{ position: 'initial' }} className="badge badge-info white">
								Last email sent: {moment(props.invoiceEmailSending.lastSending).format('YYYY-MM-DD')} <br />
								at {moment(props.invoiceEmailSending.lastSending).format('hh:mm')}
							</span>
						</div>
					}
					{/*
						!payment && props.isSuperUser &&
						<div>
							<button onClick={sendInvoiceEmail} className="btn btn-secondary btn-sm mx-2">
								{languageSchema.Contract.sentInvoiceEmailNotification}
							</button>

						</div>
					*/}
					{/*
						!payment && props.isSuperUser &&
						<button onClick={handleManualPay} className="btn btn-success btn-sm">
							{languageSchema.Contract.invoiceIsPaid}
						</button>
					*/}
				</div>
			</div>

			<div id={`collapse${invoice.DocumentID}`} className={`mb-4 collapse ${invoiceIdFromQueryString === invoice.DocumentID ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordionExample">
				<div style={{ padding: '20px' }}>
					<TextLine
						left={`${languageSchema.Contract.amount} (${languageSchema.Etc.remains})`}
						right={
							<div>{`${displayAmount(invoice.debt, currency)}`} <br />
								<small>{languageSchema.Etc.of} {displayAmount(invoice.Amount, currency)}</small>
							</div>}
					/>

					{
						Boolean(invoice.VATAmount) &&
						<TextLine
							left={languageSchema.Contract.vat}
							right={`${displayAmount(invoice.VATAmount, currency)}`}
						/>
					}
					{
						!isCurrentUserAdmin &&
						<TextLine
							left={languageSchema.Contract.documentDate}
							right={`${moment(invoice.DocumentDate).format(DATE_FORMAT)}`}
						/>
					}

					{
						invoice.paymentId &&
						<TextLine
							left={languageSchema.Contract.paymentDate}
							right={`${moment(invoice.paymentDate).format(DATE_FORMAT)}`}
						/>
					}
					{
						invoice.Description &&
						<TextLine
							left={languageSchema.Contract.comment}
							right={
								invoice.DocumentType === 'Deposit' ?
									'Depozyt' :
									invoice.Description
							}
						/>
					}

					<div className="inline-flex horizontal-centered">
						{

							<div className="text-center">
								<a className="btn btn-primary mt-4" rel="noopener noreferrer" target="_blank" href={getInvoiceInPdfUrl()}>
									{languageSchema.Etc.download}
								</a>
							</div>
						}

						{
							0 < invoice.debt && !isSubscription &&
							<div className="text-center ml-2">
								<div className="btn btn-success mt-4" onClick={handlePayButton}>
									{languageSchema.Payment.pay}
								</div>
							</div>
						}
					</div>
				</div>
				{props.children}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.me,
	stocks: state.stocks,
});

const mapDispatchToProps = {
	setIqacDocumentAttribute,
	fetchStocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListItem);
