import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import querystring from 'querystring';
import { toastr } from 'react-redux-toastr';
import { Upload, Button } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';

import Heading from 'components/UI/Heading';
import request from 'helpers/request';
import { displayAmount } from 'helpers/amount';
import { getToken } from 'helpers/request';
import { apiUrl } from 'utils/constants';
import { getLanguageSchema } from 'dashboard-commons/languages';
import InvoicesList from 'components/Contract/InvoicesList';
import { apiStartLoading, apiEndLoading, setError } from 'redux/actions/common';
import { ROLES_MAP, ACCOUNT_TYPES_MAP, REGULAMIN_URL, INSURANCE_CONDITIONS_URL } from 'utils/constants';
import history from 'utils/history';
import PersonalInvoices from 'components/User/PersonalInvoices';

class ContractPage extends Component {
		state = {
			contract: {},
			isContractLoaded: false,
			user: {},
			personalInvoices: [],
		};

		async componentDidMount() {
			await this.fetchContract()
				.then(() => {
					if (window.location.href.includes('#invoices_list') && document.getElementById('invoices_list')) {
						document.getElementById('invoices_list').scrollIntoView();
					}
				});
			await this.fetchPersonalInvoices();
		}

		fetchContract = async () => {
			let { match: { params: { contractId } } } = this.props;

			const { closed } = querystring.parse(window.location.search.slice(1));

			let path =  `contracts/${contractId}`;

			if (closed === 'true') {
				path = path.includes('closed=true') ? path : path + '?closed=true';
			}

			let currentContract = await request('GET', path);

			let user = await request('GET', `users/${currentContract.CustomerID}`);

			this.setState({
				contract: currentContract,
				user,
				isContractLoaded: true
			});
		}

		fetchPersonalInvoices = async () => {
			let { user } = this.props;
			let userId = this.props.match.params.userId;

			let personalInvoices = await request('GET', `users/${userId}/personal-invoices`);

			let isSuperUser = user.role === ROLES_MAP.superUser;

			personalInvoices = personalInvoices.filter(invoice => {
				if (!invoice.debt && !invoice.paymentDate && !isSuperUser) {
					return false;
				}

				return true;
			});
			this.setState({ personalInvoices });
		}

		getContractInPdfUrl = () => {
			let { match: { params: { contractId } } } = this.props;
			let authToken = getToken();
			let query = querystring.stringify({ contractId, userId: this.state.user._id });

			return `${apiUrl}/contracts/pdf?${query}&authToken=${authToken}`;
		};

		handleUploadFile = async (options) => {
			const { file } = options;

			const formData = new FormData();

			formData.append('file', file);
			formData.append('contractId', this.state.contract.ContractID);

			try {
				await request('POST', `contracts/${this.state.contract.ContractID}/files?closed=true`, formData);
				await this.fetchContract();

				toastr.success(this.props.languageSchema.Etc.success);
			}
			catch(err) {
				console.log(err);
			}
		}

		handleRemoveFileClick = (filePath) => () => {
			toastr.confirm(this.props.languageSchema.Etc.areYouSure, {
				onOk: async () => {
					await request('DELETE', `contracts/${this.state.contract.ContractID}/file?filePath=${filePath}`);
					toastr.success(this.props.languageSchema.Etc.success);
					await this.fetchContract();
				}
			});
		}

		render() {
			let { languageSchema, user } = this.props;
			let { contract, isContractLoaded, user: contractUser, personalInvoices } = this.state;

			let isSuperUser = user.role === ROLES_MAP.superUser;

			let contractUserName = contractUser.accountType === ACCOUNT_TYPES_MAP.legal ?
				contractUser.company :
				`${contractUser.firstName || ''} ${contractUser.lastName || ''}`;

			let discountRate = contract.ExtraDiscountRate ?
				contract.ExtraDiscountRate :
				(contract.DiscountRate || 0);

			// cause SM has own strange discount calculation logic
			if (20 < discountRate) {
				discountRate = 0;
			}

			return (
				<div className="row py-3">
					<div className='col-md-8 offset-md-2'>
						<div className="card">
							<div>
								<button className="mb-3 btn btn-sm btn-outline-primary" onClick={() => history.goBack()}>
									{languageSchema.BoxBooking.back}
								</button>
							</div>
							<Heading className="mb-3 d-flex justify-content-between align-items-start">
								{languageSchema.Contract.contractDetails}
							</Heading>
							{
								!isContractLoaded ?
									<div className="text-center">
										<i className="fas fa-2x fa-spinner fa-spin"></i>
									</div> :
									<div>
										<TextLine
											left={languageSchema.User.client}
											right={
												<Link to={`/users/${contractUser._id}`}>
													{contractUserName}
												</Link>
											}
										/>
										<TextLine
											left={languageSchema.Contract.contractNumber}
											right={`#${contract.contractNumber}`}
										/>

										<TextLine
											left={languageSchema.Contract.startDate}
											right={`${contract.arrivalDate}`}
										/>

										<TextLine
											left={languageSchema.Contract.contractEndingDate}
											right={contract.endDate || '-'}
										/>

										<TextLine
											left={languageSchema.Stock.boxNumber}
											right={contract.boxNumber}
										/>

										<TextLine
											left={languageSchema.Contract.square}
											right={`${contract.boxSquare}${languageSchema.Etc.metersShortLetter}²`}
										/>

										<hr />

										<TextLine
											left={`${languageSchema.Contract.regularPayment}(-${discountRate}%${languageSchema.Contract.discount})`}
											right={displayAmount((contract.monthRate - (discountRate * contract.monthRate / 100) + ((contract.monthRate - (discountRate * contract.monthRate / 100)) * contract.vatRate / 100)), contract.CurrencyCode)}
										/>
										<TextLine
											left={<small>{languageSchema.Contract.vat}({contract.vatRate}%)</small>}
											right={<small>{displayAmount(((contract.monthRate - (discountRate * contract.monthRate / 100)) * contract.vatRate / 100), contract.CurrencyCode)}</small>}
										/>

										<TextLine
											left={languageSchema.Contract.insuranceAmount}
											right={displayAmount(contract.insuranceMonthly || 0, contract.CurrencyCode)}
										/>
										<TextLine
											left={languageSchema.Contract.insuranceCoverageAmount}
											right={displayAmount(contract.insuranceCoverageAmount || 0, contract.CurrencyCode)}
										/>
										<hr />
										<TextLine
											left={languageSchema.BoxBooking.deposit}
											right={displayAmount(contract.deposit || 0, contract.CurrencyCode)}
										/>
										{
											<>
												<hr />
												<h5>{languageSchema.Contract.termsOfUse}</h5>
												{
													contract.contractNumber && /* Contract has cached version for PDF */
													<TextLine
														left={<a href={this.getContractInPdfUrl()} rel="noopener noreferrer" target="_blank" >
															{languageSchema.Contract.BoxRentalAgreement}
														</a>}
													/>
												}
												<TextLine
													left={<a href={REGULAMIN_URL} rel="noopener noreferrer" target="_blank" >
														{languageSchema.BoxBooking.docsAgreement}
													</a>}
												/>
												<TextLine
													left={<a href={INSURANCE_CONDITIONS_URL} rel="noopener noreferrer" target="_blank" >
														{languageSchema.Contract.insuranceConditions}
													</a>}
												/>

												{
													contract && contract.files && contract.files.map(file =>
														<TextLine
															key={file.filename}
															left={
																<div className="flex vertical-centered">
																	<div>
																		<a
																			href={`${apiUrl}/contracts/${this.state.contract.ContractID}/file?filePath=${file.filePath}`}
																			rel="noopener noreferrer"
																			target="_blank"
																		>
																			{file.filename}
																		</a>
																	</div>

																	{
																		isSuperUser &&
																		<div
																			onClick={this.handleRemoveFileClick(file.filePath)}
																			className="btn btn-sm btn-danger ml-1"
																		>
																			<DeleteOutlined />
																		</div>
																	}
																</div>
															}
														/>
													)
												}
											</>
										}

										{
											isSuperUser &&
											<div className="flex horizontal-centered">
												<Upload
													showUploadList={false}
													multiple={false}
													accept='image/*,.pdf'
													name="file"
													customRequest={this.handleUploadFile}
												>
													<Button
														icon={<UploadOutlined />}
													>
														{languageSchema.Contract.uploadApplication}
													</Button>
												</Upload>
											</div>
										}
									</div>
							}
						</div>
						<div id="invoices_list"></div>

						{
							contract.ContractID &&
							<>
								<InvoicesList
									contract={contract}
									fetchContract={this.fetchContract}
									contractUser={contractUser}
								/>
							</>
						}

						{
							0 < personalInvoices.length &&
								<PersonalInvoices
									invoices={personalInvoices}
									fetchData={this.fetchPersonalInvoices}
								/>
						}
					</div>
				</div>
			);
		}
}

const mapStateToProps = ({ auth, common }) => {
	return {
		user: auth.me,
		language: common.language,
		languageSchema: getLanguageSchema(common.language),
	};
};

const mapDispatchToProps = {
	apiStartLoading,
	apiEndLoading,
	setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractPage);

function TextLine({ left, right }) {
	return (
		<div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
			<div style={{ fontSize: 16, }}>
				{left}
			</div>
			<div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
				{right}
			</div>
		</div>
	);
}
