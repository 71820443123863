import React, { useState } from 'react';
import moment from 'moment';
import { Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { ACCOUNT_TYPES_MAP, IS_KYC_ENABLED, DATE_FORMAT, ROLES_MAP, env } from 'utils/constants';
import { isAdmin } from 'utils/functions';
import LegalUserFormFields from 'components/User/LegalUserFormFields';
import IndividualUserFormFields from 'components/User/IndividualUserFormFields';

function UserForm(props) {
	let {
		type, values, touched, isSuccess,
		errors, isSubmitting, languageSchema, currentUser, language,
		setFieldValue
	} = props;

	let isError = field => touched[field] && errors[field];
	let isCurrentUserAdmin = currentUser.role && isAdmin(currentUser.role);
	let isEditForm = type === 'edit';
	let buttonText = isEditForm ? languageSchema.Buttons.save : languageSchema.Buttons.create;
	let adminPasswordText = isEditForm ?
		languageSchema.User.passwordMayBeReset :
		languageSchema.User.userWillReceivePassword;

	let getFormTypeSelectOptions = (languageSchema) => Object.keys(ACCOUNT_TYPES_MAP)
		.map(accountType => ({
			value: accountType,
			label: languageSchema.User[accountType],
		}));

	let getFormTypeSelectOptionsMap = () =>
		getFormTypeSelectOptions(languageSchema)
			.reduce(((acc, curr) => ({ ...acc, [curr.value]: curr })), {});

	let formTypeSelectOptionsMap = getFormTypeSelectOptionsMap();

	let [formType, setFormType] = useState(values.accountType || ACCOUNT_TYPES_MAP.individual);

	let onFormTypeChange = type => {
		setFormType(type.value);
		setFieldValue('accountType', type.value);
		props.setAccountType(type.value);
	};

	let getFormFields = (type, props) => ({
		[ACCOUNT_TYPES_MAP.legal]: <LegalUserFormFields {...props} />,
		[ACCOUNT_TYPES_MAP.individual]: <IndividualUserFormFields {...props} />,
	}[type] || null);

	return (
		<Form>
			{
				!isEditForm && IS_KYC_ENABLED &&
				<div className="mb-3">
					<div className="alert alert-info alert-dismissible fade show" role="alert">
						{languageSchema.User.docsAttachmentInfo}
					</div>
				</div>
			}

			<div className="form-group">
				<label>
					{languageSchema.User.accountType}
				</label>
				<div>
					<Field
						component={Select}
						isDisabled={isEditForm}
						id="accountType"
						name="accountType"
						className={`${isError('accountType') ? 'is-invalid' : ''}`}
						value={formTypeSelectOptionsMap[formType]}
						options={getFormTypeSelectOptions(languageSchema)}
						onChange={onFormTypeChange}
						placeholder={languageSchema.Etc.select}
					/>
					{
						isError('accountType') &&
						<div className="invalid-feedback">
							{errors.lastName}
						</div>
					}
				</div>
			</div>

			{getFormFields(formType, { isCurrentUserAdmin, isEditForm, language, languageSchema, setFieldValue, errors, isError, values })}


			{
				isCurrentUserAdmin && currentUser.email !== values.email &&
				<div className="form-group		align-items-center">
					<label className=" col-form-label">{languageSchema.User.password}</label>
					<div>{adminPasswordText}</div>
				</div>
			}

			{
				formType &&
				<div className="form-group ">
					<label htmlFor="role" className=" col-form-label">
						{languageSchema.User.role}
						{isCurrentUserAdmin && !isEditForm && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
					</label>
					<div>
						<Field
							disabled={!isCurrentUserAdmin}
							name="roleId"
							component="select"
							id="roleId"
							className={`form-control ${isError('roleId') ? 'is-invalid' : ''}`}
						>
							<option key={0} value={''} >{languageSchema.Etc.choose}</option>
							{[ROLES_MAP.client, ROLES_MAP.superUser].map(role =>
								<option key={role} value={role}>
									{languageSchema.Role[role]}
								</option>)
							}
						</Field>
						{
							isError('roleId') &&
							<div className="invalid-feedback">
								{errors.roleId}
							</div>
						}
					</div>
				</div>
			}

			{
				isSuccess &&
				<div className="alert alert-success mt-3" role="alert">
					{languageSchema.Etc.success}
				</div>
			}

			{
				isEditForm &&
				<div className="form-group ">
					<label htmlFor="created" className=" col-form-label">{languageSchema.Etc.createdAt}</label>
					<div>
						<input type="text" readOnly className="form-control-plaintext" id="created"
							value={values.created && moment(values.created).format(DATE_FORMAT)} />
					</div>
				</div>
			}
			{
				isCurrentUserAdmin && formType &&
				<div>
					<div className="col-auto">
						<button type="submit" className="btn btn-sm btn-secondary mr-3" disabled={isSubmitting}>
							{isSubmitting ? languageSchema.Etc.wait : buttonText}
						</button>
						<Link to="/users" className="btn btn-sm btn-outline-secondary">
							{languageSchema.Buttons.back}
						</Link>
					</div>
				</div>
			}
		</Form >
	);
}

export default UserForm;
