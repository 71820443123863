import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import querystring from 'querystring';

import { apiStartLoading, apiEndLoading } from 'redux/actions/common';
import { getLanguageSchema } from 'dashboard-commons/languages';
import Heading from 'components/UI/Heading';
import request, { getToken } from 'helpers/request';
import { round, displayAmount } from 'helpers/amount';
import { apiUrl, DATE_FORMAT, ROLES_MAP, DEF_BLUE_MEDIA_PROCESSOR } from 'utils/constants';

function PersonalInvoices(props) {
	let { id: userId } = useParams();
	let invoiceIdFromQueryString = querystring.parse(window.location.search.slice(1)).invoiceId;

	useEffect(() => {
		// scroll to needed invoice
		if (invoiceIdFromQueryString && document.getElementById(invoiceIdFromQueryString)) {
			document.getElementById(invoiceIdFromQueryString).scrollIntoView();
		}

		if (window.location.hash && document.querySelector(window.location.hash)) {
			document.querySelector(window.location.hash).scrollIntoView();
		}
	}, [props.invoices.length]);

	const getInvoiceInPdfUrl = (invoiceId) => {
		const paymentConfig = {
			invoiceId,
		};

		const queryString = querystring.stringify(paymentConfig);
		const authToken = getToken();

		return `${apiUrl}/payments/pdf?${queryString}&authToken=${authToken}`;
	};

	return (
		<div id="userInvoices">
			<div className="card">
				<Heading className="mb-3 d-flex justify-content-between align-items-start">
					{props.languageSchema.Contract.otherInvoices}
				</Heading>
				<div className="accordion" id="accordionExample">
					{props.invoices.map(invoice =>
						<InvoiceItem
							key={invoice.DocumentID}
							userId={userId}
							invoice={invoice}
							invoicePdfUrl={getInvoiceInPdfUrl(invoice.DocumentID)}
							languageSchema={props.languageSchema}
							isSuperUser={props.currentUser.role === ROLES_MAP.superUser}
							invoiceEmailSending={null}
							fetchData={props.fetchData}
							apiStartLoading={props.apiStartLoading}
							apiEndLoading={props.apiEndLoading}
							currency={'PLN'}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

let mapStateToProps = (state) => ({
	currentUser: state.auth.me,
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language)
});

let mapDispatchToProps = {
	apiStartLoading,
	apiEndLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInvoices);

function InvoiceItem(props) {
	let { invoice, languageSchema, invoicePdfUrl, currency } = props;
	const invoiceIdFromQueryString = querystring.parse(window.location.search.slice(1)).invoiceId;

	let isNeedToBePaid = 0 < invoice.debt;// && !invoice.paymentDate;
	let isPaid = invoice.debt === 0; //&& invoice.paymentDate;
	let isCredited = invoice.debt === 0 && !invoice.paymentDate;

	let handlePayButton = async () => {
		props.apiStartLoading();

		try {
			let result = await request('POST', `payments/${invoice.DocumentID}?paymentMethod=${DEF_BLUE_MEDIA_PROCESSOR}`);
			if (result.redirectionUrl) {
				window.location = result.redirectionUrl;
			}

			await props.fetchInvoices();
		} catch (e) {
			props.setError(e.message);
		}

		props.apiEndLoading();

		await props.fetchData();
	};

	return (
		<div id={invoice.DocumentID}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="card-header">
				<h2 className="mb-0">
					<button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${invoice.DocumentID}`} aria-controls={`collapse${invoice.DocumentID}`}>
						<div style={{ flexDirection: 'row', alignItems: 'center' }}>
							{
								isNeedToBePaid && <i className="fas fa-exclamation-triangle font-red"></i>
							}

							{
								isPaid && <i className="fas fa-check font-green"></i>
							}

							{
								false && isCredited && <span>Credited</span>
							}

							<span style={{ fontSize: 17 }}>
								{`#${invoice.InvoiceNumber}(${invoice.DocumentID})`} {props.isSuperUser && invoice.DocumentType}
							</span>
						</div>
					</button>
				</h2>
			</div>

			<div id={`collapse${invoice.DocumentID}`} className={`mb-4 collapse ${invoiceIdFromQueryString === invoice.DocumentID ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordionExample">
				<div style={{ padding: '20px' }}>
					<TextLine
						left={languageSchema.Contract.amount}
						right={`${displayAmount(round(invoice.Amount), currency)}`}
					/>

					<TextLine
						left={languageSchema.Contract.debt}
						right={`${displayAmount(invoice.debt, currency)}`}
					/>

					{
						invoice.DocumentType === 'Invoice' &&
						<TextLine
							left={languageSchema.Contract.vat}
							right={`${displayAmount(round(invoice.VATAmount), currency)}`}
						/>
					}

					{
						invoice.paymentDate &&
						<TextLine
							left={languageSchema.Contract.paymentDate}
							right={`${moment(invoice.paymentDate).format(DATE_FORMAT)}`}
						/>
					}

					{
						(invoice.Description) &&
						<TextLine
							left={languageSchema.Contract.comment}
							right={invoice.Description ? `${invoice.Description} (${invoice.DocumentDescription})` : invoice.Description}
						/>
					}

					<div className="text-center">
						{
							invoice.DocumentType === 'Invoice' &&
								<a className="btn btn-primary" rel="noopener noreferrer" target="_blank" href={invoicePdfUrl}>
									{languageSchema.Etc.download}
								</a>
						}

						{
							Number(invoice.debt) > 0 && !props.isSuperUser &&
							<button
								type="button"
								className={'btn btn-success mx-2'}
								onClick={handlePayButton}
							>
								{languageSchema.Payment.pay}
							</button>
						}
					</div>

				</div>
			</div>
		</div>
	);
}

function TextLine({ left, right }) {
	return (
		<div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
			<div style={{ fontSize: 16, }}>
				{left}:
			</div>
			<div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
				{right}
			</div>
		</div>
	);
}
