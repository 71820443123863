import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'formik';
import qs from 'query-string';

import PasswordInput from 'components/common/PasswordInput';

import LanguagePicker from 'components/common/LanguagePicker';
import topboxLogo from 'assets/images/topbox_large_logo.png';

const LoginForm = (props) => {
	const {
		values,
		touched,
		errors,
		isSubmitting,
		languageSchema,
		location = {},
		setFieldValue,
		onSignUpAction
	} = props;

	const isError = field => errors[field] && touched[field];

	let emailFromQueryString = location.search ?
		qs.parse(location.search).email :
		null;

	if (!values.email && emailFromQueryString) {
		setFieldValue('email', emailFromQueryString);
	}

	return (
		<Form>
			<div style={{ position: 'absolute', right: '5px', top: '10px' }}>
				<LanguagePicker />
			</div>

			<div className="text-center mb-5 mt-3">
				<img
					src={topboxLogo}
					alt="Rentabox"
					title="Rentabox"
					width="75%"
				/>
			</div>
			<div className="form-group mt-3">
				<Field
					id="email"
					type="text"
					className={`form-control ${isError('email') ? 'is-invalid' : ''}`}
					value={values.email}
					placeholder={languageSchema.LogIn.yourEmail}
				/>
				{
					isError('email') &&
			<div className="invalid-feedback">
				{languageSchema.errors[errors.email]}
			</div>
				}
			</div>
			<div className="form-group">
				<PasswordInput
					id="password"
					className={`form-control ${isError('password') ? 'is-invalid' : ''}`}
					value={values.password}
					placeholder={languageSchema.LogIn.password}
					languageSchema={languageSchema}
				/>
				{
					isError('password') &&
				<div className="invalid-feedback">
					{languageSchema.errors[errors.password]}
				</div>
				}
			</div>

			<div style={{ textDecoration: 'underline' }} className="d-flex justify-content-between mb-5">
				<Link to='/reset-password'>
					{languageSchema.LogIn.forgotPassword}
				</Link>
			</div>

			<button
				type="submit"
				className="btn btn-success d-block"
				disabled={isSubmitting}
				style={{
					fontSize: '20px',
					width: '100%'
				}}
			>
				{isSubmitting ? languageSchema.LogIn.wait : languageSchema.LogIn.enter }
			</button>
			<div className="my-2">
				{
					onSignUpAction &&
						<button
							onClick={onSignUpAction}
							style={{
								backgroundColor: '#283049',
								fontSize: '20px',
								width: '100%'
							}}
							className="btn btn-primary d-block"
						>
							{languageSchema.SignUp.signUp}
						</button>
				}
			</div>
		</Form>
	);
};

export default LoginForm;
