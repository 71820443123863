import React from 'react';
import { Field } from 'formik';

function FormField(props) {
	let {
		name, value, label, placeholder, type,
		errors, touched, readOnly, disabled, component, onChange, options
	} = props;

	let isError = field => errors[field] && touched[field];

	let fieldType = type || 'text';

	let fieldProps = {};

	if (component) {
		fieldProps.component = component;
	}

	if (onChange) {
		fieldProps.onChange = onChange;
	}

	if (options) {
		fieldProps.options = options;
	}

	return (
		<div className="form-group row vertical-centered">
			<label htmlFor={name} className="col-sm-3 col-form-label">{label}</label>
			<div className="col-sm-9">
				<Field
					type={fieldType}
					className={`${component && component !== 'textarea' ? '' : 'form-control'} ${isError(name) ? 'is-invalid' : ''}`}
					id={name}
					placeholder={placeholder}
					value={value || ''}
					readOnly={readOnly}
					disabled={disabled}
					{...fieldProps}
				/>
				{isError(name) && <div className="invalid-feedback">{errors[name]}</div>}
			</div>
		</div>
	);
}

export default FormField;
