const initialState = {
	data: [],
	isFetched: false,
	history: [],
};

export default function reducer(state = initialState, action) {

	let stocks;

	switch (action.type) {
	case 'FETCH_STOCKS':
		return { ...state, data: action.payload, isFetched: true };
				
	case 'FETCH_STOCK_HISTORY':
		return { ...state, history: action.payload };
						
	case 'CREATE_STOCK':
		return { ...state, data: [...state.data, action.payload] };

	case 'UPDATE_STOCK':
		stocks = state.data.map(stock => (stock._id === action.payload._id) ? { ...stock, ...action.payload } : stock);
		return { ...state, data: stocks };
						
	case 'DELETE_STOCK':
		stocks = state.data.filter(stock => stock._id !== action.payload);
		return { ...state, data: stocks };

	default:
		return state;
	}

}