import React from 'react';
import { Link } from 'react-router-dom';

import Heading from 'components/UI/Heading';
import Table from 'components/UI/Table';
import { ACCOUNT_TYPES_MAP } from 'utils/constants';

import ListItem from './ListItem';

export default function ContractList(props) {
	let { user, contracts, terminateContract, savePlannedLeaveDate, languageSchema, language } = props;

	let getUserName = () => user.accountType === ACCOUNT_TYPES_MAP.legal ?
		user.company :
		`${user.firstName} ${user.lastName}`;

	return (
		<>
			<div className="custom-filter mb-3">
				<Heading>
					{languageSchema.Contract.userContracts} {' '}
					<Link to={`/users/${user._id}`}>
						{getUserName()}
					</Link>
				</Heading>

				<div className="mb-3">
					<div className="alert alert-warning alert-dismissible fade show" role="alert">
						<b>{languageSchema.Contract.attention}! </b>
						{languageSchema.Contract.closingContractConition}
					</div>
				</div>
			</div>
			<div className="mb-3 contract-list">
				<Table
					centerHeader
					header={[
						languageSchema.Stock.stock,
						'#',
						languageSchema.Contract.square,
						languageSchema.Contract.period,
						languageSchema.Contract.closingDate,
						languageSchema.Contract.paidInDays,
						languageSchema.Contract.planningLeavingDate,
						'',
					]}
				>
					{contracts.map((contract) =>
						<ListItem
							contract={contract}
							terminateContract={terminateContract}
							key={contract.ContractID}
							savePlannedLeaveDate={savePlannedLeaveDate}
							languageSchema={languageSchema}
							language={language}
						/>
					)}
				</Table>
			</div>
		</>
	);
}
