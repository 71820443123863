/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';


const CARD_ELEMENT_OPTIONS = {
	hidePostalCode: true,
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
};

function CardSection() {
	return (
		<div style={{ minWidth: '350px', marginBottom: '15px' }}>
			<CardElement
				options={{ CARD_ELEMENT_OPTIONS }}
			/>
		</div>
	);
}

export default CardSection;
