import React from 'react';
import { Modal } from 'antd';

const actions = {
	BOX_LEAVED_OPENED: 'boxLeavedOpened',
};

const getTitle = (type, languageSchema) => {
	return languageSchema.Notification[actions[type]];
};

const getContent = (data, languageSchema) => {
	switch (data?.actionType) {
	case 'BOX_LEAVED_OPENED':
		return (
			<h6>
				{languageSchema.BoxBooking.box} {data.boxNumber}{' '}
				{languageSchema.Notification.boxLeavedOpenedDescription}
			</h6>
		);

	default:
		return <></>;
	}
};

const NotificationModal = (props) => {
	const { visible, data, languageSchema, closeNotificationModal } = props;

	const title = getTitle(data?.actionType, languageSchema);

	const content = getContent(data, languageSchema);

	return (
		<Modal
			title={title}
			visible={visible}
			onCancel={closeNotificationModal}
			onOk={closeNotificationModal}
			cancelButtonProps={{
				style: {
					display: 'none',
				},
			}}
		>
			{content}
		</Modal>
	);
};

export default NotificationModal;
