import React, { useState } from 'react';
import { Field } from 'formik';

function PasswordInput(props) {
	let { languageSchema, value, isError, id, placeholder, className, name, isDisabledField } = props;
	let [isPasswordShown, setIsPasswordShown] = useState(false);

	return (
		<div>
			<Field
				disabled={isDisabledField}
				id={id}
				name={name}
				type={ isPasswordShown ? 'text' : 'password'}
				className={className || `form-control ${isError('password') ? 'is-invalid' : ''}`}
				value={value}
				placeholder={placeholder || languageSchema.LogIn.password}
			/>
			<div className="right pointer" onClick={() => setIsPasswordShown(!isPasswordShown)}>
				<i className={`fas fa-eye${isPasswordShown ? '-slash' : ''} mr-1`}></i>
				{
					isPasswordShown ?
						languageSchema.User.hidePassword :
						languageSchema.User.showPassword
				}
			</div>
		</div>
	);
}

export default PasswordInput;
