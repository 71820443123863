import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { fetchBoxCats, updateBoxCat } from 'redux/actions';
import BoxCatForm from 'components/BoxCat/Form';
import Heading from 'components/UI/Heading';
import validationSchema from './validation';

class BoxCatPage extends Component {
		state = {
			isSuccess: false,
			tempImage: null,
		}

		componentDidMount() {
			if (!this.props.isFetched) {
				this.props.fetchBoxCats();
			}
		}

		handleSubmit = (values, actions) => {
			const { updateBoxCat, boxcat } = this.props;

			let formData = new FormData();
			for (var key in values) {
				formData.append(key, values[key]);
			}

			updateBoxCat(boxcat._id, formData).then(() => {
				this.setState({ isSuccess: true }, () => {
					setTimeout(() => {
						this.setState({ isSuccess: false });
					}, 2000);
				});
				actions.setSubmitting(false);
			});
		}

		handleFileInput = file => {
			var reader = new FileReader();
			reader.onload = e => {
				this.setState({ tempImage: e.target.result });
			};
			reader.readAsDataURL(file);
		}

		render() {
			const { boxcat } = this.props;
			const { isSuccess, tempImage } = this.state;

			if (!boxcat) return (<div>Загрузка ...</div>);

			return (
				<div className="row py-3">
					<div className="col-md-6 offset-md-3">
						<Heading>Редактирование категории боксов</Heading>
						<Formik
							initialValues={ boxcat }
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema}
							render={props => (
								<BoxCatForm
									type="edit"
									isSuccess={isSuccess}
									handleFileInput={this.handleFileInput}
									tempImage={tempImage}
									{...props}
								/>
							)}
						/>
					</div>
				</div>
			);
		}
}

const mapStateToProps = (state, props) => {
	const currentId = props.match.params.id;
	const currentBoxcat = state.boxcats.data.find(boxcat => boxcat._id === currentId);
	return {
		boxcat: currentBoxcat,
		isFetched: state.boxcats.isFetched
	};
};
export default connect(mapStateToProps, { fetchBoxCats, updateBoxCat })(BoxCatPage);
