import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Modal } from 'antd';

import LoginForm from 'components/Auth/LoginForm';
import validationSchema from 'components/Auth/validation';
import { logIn, logOut, setCurrentUserDetails, setError } from 'redux/actions';

import { getLanguageSchema } from 'dashboard-commons/languages';

class LoginPage extends React.Component {
	componentDidMount() {
		this.props.logOut();
	}

	handleSubmit = async (values, { setSubmitting }) => {
		try {
			let loginResult = await this.props.logIn(values);

			await this.props.setCurrentUserDetails();

			this.props.onLogInCompleted(loginResult);
		} finally {
			setSubmitting(false);
		}
	}

	render() {
		const { languageSchema, isVisible, onCancel, onSignUpAction } = this.props;

		return (
			<Modal visible={isVisible} footer={null} onCancel={onCancel}>
				<Formik
					initialValues={{ email: '', password: '' }}
					onSubmit={this.handleSubmit}
					validationSchema={validationSchema(languageSchema)}
					render={
						(props) =>
							<LoginForm
								{...props}
								languageSchema={languageSchema}
								location={this.props.location}
								onSignUpAction={onSignUpAction}
							/>
					}
				/>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	bookingCache: state.cache.booking,
	invoiceCache: state.cache.invoice,
});

export default connect(mapStateToProps, { logIn, logOut, setCurrentUserDetails, setError })(LoginPage);
