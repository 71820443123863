import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getLanguageSchema } from 'dashboard-commons/languages';

import StockItem from './StockItem';
import './styles.scss';

const StockSelector = (props) => {
	const {
		languageSchema,
		stocks,
		onStockSelected,
		selectedStockId,
		selectedDirectionToStockId,
		setMapCenter,
		onShowRoute,
		onCancelRoute,
		isLoading,
		totalDistance,
		setStock,
	} = props;

	const [searchField, setSearchField] = useState('');
	const [stocksToRender, setStocksToRender] = useState(stocks);

	const handleSearchInputChange = e => {
		setSearchField(e.target.value);
	};

	const filterStocks = () => {
		const filteredStocks = stocks.filter(stock =>
			stock.cityId.city.toLowerCase().includes(searchField.toLowerCase()) ||
			stock.name.toLowerCase().includes(searchField.toLowerCase())
		);

		setStocksToRender(filteredStocks);

		const [firstStock] = filteredStocks;

		if (firstStock) {
			setMapCenter({
				lat: firstStock.cityId.latitude,
				lng: firstStock.cityId.longitude
			});
		}
	};

	return (
		<div className="card stock_selector_wrapper">
			<div className="stock_selector_wrapper__search_wrapper">
				<div className="stock_selector_wrapper__search_wrapper__search_header">
					{languageSchema.BoxBooking.cityOrStockName}
				</div>
				<div className="stock_selector_wrapper__search_wrapper__search_input_wrapper">
					<input
						className="stock_selector_wrapper__search_wrapper__search_input"
						type="search"
						placeholder={languageSchema.Etc.searchPlaceholder}
						onChange={handleSearchInputChange}
					/>
					<button
						className="stock_selector_wrapper__search_wrapper__search_button"
						onClick={filterStocks}
					>
						<i className="fas fa-search"/>
					</button>
				</div>
			</div>

			<div className="stock_selector_wrapper__stocks_list_wrapper">
				{stocksToRender.map(stock => (
					<StockItem
						onClick={onStockSelected(stock)}
						key={stock._id}
						stock={stock}
						isSelected={selectedStockId === stock._id}
						isDirectionToStockSelected={selectedDirectionToStockId === stock._id}
						onShowRoute={onShowRoute}
						isLoading={isLoading}
						totalDistance={totalDistance}
						onCancelRoute={onCancelRoute}
						languageSchema={languageSchema}
						setStock={setStock}
					/>
				))}
			</div>
		</div>
	);
};

const mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
	};
};

export default connect(mapStateToProps)(StockSelector);
