import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import request from 'helpers/request';

export default function ListItem(props) {
	const [isInvoiceEmailNotificationSent, setIsInvoiceEmailNotificationSent] = useState(false);

	const getUserName = (user) => user.accountType === ACCOUNT_TYPES_MAP.legal ?
		user.company :
		`${user.firstName} ${user.lastName}`;

	const sendInvoiceEmail = async () => {
		await request('POST', 'notifications/invoices-emailing', {
			stockId: props.invoice.contractData.stockId._id,
			contractId: props.invoice.contractData.contractId_sm,
			invoiceId: props.invoice.DocumentID,
		});

		setIsInvoiceEmailNotificationSent(true);
	};

	if (!props.invoice && !props.invoice.contractData && !props.invoice.contractData.stockId) {
		return null;
	}


	return (
		<tr className="text-center" key={props.invoice.DocumentID}>
			<td>
				{
					(props.invoice.contractData && props.invoice.contractData.stockId) ?
						props.invoice.contractData.stockId.name :
						'-'
				}
			</td>

			<td>
				{
					(props.invoice.contractData && props.invoice.contractData.userId) ?
						<Link to={`/users/${props.invoice.contractData.userId._id}`}>
							{getUserName(props.invoice.contractData.userId)}
						</Link> :
						'-'
				}
			</td>

			<td>
				{
					(props.invoice.contractData && props.invoice.contractData.userId) ?
						<Link to={`/users/${props.invoice.contractData.userId._id}/contracts/${props.invoice.ContractID}`}>
							{props.invoice.ContractNumber}
						</Link> :
						'-'
				}
			</td>

			<td>
				{props.invoice.DocumentID}
			</td>

			<td>
				{
					isInvoiceEmailNotificationSent ?
						<div className="text-success">
							<i className="fas fa-check" /> {' '}
							{props.languageSchema.Contract.sent}
						</div> :
						<button onClick={sendInvoiceEmail} className="btn btn-secondary btn-sm mx-2">
							{props.languageSchema.Contract.sentInvoiceEmailNotification}
						</button>
				}
			</td>
		</tr>
	);
}
