import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getLanguageSchema } from 'dashboard-commons/languages';
import logo from 'assets/images/topbox_large_logo.png';

import BoxBookingContext from 'screens/BoxBookingNew/Context';
import LanguagePicker from 'components/common/LanguagePicker';
import { WEBSITE_URL } from 'utils/constants';
import { Steps } from 'antd';

import { scrollToDiv } from 'helpers/common';

const NavBar = (props) => {
	const { selectedSizeGroup, selectedSizeCode, languageSchema } = props;
	const [current, setCurrent] = useState(0);
	const boxBookingContext = React.useContext(BoxBookingContext);

	useEffect(() => {
		if (!_.isEmpty(boxBookingContext.bookingMeta.selectedStockId)){
			setCurrent(1);
		}
		if (!_.isEmpty(boxBookingContext.bookingMeta.selectedBox)) {
			setCurrent(2);
		}
	}, [
		boxBookingContext.bookingMeta.selectedStockId,
		selectedSizeGroup,
		selectedSizeCode,
		boxBookingContext.bookingMeta.selectedBox
	]);

	const onChange = (value) => {
		if (value === 0) {
			scrollToDiv('#stocks_map');
		}

		if (value === 1) {
			scrollToDiv('#size-group');
		}

		if (value === 2) {
			scrollToDiv('#boxes-list');
		}
		setCurrent(value);
	};


	const { Step } = Steps;

	return (
		<nav id="navbar_top" className="navbar navbar-expand-lg navbar-light bg-white sticky-top"
			style={{boxShadow: '0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%)'}}
		>
			<div className="container">
				<a href={WEBSITE_URL} className="navbar-brand mx-2">
					<img src={logo} alt="Logo" style={{width: 200}} />
				</a>

				<button
					className="navbar-toggler collapsed"
					type="button" data-bs-toggle="collapse"
					data-bs-target="#main_nav"
					aria-expanded="false" aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon" />
				</button>

				<div className="my-2 navbar-collapse flex-center collapse flex-wrap" id="main_nav" style={{ marginLeft: '0.8rem' }}>
					<ul className="navbar-nav">
						<Steps
							current={current}
							onChange={onChange}
						>
							<Step title={languageSchema.BoxBooking.selectStock} />
							<Step title={languageSchema.BoxBooking.selectBoxSize} disabled={!boxBookingContext.bookingMeta.selectedStockId} />
							<Step title={languageSchema.BoxBooking.enterDataAndPayment} disabled={!boxBookingContext.bookingMeta.selectedBox}/>
						</Steps>

					</ul>
					<ul className = "navbar-nav" >
						<li className="nav-item">
							<div className=" nav-link fw-normal text-sm reset-anchor" href="#!">
								<i className="fas fa-mobile me-2"></i>
								<a href="tel:+48 22 800 800 007">800 800 007</a>
							</div>
						</li>
						<LanguagePicker dark />
					</ul>
				</div> {/* navbar-collapse.// */}
			</div> {/* container-fluid.// */}
		</nav>
	);
};


const mapStateToProps = ({
	common
}) => ({
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(NavBar);
