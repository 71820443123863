import React from 'react';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import NoItemsLogo from 'assets/images/no-items.svg';

function NoItems(props) {
	let { linkTo, linkText, isLogo = true } = props;

	return (
		<div className="text-center">
			<div className="p-5">
				<p>
					{
						isLogo ?
							<img
								src={NoItemsLogo}
								style={{ maxWidth: '100%' }}
							/>
							:
							<i className="fas fa-clipboard-list fs-9 mb-2		grey"></i>
					}
				</p>
				<h2 className="grey">{props.languageSchema.Etc.noItems}</h2>
			</div>
			{
				linkTo &&
				<Link to={linkTo} className="btn btn-success">
					{linkText}
				</Link>
			}
		</div>
	);
}

let mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(NoItems);
