import React, { Component } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import countries from 'i18n-iso-countries';
import _ from 'lodash';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));


export default class CountrySelector extends Component {
	constructor(props) {
		super(props);

		let language = this.props.language.toLowerCase();

		this.state = {
			country: {
				value: this.props.field.value,
				label: countries.getName(this.props.field.value, language)
			},
		};
	}
	componentDidMount() {
		this.setOptions(this.props.language);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.language !== this.props.language) {
			this.setOptions(nextProps.language);
		}
	}

		setOptions = (lang) => {
			let language = lang.toLowerCase();

			this.setState({
				options: [
					{
						value: 'PL',
						label: countries.getName('PL', language),
					},
					..._.uniqBy(countryList().getData().map(country => ({
						value: country.value,
						label: countries.getName(country.value, language)
					})), 'value')
				],
				country: {
					...this.state.country,
					label: countries.getName(this.props.field.value, language)
				},
			});
		}

		changeHandler = country => {
			this.setState({ country });

			this.props.form.setFieldValue('country', country.value);
		}

		render() {
			return (
				<Select
					options={this.state.options}
					value={this.state.country}
					onChange={this.changeHandler}
					placeholder={this.props.languageSchema.Etc.choose}
					name={this.props.field.name}
					isDisabled={this.props.disabled}
				/>
			);
		}
}
