import { pow } from 'helpers/common';

function toFixed( num, precision ) {
	return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export const round = (number) => {
	return Number(toFixed(number, 2));
};

export const getCurrencySign = (currency, language = 'PL') => {
	let currencyToSignMap = {
		EN: {
			EUR: '€',
			USD: '$',
			RUB: '₽',
			PLN: 'PLN',
		},
		PL: {
			EUR: '€',
			USD: '$',
			RUB: '₽',
			PLN: 'zł',
		}
	};

	return currencyToSignMap[language] ? currencyToSignMap[language][currency] : currencyToSignMap['EN'][currency];
};

export const toBaseUnit = (amount, currency) =>
	round(Number(amount) / pow(10, getIndivisibleParticle(currency)));


export const toIndivisibleUnit = (amount, currency) =>
	round(Number(amount) * pow(10, getIndivisibleParticle(currency)));

export const percentToDecimal = percent => percent / 100;

function getIndivisibleParticle(currency) {
	let particles =		{
		EUR: 2,
		PLN: 2
	};

	if (!particles[currency]) {
		throw new Error(`Invalid currency: ${currency}`);
	}

	return particles[currency];
}

export const displayAmount = (amountInIndivisibleFormat, currency) =>
	`${Number(amountInIndivisibleFormat).toFixed(2)} ${currency ? getCurrencySign(currency) : ''}`;
