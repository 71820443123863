import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/de';

class DayPicker extends Component {
		state = {
			from: undefined,
			to: undefined
		}

		fieldToRef = React.createRef();

		handleChangeFromParam = (from) => {
			this.setState({ from });
		}

		handleChangeToParam = (to) => {
			this.setState({ to }, () => {
				this.showFromMonth();
			});
		}

		showFromMonth = () => {
			const { from, to } = this.state;
			if (!from) {
				return;
			}
			if (moment(to).diff(moment(from), 'months') < 2) {
				this.fieldToRef.current.getDayPicker().showMonth(from);
			}

			this.check();
		}

		reset = () => {
			this.setState({ from: undefined, to: undefined }, this.check);
		}

		check = () => {
			const { from, to } = this.state;
			this.props.handleSelectDateRange(from, to);
		}

		render() {
			const { from, to } = this.state;
			const { language, languageSchema } = this.props;
			const modifiers = { start: from, end: to };

			return (
				<div>
					<div className="form-row InputFromTo">
						<div className="col-md-6">
							<DayPickerInput
								value={from}
								placeholder={languageSchema.Etc.from}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								inputProps={{ style: { width: '100%' }, className: 'form-control' }}
								dayPickerProps={{ 
									selectedDays: [from, { from, to }],
									disabledDays: { after: new Date() },
									toMonth: to,
									modifiers,
									numberOfMonths: 2,
									localeUtils: MomentLocaleUtils,
									locale: language.toLowerCase(),
									onDayClick: () => this.fieldToRef.current.getInput().focus(),
								}}
								onDayChange={this.handleChangeFromParam}
							/>
						</div>
						<div className="col-md-6 InputFromTo-to">
							<DayPickerInput
								ref={this.fieldToRef}
								value={to}
								placeholder={languageSchema.Etc.to}
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								inputProps={{ style: { width: '100%' }, className: 'form-control' }}
								dayPickerProps={{
									selectedDays: [from, { from, to }],
									disabledDays: { before: from, after: new Date() },
									modifiers,
									month: from,
									fromMonth: from,
									numberOfMonths: 2,
									localeUtils: MomentLocaleUtils,
									locale: language.toLowerCase(),
								}}
								onDayChange={this.handleChangeToParam}
							/>
						</div>
					</div>
					{from && to &&
										<button className="btn btn-outline-secondary btn-sm btn-block mt-2" onClick={this.reset}>
											{languageSchema.Etc.reset}
										</button>
					}
				</div>
			);
		}
}

export default DayPicker;
