export default {
	stock: 'Magazyn',
	createStock: 'Dodaj zapas',
	stockEditing: 'Edycja zasobów',
	history: 'Historia',
	box: 'Boks',
	boxes: 'Boksy',
	name: 'Tytuł',
	city: 'Miasto',
	address: 'Adres',
	phone: 'Numer',
	zip: 'Indeks',
	hasBoxes: 'Ma boksy',
	boxRenting: 'Wynajem boksu',
	controlSystem: 'System zarządzania magazynem',
	currency: 'Waluta rozliczenia',
	currencyRequired: 'Waluta rozliczenia',
	maxContractDuration: 'Maksymalny czas trwania umowy (miesiące)',
	routerIp: 'adres IP magazynu',
	locksServerPort: 'Port serwera blokującego',
	databaseServer: 'Serwer bazy danych',
	databasePort: 'Port bazy danych',
	databaseName: 'Nazwy baz danych',
	databaseUser: 'Użytkownik bazy danych',
	databasePassword: 'Hasło do bazy danych',
	addEquipment: 'Dodaj element wyposażenia',
	equipmentId: 'identyfikator przedmiotu',
	control: 'Zarządzanie',
	discountSettings: 'Ustawienia rabatów',
	discountHelpInfo: `Wartość ta będzie co miesiąc pomnażana przez jednorazową wpłatę. <br /> <br />
		<b> Example: </b> <br />
		Użytkownik ma zamiar zawrzeć umowę na <b> 12 miesięcy </b>
		i wybiera płatność dla każdej <b> 4 miesiące </b>. Wartość rabatu ustala się na. <b> 2% </b>. <br />
		Wówczas łączny rabat dla płatności 4-miesięcznej wyniesie.: <br />
		<b> 4 * 2% = 8% </b> miesięcznego kosztu wynajmu boksu.`,
	discountPercentPerMonth: 'Procent rabatu za każdy jednorazowy miesiąc płatności:',
	feeSettings: 'Ustawienia kar',
	feeInfoBlock: 'Karny procent jest dodawany każdego dnia. Jest on obliczany od pierwotnej kwoty. ',
	dailyFeeDescription: 'Procent składki za każdy nieopłacony dzień',
	freeFeeDays: 'Nie ładuj najpierw',
	days: 'dni',
	contractEndedFeeLabel: 'Procent składki za każdy dzień po zakończeniu umowy',
	stockHistory: 'Historia akcji',
	date: 'Data',
	idOrSize: 'Identyfikator lub rozmiar',
	action: 'Działanie',
	noHistoryRecords: 'Historia jest pusta',
	account: 'Konto',
	opened: 'Otwarte',
	closed: 'Zamknięte',
	accountType: 'Rodzaj konta',
	areYouSureYouWantToOpenBox: 'Czy na pewno chcesz otworzyć boks?',
	lockStatus: 'Status zamka',
	totalBoxes: 'Razem boksów',
	boxNumber: 'Numer boksu',
	size: 'Rozmiar',
	lockNumber: 'Numer zamka',
	monthRate: 'Koszt / miesiąc',
	notSet: 'Nie określono',
	currentUser: 'Bieżący użytkownik',
	boxAvailable: 'Bezpłatny',
	areYouSureYouWantToOpenStock: 'Czy jesteś pewien, że chcesz otworzyć magazyn?',
	areYouSureYouWantToOpen: 'Czy jesteś pewien, że chcesz otworzyć',
	areYouSureYouWantToDelete: 'Czy na pewno chcesz usunąć magazyn?',
	insurance: 'Ubezpieczenie',
	insuranceCoverageAmount: 'Kwota ubezpieczenia',
	insuranceMonthlyAmount: 'Kwota ubezpieczenia do zapłaty miesięcznie',
	vat: 'VAT',
	legalUsersVATRate: 'VAT dla użytkowników prawnych',
	individualUsersVATRate: 'VAT dla użytkowników indywidualnych',
	wifiPassword: 'Wi-Fi hasło',
	accessDenied: 'Odmowa dostępu',
	accessAllowed: 'Dostęp dozwolony',
	coordinates: 'Współrzędne',
	latitude: 'Szerokość geograficzna',
	longitude: 'Długość geograficzna',
	stockId_sm: 'Identyfikator magazynu SM',
	save: 'Zapisać',
	boxesMapConstructor: 'Konstruktor mapy boksów',
	accountBalance: 'Stan konta',
	addFloor: 'Dodaj piętro',
	floor: 'Piętro',
	height: 'Wysokość',
	width: 'Szerokość',
	depth: 'Głębokość',
	square: 'Powierzchnia',
	boxSpace: 'Powierzchnia',
	wifiNetworkName: 'Nazwa sieci Wi-Fi',
	gate: 'Brama',
	locksState: 'Stan zamka',
	all: 'Wszystko'
};
