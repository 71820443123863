import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { env } from 'utils/constants';
import InfoToggler from 'components/common/InfoToggler';
import { round, displayAmount, getCurrencySign } from 'helpers/amount';
import BoxBookingContext from 'screens/BoxBookingNew/Context/';

function Tariffs(props) {
	let { discountSchemes, languageSchema } = props;
	let boxBookingContext = React.useContext(BoxBookingContext);
	let { bookingMeta: { selectedContractDuration, vatRate }, setTariff } = boxBookingContext;

	const tariffs = [
		{
			label: 'BASIC',
			key: 'basic',
			discountPercent: 0,
			description: languageSchema.BoxBooking.tariffs.basic,
			minRentMonths: 1,
			maxRentMonths: 5,
			discountMaxThreshold: 5,
		},
		{
			label: 'SMART',
			key: 'smart',
			discountPercent: 5,
			description: languageSchema.BoxBooking.tariffs.smart,
			minRentMonths: 6,
			maxRentMonths: 11,
			discountMaxThreshold: 11,
		},
		{
			label: 'MAX',
			key: 'max',
			discountPercent: 10,
			description: languageSchema.BoxBooking.tariffs.max,
			minRentMonths: 12,
			maxRentMonths: 1000,
			discountMaxThreshold: 12,
		},
		{
			label: 'WEEK',
			key: 'week',
			discountPercent: 0,
			description: languageSchema.BoxBooking.tariffs.week,
			isWeekly: true,
		}
	];


	useEffect(() => {
		console.log('WERWERselectedContractDuration', selectedContractDuration);

		// boxBookingContext.setDiscountPercent(discountPercent);
	}, [selectedContractDuration]);

	let getDiscountPercent = (contractDuration) => {
		// let discounts = discountSchemes
		// 	.filter(scheme => scheme.MinStayNumOfPeriods || scheme.MinPrepayPeriod)
		// 	.sort((el1, el2) => {
		// 		if (el2.SortOrder > el1.SortOrder) {
		// 			return 1;
		// 		}
		//
		// 		return -1;
		// 	});
		//
		// let correspondedDiscountScheme = discounts
		// 	.find(scheme => (scheme.MinStayNumOfPeriods || scheme.MinPrepayPeriod) <= contractDuration);
		//
		// if (correspondedDiscountScheme && correspondedDiscountScheme.Discount) {
		// 	return correspondedDiscountScheme.Discount;
		// }
		//
		// return 0;
		//
		let discounts = discountSchemes
			// .filter(scheme => scheme.MinStayNumOfPeriods || scheme.MinPrepayPeriod)
			.sort((el1, el2) => {
				if (el2.Period > el1.Period) {
					return 1;
				}

				return -1;
			});

		let correspondedDiscountScheme = discounts.find(scheme => (scheme.Period <= contractDuration));
		//
		// if (!correspondedDiscountScheme) {
		// 	correspondedDiscountScheme = discounts.find(schema => schema.Discount === 0);
		// }

		let discountPercent = 0;

		if (correspondedDiscountScheme && correspondedDiscountScheme.PercentageDiscount) {
			discountPercent = correspondedDiscountScheme.PercentageDiscount;
		}

		return discountPercent;
	};

	let getCalculatedMonthRate = (minContractDuration) => {
		// let contractDuration = boxBookingContext.bookingMeta.selectedContractDuration;
		let discountPercent = getDiscountPercent(minContractDuration);

		let discountAmount = round(discountPercent * boxBookingContext.bookingMeta.selectedBox.MonthRate / 100);
		let rateWithDiscount = round(((boxBookingContext.bookingMeta.selectedBox.MonthRate * minContractDuration) - discountAmount) / minContractDuration);
		let vatAmount = round(rateWithDiscount * vatRate / 100);

		return round(rateWithDiscount + vatAmount);
	};

	let getCalculatedWeekRate = () => {
		let rate = round(boxBookingContext.bookingMeta.selectedBox.Weekrate);
		let vatAmount = round(rate * vatRate / 100);

		return round(rate + vatAmount);
	};

	let isActive = (tariff) => {
		if (
			tariff.minRentMonths <= boxBookingContext.bookingMeta.selectedContractDuration &&
			boxBookingContext.bookingMeta.selectedContractDuration <= tariff.maxRentMonths
		) {
			if (env === 'production') {
				ym(89808973,'reachGoal', 'tariff_selected'); // eslint-disable-line
			}

			return true;
		}

		if (boxBookingContext.bookingMeta.isWeeklyPaymentChosen && tariff.isWeekly) {
			return true;
		}

		return false;
	};

	let renderRate = (tariff) => {
		let weekRate = <>
			{displayAmount(
				getCalculatedWeekRate(),
			)} <br />
			<span style={{ fontSize: '18px' }}>
				{getCurrencySign(boxBookingContext.bookingMeta.selectedStock.currency, props.language)}/{props.languageSchema.BoxBooking.week}
			</span>
		</>;

		let monthRate = <>
			{displayAmount(getCalculatedMonthRate(tariff.discountMaxThreshold))}
			<br />
			<span style={{ fontSize: '18px' }}>
				{getCurrencySign(boxBookingContext.bookingMeta.selectedStock.currency, props.language)}/{props.languageSchema.Etc.monthShort}
			</span>
		</>;

		if (!boxBookingContext.bookingMeta.arrivalDate) {
			return <>
				{monthRate}
			</>;
		} else {
			return boxBookingContext.bookingMeta.isWeeklyPaymentChosen ? weekRate : monthRate;
		}
	};



	return (
		<div className="flex inline-flex flex-center flex-wrap mt-3 mb-3 relative" style={{ marginLeft: '-20px', marginRight: '-20px' }}>
			{
				tariffs.filter(({ isWeekly }) => {
					if (boxBookingContext.bookingMeta.isWeeklyPaymentChosen) {
						return isWeekly;
					}

					return !isWeekly;
				})
					.map((tariff, index, tariffs) => {
						if (isActive(tariff)) {
							setTariff(tariff.key);
						}

						return (
							<div
								key={tariff.key}
								style={{
									width: 1 < tariffs.length ? '130px' : '100%',
									textAlign: 'center',
									border: isActive(tariff) ? '1px solid #007396' : '1px solid lightgrey',
									borderRadius: '5px',
									margin: '5px 3px',
								}}
							>
								<div style={{
									fontSize: '24px',
									textAlign: 'center',
									background: isActive(tariff) ? '#007396' : 'white',
									color: isActive(tariff) ? 'white' : 'black',
									marginBottom: '5px',
								}}>
									Taryfa <br />
									{tariff.label}
								</div>

								<div style={{ fontSize: '24px', }}>
									<InfoToggler alertType="info" text={tariff.description} />
								</div>

								<div style={{ fontSize: '24px', margin: '10px', lineHeight: '1.0' }}>
									{
										renderRate(tariff)
									}
								</div>
							</div>
						);
					})
			}
		</div>
	);
}

let mapStateToProps = state => ({
	language: state.common.language,
});

export default connect(mapStateToProps)(Tariffs);
