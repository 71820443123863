import decodeJwt from 'jwt-decode';
import { getToken } from 'helpers/request';
import { ROLES_MAP } from 'utils/constants';

export const isDevelompent = () => window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const getApiToken = () => localStorage.getItem('accessToken');
export const isTokenExist = () => !!getApiToken();

export const getCurrentStockId = stock => 'stockId_sm' in stock ? stock.stockId_sm : stock._id;

export const getCurrentStockIdByBox = box => 'stockId_sm' in box ? box.stockId_sm : box.stockId;

export const getPrettyLockStatus = (status, languageSchema) => {
	switch (status) {
	case 'opened':
		return { title: languageSchema.Stock[status], color: 'green' };

	case 'closed':
		return { title: languageSchema.Stock[status], color: 'red' };

	default:
		return { title: 'N/A', color: 'gray' };
	}
};

export const getPageCount = (count, perPage) => Math.ceil(count / perPage);

const getDecodedToken = () => {
	try {
		const token = getToken();

		if (!token) {
			return null;
		}

		return decodeJwt(token);
	} catch (error) {
		return null;
	}
};


export const isTokenValid = () => {
	const decodedToken = getDecodedToken();

	if (!decodedToken || (decodedToken && Date.now() >= decodedToken.exp * 1000)) {
		return false;
	}

	return true;
};


export const getRoleNameFromToken = () => {
	const decodedToken = getDecodedToken();
	if (!decodedToken) {
		return null;
	}

	return decodedToken.role;
};

export const getCurrentUserIdFromToken = () => {
	const decodedToken = getDecodedToken();
	if (!decodedToken) {
		return null;
	}

	return decodedToken.id;
};

export const getCurrentUserAccountTypeFromToken = () => {
	const decodedToken = getDecodedToken();
	if (!decodedToken) {
		return null;
	}

	return decodedToken.accountType;
};

export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isAdmin = role => [ROLES_MAP.superUser].includes(role);

export const isClient = role => role === ROLES_MAP.client;

export const getUserName = user => user.accountType === 'legal' ?
	user.company :
	`${user.firstName} ${user.lastName}`;

/**
 * Create invoice Url from parameters
 *
 * @param {string} user
 * @param {string} contract
 * @param {string} invoice
 * @returns {string}
 */
export function createUserInvoiceUrl(user, contract, invoice) {
	return `/users/${user}/contracts/${contract}#activeInvoice=${invoice}`;
}

export function debounce(func, timeout = 300){
	let timer;

	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}
