import React from 'react';

class CallbackButton extends React.Component {
	componentDidMount() {
		// if (process.env.NODE_ENV === 'production' && window.innerWidth > 600) {
		// 	const script = document.createElement('script');
		//
		// 	script.src = '//code-ya.jivosite.com/widget/UvjAchWCXL';
		//
		// 	script.async = true;
		//
		// 	document.body.appendChild(script);
		// }
	}

	render() {
		return (
			<div />
		);
	}
}

export default CallbackButton;
