export default {
	propertyPanel: {
		// position: 'absolute',
		// right: 0,
		width: 240,
		padding: '0 5px 6px 5px',
		fontFamily: '"Lucida Grande", sans-serif',
		fontSize: 11
	},
	propertyGroup: {
		backgroundColor: '#f1f1f1',
		overflow: 'hidden',
		paddingBottom: 12,
		paddingTop: 2,
		paddingLeft: 10,
		border: '0px solid #d3d3d3',
		marginBottom: 5
	},
	inputHelper: {
		fontSize: 9,
		color: '#d2d2d2',
		paddingTop: 2,
		paddingLeft: 5
	},
	inlineInputHelper: {
		fontSize: 9,
		display: 'inline-block',
		marginLeft: 10
	},
	panelTitle: {
		float: 'left',
		width: 60,
		padding: 3,
		color: '#b8b8b8'
	},
	columns: {
		overflow: 'hidden',
		marginTop: 10
	},
	column: {
		float: 'left',
		marginRight: 5
	},
	input: {
		background: '#e1e1e1',
		borderWidth: 0,
		padding: '3px 5px',
		color: 'gray',
		borderRadius: 3,
	},
	select: {
		WebkitAppearance: 'none',
		MozAppearance: 'none',
		borderWidth: 0,
		padding: '3px 3px 3px 5px',
		outline: 'none',
		borderRadius: 0,
		borderRight: '3px solid #b7b7b7',
		color: 'gray',
		width: 75
	},
	integerInput: {
		width: 50,
		outline: 'none'
	},
	textInput: {
		marginTop: 2,
		outline: 'none',
		width: '100%',
		padding: 3,
	},
	colorInput: {
		width: 18,
		height: 18,
		borderRadius: '50%',
		display: 'inline-block',
		background: 'white',
		marginRight: 3,
	},
	color: {
		marginLeft: 2,
		marginTop: 2,
		width: 14,
		height: 14,
		display: 'inline-block',
		borderRadius: '50%'
	},
	colorCover: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
	colorPopover: {
		position: 'absolute',
		marginTop: 8,
		zIndex: 999999
	},
	empty: {
		display: 'none',
	},
	button: {
		color: '#515151',
		textDecoration: 'none',
		display: 'block',
		padding: '2px 0',
	},
	item: {
		padding: '2px 6px',
		cursor: 'default'
	},

	highlightedItem: {
		color: 'white',
		background: 'hsl(200, 50%, 50%)',
		padding: '2px 6px',
		cursor: 'default'
	},
};
