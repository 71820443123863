import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import UserForm from 'components/User/Form';
import Heading from 'components/UI/Heading';
import { createUser } from 'redux/actions';
import validationSchema from './validation';
import {getLanguageSchema} from 'dashboard-commons/languages';
import { fetchStocks } from 'redux/actions';
import { timeout } from 'utils/functions';

class UserFormAdd extends React.Component {
		state = {
			isSuccess: false,
			accountType: ACCOUNT_TYPES_MAP.individual,
		}

		componentDidMount() {
			const {
				isFetchedStocks,
				fetchStocks,
			} = this.props;

			if (!isFetchedStocks) {
				fetchStocks();
			}
		}

		handleSubmit = async (values, { setSubmitting }) => {
			try {
				await this.props.createUser(values);

				this.setState({ isSuccess: true }, async () => {
					await timeout(1500);
					this.setState({ isSuccess: false });
					this.props.history.push('/users');
				});
			} catch (error) {
				console.log(error);
			} finally {
				setSubmitting(false);
			}
		}

	setAccountType = accountType => this.setState({ accountType });

	render() {
		const { languageSchema, user: currentUser, language } = this.props;
		let { accountType } = this.state;

		return (
			<div className="row py-3">
				<div className="col-md-6 offset-md-3">
					<div className="card">
						<Heading>{languageSchema.User.accountCreation}</Heading>
						<Formik
							initialValues={{
								firstName: '',
								lastName: '',
								company: '',
								country: 'PL',
								email: '',
								phone: '',
								address: '',
								house: '',
								entrance: '',
								apartment: '',
								city: '',
								zip: '',
								pesel: '',
								role: '',
								accountType: ACCOUNT_TYPES_MAP.individual,
								language,
								isAdvertisingAgree: true,
							}}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema(languageSchema, accountType)}
							render={props => (
								<UserForm
									type="create"
									isSuccess={this.state.isSuccess}
									onBirthDateChange={this.onBirthDateChange}
									{...props}
									languageSchema={languageSchema}
									language={language}
									currentUser={currentUser}
									setAccountType={this.setAccountType}
								/>
							)}
						/>

					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, stocks, common }) => {
	return {
		isFetchedStocks: stocks.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
		user: auth.me,
	};
};
export default connect(mapStateToProps, { fetchStocks, createUser })(UserFormAdd);
