import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import { scrollToDiv } from 'helpers/common';

import { env } from 'utils/constants';

import './styles.scss';

export default (props) => {
	let {
		stock,
		onClick,
		isSelected,
		onShowRoute,
		totalDistance,
		onCancelRoute,
		isDirectionToStockSelected,
		languageSchema,
		setStock,
		isLoading = false,
	} = props;

	let stocksCoordinates = {
		latitude: stock.latitude,
		longitude: stock.longitude,
	};

	let isMobile = window.innerWidth < 900;
	//let cityCenterCoordinates = { latitude: stock.cityId.latitude, longitude: stock.cityId.longitude };
	//let distanceToCenterKM = calculateDistanceToCenter(stocksCoordinates, cityCenterCoordinates);

	let renderGetRouteButton = () => {
		if (!isMobile) {
			return (
				<div className="mx-2">
					{(!isDirectionToStockSelected || isLoading) && (
						<div
							onClick={(e) => {
								!isLoading &&
									onShowRoute(e, stocksCoordinates, stock);
							}}
							className="btn btn-sm btn-outline-primary mt-2 min-w-50"
						>
							{isDirectionToStockSelected && isLoading ? (
								<LoadingOutlined />
							) : (
								languageSchema.BoxBooking.getRoute
							)}
						</div>
					)}
				</div>
			);
		}
		return (
			<div className="mx-2" onClick={(e) => e.stopPropagation()}>
				<a className='d-block btn btn-sm btn-outline-primary mt-2 min-w-50' target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${stocksCoordinates.latitude},${stocksCoordinates.longitude}`} rel="noreferrer" >
					{languageSchema.BoxBooking.getRoute}
				</a>
			</div>
		);
	};

	let pricesButtonId = `prices_button_${stock.name.replaceAll(' ', '_')}`;

	let handlePricesButtonClick = (buttonId) => () => {
		setStock(stock);

		setTimeout(() => {
			scrollToDiv('#size-group');
		}, 0);

		if (env === 'production') {
			ym(89808973,'reachGoal', buttonId); // eslint-disable-line
		}
	};

	return (
		<div
			className={`stock_item ${isSelected ? 'stock_item_selected' : ''}`}
		>
			<div className="stock_item__address_info_wrapper">
				<div className="d-flex flex-column justify-content-between">
					<div style={{ fontWeight: 'bold', fontSize: '18px' }}>
						{' '}
						{stock.name}{' '}
					</div>
					{isSelected && Boolean(totalDistance) && (
						<div
							style={{
								color: 'grey',
								fontSize: '14px',
								lineHeight: 1.0,
							}}
						>
							{totalDistance}km <br /> do Ciebie
						</div>
					)}
					<div
						onClick={handlePricesButtonClick(pricesButtonId)}
						className="btn btn-sm btn-success mx-2 min-w-50"
						id={pricesButtonId}
					>
						{languageSchema.BoxBooking.prices}
					</div>
				</div>
				<div className="d-flex flex-column" onClick={onClick}>
					<div
						style={{
							lineHeight: '1.0',
							color: 'grey',
							padding: '5px',
						}}
					>
						{stock.address} <br />
						{stock.zip} {stock.cityId.city}
					</div>
					{
						renderGetRouteButton()
					}
					{isDirectionToStockSelected && !isLoading && (
						<div
							onClick={onCancelRoute}
							className="btn btn-sm btn-outline-primary mt-2 min-w-50"
						>
							{languageSchema.BoxBooking.back}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

function calculateDistanceToCenter(pointA = {}, pointB = {}) {
	if (
		!pointA.latitude ||
		!pointB.latitude ||
		!pointA.latitude ||
		!pointB.longitude
	) {
		return null;
	}

	let R = 3958.8; // Radius of the Earth in miles

	let rlat1 = pointA.latitude * (Math.PI / 180); // Convert degrees to radians
	let rlat2 = pointB.latitude * (Math.PI / 180); // Convert degrees to radians

	let rlon1 = pointA.longitude * (Math.PI / 180); // Convert degrees to radians
	let rlon2 = pointB.longitude * (Math.PI / 180); // Convert degrees to radians

	let difflat = rlat2 - rlat1; // Radian difference (latitudes)
	let difflon = rlon2 - rlon1; // Radian difference (longitudes)

	let distance =
		2 *
		R *
		Math.asin(
			Math.sqrt(
				Math.sin(difflat / 2) * Math.sin(difflat / 2) +
					Math.cos(rlat1) *
						Math.cos(rlat2) *
						Math.sin(difflon / 2) *
						Math.sin(difflon / 2)
			)
		);
	let distanceKM = distance * 1.60934;

	return distanceKM.toFixed(2);
}
