import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { createBoxCat } from 'redux/actions';
import BoxCatForm from 'components/BoxCat/Form';
import Heading from 'components/UI/Heading';
import validationSchema from './validation';
import { timeout } from 'utils/functions';

class BoxCatFormScreen extends Component {
		state = {
			isSuccess: false,
			tempImage: null,
		}

		handleSubmit = async (values, { setSubmitting }) => {
			const { createBoxCat } = this.props;

			let formData = new FormData();
			for (let key in values) {
				formData.append(key, values[key]);
			}

			await createBoxCat(formData);
			setSubmitting(false);

			this.setState({ isSuccess: true }, async () => {
				await timeout(2000);
				this.setState({ isSuccess: false });
			});
		}

		handleFileInput = file => {
			var reader = new FileReader();
			reader.onload = e => {
				this.setState({ tempImage: e.target.result });
			};
			reader.readAsDataURL(file);
		}

		render() {
			const { isSuccess, tempImage } = this.state;

			return (
				<div className="row py-3">
					<div className="col-md-6 offset-md-3">
						<Heading>Редактирование категории боксов</Heading>
						<Formik
							initialValues={{ name: '', alias: '', minSize: '', maxSize: '', pricePerMeterQuadratic: '', description: '' }}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema}
							render={props => (
								<BoxCatForm
									type="create"
									isSuccess={isSuccess}
									handleFileInput={this.handleFileInput}
									tempImage={tempImage}
									{...props}
								/>
							)}
						/>
					</div>
				</div>
			);
		}
}

export default connect(null, { createBoxCat })(BoxCatFormScreen);