import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
	Drawer,
	Tabs,
	Button,
	Alert,
	Popover,
} from 'antd';
import moment from 'moment';

import { getLanguageSchema } from 'dashboard-commons/languages';
import { displayAmount, round } from 'helpers/amount';
import request from 'helpers/request';
import {
	ACCOUNT_TYPES_MAP,
	PRIVATE_CUSOMTER_AGREEMENT,
	FIRMA_AGREEMENT,
	LOCALIZATION_WARSAW_WEBSITE_URL,
	env
} from 'utils/constants';
import {
	getRoleNameFromToken,
	getCurrentUserIdFromToken,
	isAdmin
} from 'utils/functions';

import BoxBookingContext from 'screens/BoxBookingNew/Context';

import InsuranceSelector from './InsuranceSelector';
import ChoiceOfArrivalDate from './ChoiceOfArrivalDate';
import ChoiceOfDepartureDate from './ChoiceOfDepartureDate';
import DocsAgreementModal from './modals/DocsAgreementModal';
import Tariffs from './Tariffs';
import DocsList from './DocsList';

import '../styles.scss';

const SideBar = props => {
	const {
		selectedBox,
		languageSchema,
		language,
		selectedStockId,
		isOpened,
		close,
		open,
		openSignUpModal,
	} = props;

	const [isLoading, setLoadingState] = useState(false);
	const [currentTab, setCurrentTab] = useState('1');
	const boxBookingContext = React.useContext(BoxBookingContext);
	const [discountSchemes, setDiscountSchemes] = useState([]);
	const [isDocsAgreementModalOpen, setIsDocsAgreementModalOpen] = useState(false);
	const [docLink, setDocLink] = useState('');

	useEffect(() => {
		if (currentTab === '2' && !boxBookingContext.bookingMeta.user) {
			openSignUpModal();
			setCurrentTab('1');
		}
	}, [currentTab]);

	const sideBarStep1BottomRef = useRef(null);

	const {
		bookingMeta: {
			arrivalDate,
			selectedContractDuration,
			selectedInsuranceSchemeId,
			areDocsConfirmed,
			user,
			paymentMethod,
			departureDate,
			vatRate,
			invoicingPeriodType,
			isWeeklyPaymentChosen,
			tariff,
		}
	} = boxBookingContext;

	const isStep1Completed = Boolean(arrivalDate && selectedContractDuration && selectedInsuranceSchemeId);
	const isStep2Completed = areDocsConfirmed;
	const isSuperUser = isAdmin(getRoleNameFromToken());

	let handleSubmit = () => {
		setIsDocsAgreementModalOpen(false);
		handleBookWithPayment();
	};

	let handleCancel = () => {
		setIsDocsAgreementModalOpen(false);
		setCurrentTab(String(Number(currentTab) - 1));
		open();
	};

	useEffect(() => {
		if (currentTab == 3) {
			close();
			return setIsDocsAgreementModalOpen(true);
		}
	}, [currentTab]);

	useEffect(() => {
		switch (user?.accountType) {
		case ACCOUNT_TYPES_MAP.individual:
			setDocLink(PRIVATE_CUSOMTER_AGREEMENT);
			break;
		case ACCOUNT_TYPES_MAP.legal:
			setDocLink(FIRMA_AGREEMENT);
			break;
		default:
			break;
		}
	}, [user]);

	useEffect(() => {
		if (selectedContractDuration || selectedInsuranceSchemeId) {
			if (sideBarStep1BottomRef && sideBarStep1BottomRef.current) {
				sideBarStep1BottomRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	});


	useEffect(() => {
		if (boxBookingContext.bookingMeta.selectedStock) {
			request('GET', `stocks/${boxBookingContext.bookingMeta.selectedStock.stockId_sm}/discount-schemes`)
				.then(setDiscountSchemes);
		}
	}, [boxBookingContext.bookingMeta.selectedStock]);

	const handleBookWithoutPayment = async () => {
		try {
			setLoadingState(true);

			let reservationParams = {
				stockId: selectedStockId,
				boxId: selectedBox.UnitID,
				arrivalDate: arrivalDate ? moment(arrivalDate).format('MM/DD/YYYY') : arrivalDate,
				departureDate: departureDate ? moment(departureDate).format('MM/DD/YYYY') : departureDate,
				contractDuration: selectedContractDuration,
				insuranceSchemeId: selectedInsuranceSchemeId,
				userId: getCurrentUserIdFromToken(),
				invoicingPeriodType,
				isWeeklyPaymentChosen,
				tariff
			};

			console.log('reservationParams', reservationParams);

			let result = await request('POST', 'boxes/reserve', reservationParams);

			if (env === 'production') {
				ym(89808973,'reachGoal', 'reservation_completed'); // eslint-disable-line
			}

			setLoadingState(false);

			if (result.redirectionUrl) {
				window.location = result.redirectionUrl;
			}
		} catch (error) {
			setLoadingState(false);
		}
	};

	const handleBookWithPayment = async () => {
		try {
			setLoadingState(true);

			let bookingParams = {
				stockId: selectedStockId,
				boxId: selectedBox.UnitID,
				arrivalDate: moment(arrivalDate).format('MM/DD/YYYY'),
				departureDate: moment(departureDate).format('MM/DD/YYYY'),
				contractDuration: selectedContractDuration,
				insuranceSchemeId: selectedInsuranceSchemeId,
				userId: getCurrentUserIdFromToken(),
				paymentMethod,
				invoicingPeriodType,
				isWeeklyPaymentChosen,
				tariff
			};

			let result = await request('POST', 'boxes/book', bookingParams);

			setLoadingState(false);

			if (result.redirectionUrl) {
				window.location = result.redirectionUrl;
			}
		} catch (error) {
			setLoadingState(false);
		}
	};

	const handleOpenDocsConfirmation = async () => {
		setCurrentTab(String(Number(currentTab) + 1));
	};

	const isCubicBox = selectedBox && selectedBox.UnitNumber.includes('KB');

	let isExternalBox = selectedBox && selectedBox.UnitNumber && selectedBox.UnitNumber.includes('D');

	let externalBoxTitle = (
		<div dangerouslySetInnerHTML={{ __html: languageSchema.BoxBooking.externalBoxDescription(LOCALIZATION_WARSAW_WEBSITE_URL) }} />
	);

	return (
		<Drawer
			width={(window.innerWidth > 500) ? 450 : '100%'}
			placement="right"
			onClose={close}
			visible={isOpened}
			footer={
				<SideBarFooter
					languageSchema={languageSchema}
					currentTab={currentTab}
					setTab={setCurrentTab}
					user={user}
				/>
			}
			title={
				<div>
					{
						selectedBox &&
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className="text-center">
									Box <b>{selectedBox.UnitNumber}</b>
								{
									<p className='mb-0 small'>
										{selectedBox.PhysicalSize} m<sup>{isCubicBox ? 3 : 2}</sup>
									</p>
								}

							</div>

							<div className="text-center">
								{
									boxBookingContext.bookingMeta.invoicingPeriodType === 'W' ?
										<>
											<b>
												{displayAmount(selectedBox.Weekrate + round(selectedBox.Weekrate * vatRate / 100), boxBookingContext.bookingMeta.selectedStock.currency)}
											</b> / <br />{languageSchema.BoxBooking.week} <br />
										</> :
										<>
											<b>
												{displayAmount(selectedBox.MonthRate + round(selectedBox.MonthRate * vatRate / 100), boxBookingContext.bookingMeta.selectedStock.currency)}
											</b> / <br />{languageSchema.BoxBooking.month} <br />
										</>
								}
							</div>
						</div>
					}
				</div>
			}
		>
			{!_.isEmpty(selectedBox) &&
				<div>
					<Tabs
						onTabClick={setCurrentTab}
						activeKey={currentTab}
						type="card"
						size="small"
					>
						<Tabs.TabPane
							tab={languageSchema.BoxBooking.bookingParams}
							key="1"
						>
							<ChoiceOfArrivalDate
								title={languageSchema.BoxBooking.choiceOfArrivalDateTitle}
								text={languageSchema.BoxBooking.choiceOfArrivalDateText}
								placeholderText={languageSchema.BoxBooking.choiceOfArrivalDatePlaceholder}
							/>

							{
								boxBookingContext.bookingMeta.arrivalDate &&
								<>
									<ChoiceOfDepartureDate
										title={`${languageSchema.BoxBooking.choiceOfDepartureDateTitle}(${languageSchema.Etc.optional})`}
										placeholderText={languageSchema.BoxBooking.choiceOfDepartureDatePlaceholder}
										languageSchema={languageSchema}
									/>
								</>
							}

							<Tariffs
								languageSchema={languageSchema}
								discountSchemes={discountSchemes}
							/>


							<InsuranceSelector
								stockId_sm={selectedStockId}
							/>
							<div ref={sideBarStep1BottomRef} />
						</Tabs.TabPane>

						{/* <Tabs.TabPane
							disabled={!isStep1Completed}
							tab={languageSchema.BoxBooking.docsAgreement}
							key="3"
						>
							<DocsList
								languageSchema={languageSchema}
								language={language}
								getContractDetailsForContractPdf={() => {}}
							/>
						</Tabs.TabPane> */}
						<Tabs.TabPane
							disabled={!isStep1Completed}
							tab={languageSchema.BoxBooking.reserveOrPay}
							key="2"
						>
							<div>
								<h4 className="mb-3">{languageSchema.BoxBooking.payment}</h4>
								<div>
									<button
										style={{ fontSize: '1em', width: '100%' }}
										type='button'
										id="reservation_button"
										className={`btn btn-primary ${process.env.REACT_APP_NODE_ENV === 'production' ? 'mrsCallTracking' : ''}`}
										onClick={handleBookWithoutPayment}/* handleOpenDocsConfirmation */
										disabled={isLoading || (!isSuperUser && (!arrivalDate))}
									>
										{
											isLoading ?
												languageSchema.Etc.wait :
												languageSchema.BoxBooking.bookWithoutPayment
										}
									</button>
									{isExternalBox ?
										<div className="d-flex align-items-center mt-3 mb-3">
											<span className="external-box__icon">&#10071;</span>
											{externalBoxTitle}
										</div>
										:
										null
									}
									<div className="mt-2">
										<Alert
											message={
												<div>
													<span>
														{languageSchema.BoxBooking.reservationDescription}
													</span>
												</div>
											}
											type="info"
										/>
									</div>
								</div>
								{/* should be continued after WContractPost procedure wii be added */}
								<div style={{ fontSize: '24px', textAlign: 'center', marginTop: '15px', marginBottom: '15px' }}>
									{languageSchema.Etc.or}
								</div>

								<div>
									<button
										style={{ fontSize: '1em', width: '100%' }}
										type='button'
										className={'btn btn-success'}
										onClick={handleOpenDocsConfirmation}
										disabled={isLoading || (!isSuperUser && (!arrivalDate || !paymentMethod))}
									>
										{
											isLoading ?
												languageSchema.Etc.wait :
												languageSchema.BoxBooking.bookAndPay
										}
									</button>

									<div className="mt-2">
										<Alert
											message={
												<div dangerouslySetInnerHTML={{ __html: languageSchema.BoxBooking.carLimitsWarning }} />
											}
											type="warning"
										/>
									</div>
								</div>
							</div>
						</Tabs.TabPane>
					</Tabs>
					<DocsAgreementModal
						isModalOpen={isDocsAgreementModalOpen}
						handleSubmit={handleSubmit}
						handleCancel={handleCancel}
						languageSchema={props.languageSchema}
						docLink={docLink}
					/>
				</div>
			}
		</Drawer>
	);
};


let mapStateToProps = state => ({
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(SideBar);

function SideBarFooter(props) {
	let {
		currentTab,
		setTab,
		languageSchema,
	} = props;

	let boxBookingContext = React.useContext(BoxBookingContext);

	let {
		bookingMeta: {
			arrivalDate,
			selectedContractDuration,
			selectedInsuranceSchemeId,
			areDocsConfirmed,
		},
		handleArrivalDateNotChosen,
		handleInsuranceNotChosen
	} = boxBookingContext;

	let handleContinueClick = () => {
		if (env === 'production') {
			ym(89808973,'reachGoal', 'sidebar_continue_button'); // eslint-disable-line
		}

		if (!arrivalDate) {
			handleArrivalDateNotChosen();

			return;
		}

		if (!selectedInsuranceSchemeId) {
			handleInsuranceNotChosen();

			return;
		}

		setTab(String(Number(currentTab) + 1));
	};

	let handleBackClick = () => {
		setTab(String(Number(currentTab) - 1));
	};

	let isStep1Completed = true || arrivalDate && selectedContractDuration && selectedInsuranceSchemeId;
	let isStep2Completed = areDocsConfirmed;

	let isContinueButtonDisabled =
		(currentTab === '1' && !isStep1Completed) ||
		(currentTab === '2' && !isStep2Completed);

	let getPricesDetails = () => {
		let {
			pureMonthRate,
			pureWeekRate,
			invoicingPeriodType,
			insuranceAmount,
			// discountAmount,
			// discountPercent,
			vatRate,
			vatAmount,
		} = boxBookingContext.bookingMeta;

		return (
			<div>
				<p className="d-flex justify-content-between">
					<span>{languageSchema.BoxBooking.rentalCost}: </span>
					<span>{displayAmount(invoicingPeriodType === 'W' ? pureWeekRate : pureMonthRate, boxBookingContext.bookingMeta.selectedStock.currency)}</span>
				</p>
				<p className="d-flex justify-content-between">
					<span>{languageSchema.BoxBooking.vatAmount} ({vatRate}%): </span>
					<span>{displayAmount(vatAmount, boxBookingContext.bookingMeta.selectedStock.currency)}</span>
				</p>
				{/*<p className="d-flex justify-content-between">
					<span>{languageSchema.BoxBooking.discount} ({discountPercent}%): </span>
					<span>-{displayAmount(discountAmount, boxBookingContext.bookingMeta.selectedStock.currency)}</span>
				</p> */}
				<p className="d-flex justify-content-between">
					<span>{languageSchema.BoxBooking.insurance}: </span>
					<span>{displayAmount(insuranceAmount, boxBookingContext.bookingMeta.selectedStock.currency)}</span>
				</p>
				<p className="d-flex justify-content-between">
					<span>{languageSchema.BoxBooking.deposit}: </span>
					<span>{displayAmount(invoicingPeriodType === 'W' ? pureWeekRate : pureMonthRate, boxBookingContext.bookingMeta.selectedStock.currency)}</span>
				</p>
			</div>
		);
	};

	return (
		<div className="sidebar_footer__buttons_wrapper">
			<div>
				{
					1 < currentTab &&
					<Button onClick={handleBackClick}>
						&lt; {props.languageSchema.BoxBooking.back}
					</Button>
				}
			</div>
			{
				boxBookingContext.bookingMeta.insuranceMonthRate &&
				boxBookingContext.bookingMeta.totalToPay &&
				<div>
					<div
						style={{ flexDirection: 'column' }}
						className={`d-flex flex-wrap ${1 < currentTab ? 'flex-center' : ''}`}
					>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
							<div className="sidebar_footer__total_price">
								{displayAmount(boxBookingContext.bookingMeta.totalToPay, boxBookingContext.bookingMeta.selectedStock.currency)}
							</div>
							<div className="mb-2" style={{ maxWidth: '230px' }}>
								{languageSchema.BoxBooking.forInitialServicePeriod}
							</div>
						</div>
						<div className='flex-center'>
							<Popover
								zIndex={100000}
								content={getPricesDetails()}
								title={languageSchema.BoxBooking.details}
								overlayStyle={{ width: '270px' }}
							>
								<div className="ml-2 mr-2 mb-2 btn btn-sm btn-outline-secondary">
									{languageSchema.BoxBooking.details}
								</div>
							</Popover>
						</div>
					</div>
				</div>

			}

			<div>
				{
					currentTab < 2 ?
						<Button
							type="primary"
							onClick={handleContinueClick}
							disabled={isContinueButtonDisabled}
						>
							{props.languageSchema.BoxBooking.continue} &gt;
						</Button> :
						<div></div>
				}
			</div>
		</div>
	);
}
