import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { fetchStocks, fetchCities } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import request from 'helpers/request';
import StocksList from 'components/MyBoxes/StocksList';
import NoItems from 'components/common/NoItems';
import Heading from 'components/UI/Heading';
import ReservationsList from 'components/MyBoxes/ReservationsList';

const MyBoxes = (props) => {
	const {
		stocks,
		user,
		isFetchedStocks,
		languageSchema,
		fetchStocks,
		changedStatusBox,
		language
	} = props;

	const [isLoading, setLoading] = useState(false);
	const [boxes, setBoxes] = useState([]);

	const [reservations, setReservations] = useState([]);

	useEffect(() => {
		if (changedStatusBox) {
			const changedBoxIndex = boxes.findIndex((box) => box._id === changedStatusBox._id);

			const copiedBoxes = [...boxes];

			copiedBoxes.splice(changedBoxIndex, 1, { ...boxes[changedBoxIndex], lockStatus: changedStatusBox.lockStatus });

			setBoxes(copiedBoxes);
		}
	}, [changedStatusBox]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setLoading(true);

		if (!isFetchedStocks) {
			await fetchStocks();
		}

		if (user._id) {
			const [customerBoxes, customerReservations] = await Promise.all([
				request('GET', `users/${user._id}/boxes`),
				request('GET', `users/${user._id}/reservations`),
			]);

			setBoxes(customerBoxes);
			setReservations(customerReservations);
		}

		setLoading(false);
	};

	const cancelReservation = async (reservationId) => {
		if (user._id) {
			await request(
				'PUT',
				`users/${user._id}/reservations/${reservationId}/cancel`
			);
		}
	};

	if (!user) {
		return <Redirect to={{ pathname: '/login' }} />;
	}

	return (
		<div>
			{isLoading ? (
				<div className="text-center">
					<i className="fas fa-2x fa-spinner fa-spin"></i>
				</div>
			) : (
				<div>
					{0 < reservations.length && (
						<div>
							<ReservationsList
								reservations={reservations}
								fetchData={fetchData}
								cancelReservation={cancelReservation}
								user={user}
							/>
						</div>
					)}
					{boxes.length === 0 ? (
						<div className="card">
							<Heading>{languageSchema.HomePage.myBoxes}</Heading>
							<NoItems
								linkText={languageSchema.BoxBooking.chooseABox}
							/>
						</div>
					) : (
						<StocksList
							stocks={stocks}
							language={language}
							languageSchema={languageSchema}
							user={user}
							boxes={boxes}
						/>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = ({ stocks, common, auth, boxes }) => {
	return {
		stocks: stocks.data,
		isFetchedStocks: stocks.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
		user: auth.me,
		changedStatusBox: boxes.changedStatusBox
	};
};


const mapDispatchToProps = {
	fetchStocks,
	fetchCities,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBoxes);
