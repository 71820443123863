import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';

import { setToken } from 'helpers/request';
import { closeNotificationModal } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { isClient } from 'utils/functions';

import Header from 'components/Header';
import NotificationModal from 'components/NotificationModal';
import CallBackButton from 'components/Support/CallBackButton';

function Layout(Component) {
	class ChildComponent extends React.Component {
		async componentDidMount() {
			let authTokenFromQuery = qs.parse(window.location.search).authToken;

			console.log('authTokenFromQuery', authTokenFromQuery);

			if (authTokenFromQuery) {
				setToken(authTokenFromQuery);
			}

		}

		render() {
			let { currentUser, languageSchema, isNotificationModalOpen, notificationInfo, closeNotificationModal } = this.props;
			let isUserClient = isClient(currentUser.role);

			return (
				<div>
					<Header />
					<div className="long-container container">
						{
							isUserClient &&
							<div className="callback-btn-wrapper">
								<CallBackButton languageSchema={languageSchema}/>
							</div>
						}
						<Component />
						<NotificationModal visible={isNotificationModalOpen} data={notificationInfo} languageSchema={languageSchema} closeNotificationModal={closeNotificationModal}/>
					</div>
				</div>
			);
		}
	}

	let mapStateToProps = (state) => ({
		currentUser: state.auth.me,
		isNotificationModalOpen: state.notifications.isNotificationModalOpen,
		notificationInfo: state.notifications.notificationInfo,
		languageSchema: getLanguageSchema(state.common.language),
	});

	let mapDispatchToProps = {
		closeNotificationModal,
	};

	return connect(mapStateToProps, mapDispatchToProps)(ChildComponent);
}

export default Layout;
