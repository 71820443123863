import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { getLanguageSchema } from 'dashboard-commons/languages';
import ResetPwdForm from 'components/Auth/ResetPwdForm';
import { logOut, resetUserPassword } from 'redux/actions';
import { errorCodesMap } from 'utils/constants';

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email(errorCodesMap.enterValidEmail)
		.required(errorCodesMap.emailIsRequired),
});

class ResetPwdPage extends React.Component {
	componentDidMount() {
		const {
			logOut
		} = this.props;

		logOut();
	}

	componentDidUpdate() {
		const { isAuth } = this.props;

		if (isAuth) {
			this.props.history.push('/');
		}
	}

		handleSubmit = async (values, { setSubmitting }) => {
			const { resetUserPassword, languageSchema } = this.props;

			try {
				await resetUserPassword(values);
				toastr.success(languageSchema.User.passwordReset, languageSchema.User.passwordWillBeSentToMail);
				this.props.history.push('/login');
			} finally {
				setSubmitting(false);
			}
		}

		render() {

			return (
				<div className="row pt-5">
					<div className="col-md-4 offset-md-4">
						<Formik
							initialValues={{ email: '' }}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema}
							render={(props) =>
								<ResetPwdForm
									{...props}
									languageSchema={this.props.languageSchema}
									isBack
								/>
							}
						/>
					</div>
				</div>
			);
		}
}

const mapStateToProps = ({ auth, common }) => ({
	isAuth: auth.isAuth,
	languageSchema: getLanguageSchema(common.language)
});
export default connect(mapStateToProps, { logOut, resetUserPassword })(ResetPwdPage);
