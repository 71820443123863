import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import Heading from 'components/UI/Heading';
import { openStockEquipment } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';


let InternalEquipments = (props) => {
	let { languageSchema, stock, openStockEquipment } = props;
	let { internalEquipments } = stock;

	let handleOpenEquipment = (equipment) => () =>
		toastr.confirm(`${languageSchema.Stock.areYouSureYouWantToOpen} "${equipment.name}" ?`, {
			onOk: () => {
				openStockEquipment(stock._id, equipment.id);
			}
		});

	return (

		<>
			<Heading>{languageSchema.Stock.control}</Heading>
			<table className="table table-borderless">
				<tbody>
					{
						internalEquipments &&
						internalEquipments.map(equipment => (
							<tr key={equipment._id}>
								<th scope="row" width="40%">{equipment.name} {Boolean(equipment.nameEN) && `(${equipment.nameEN})`}</th>
								<td className="text-right">
									<button
										variant="outline-dark"
										className="btn btn-outline-secondary btn-sm"
										onClick={handleOpenEquipment(equipment)}
									>
										{languageSchema.Buttons.open}
									</button>
								</td>
							</tr>
						))
					}
				</tbody>
			</table>
		</>
	);
};

let mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
	};
};

let mapDispatchToProps = {
	openStockEquipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalEquipments);
