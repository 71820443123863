import React from 'react';
import moment from 'moment';
import { Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import { DATE_FORMAT } from 'utils/constants';
import { isDevelompent } from 'utils/functions';

function BoxForm(props) {
	const { stocks, type, isSuccess, values, touched, errors, isSubmitting, setFieldValue, handleBlur, setFieldError } = props;

	const isError = field => errors[field] && touched[field];
	const isEdit = type === 'edit';
	const buttonText = isEdit ? 'Сохранить' : 'Создать';
	const { isExternal } = values;

	const currentStock = stocks.find(stock => stock._id === values.stockId);
	const prevLink = currentStock ? `/boxes?stockId=${currentStock._id}` : '/stocks';

	return (
		<Form>
			<div className="form-group row">
				<label htmlFor="country" className="col-sm-2 col-form-label">Размер</label>
				<div className="col-sm-10">
					<div className={`input-group ${isError('size') ? 'is-invalid' : ''}`}>
						<Field
							name="size"
							type="number"
							readOnly={isExternal}
							className={`form-control ${isError('size') ? 'is-invalid' : ''}`}
							value={values.size}
							min="1"
							max="999"
							step="0.5"
						/>
						<div className="input-group-append">
							<span className="input-group-text">м²</span>
						</div>
					</div>
					{isError('size') && <div className="invalid-feedback">{errors.size}</div>}
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="city" className="col-sm-2 col-form-label">Описание</label>
				<div className="col-sm-10">
					<Field
						name="description"
						component="textarea"
						readOnly={isExternal}
						className={`form-control ${isError('description') ? 'is-invalid' : ''}`}
						placeholder="Введите описание"
					></Field>
					{isError('description') && <div className="invalid-feedback">{errors.description}</div>}
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="stockId" className="col-sm-2 col-form-label">Склад</label>
				<div className="col-sm-10">
					<Field
						name="stockId"
						component="select"
						readOnly={isExternal}
						className={`form-control ${isError('stockId') ? 'is-invalid' : ''}`}
					>
						<option value="">Выберите склад</option>
						{stocks.map(stock =>
							<option key={stock._id} value={stock._id}>
								{stock.address}
								{`${stock.city				&& ' | г. ' + stock.city}`}
								{`${stock.country && ', ' + stock.country}`}
							</option>)
						}
					</Field>
					{isError('stockId') && <div className="invalid-feedback">{errors.stockId}</div>}
				</div>
			</div>

			<div className="form-group row align-items-center">
				<label htmlFor="categoryId" className="col-sm-2 col-form-label">Категория</label>
				<div className="col-sm-10">
										Категория определяется автоматически
					{/*
										<Field
												name="categoryId"
												component="select"
												readOnly={isExternal}
												className={`form-control ${isError('categoryId') ? 'is-invalid' : ''}`}
										>
												<option key={0} value="" disabled>Не выбрано</option>
												{boxcats.map(boxcat => (
														<option key={boxcat._id} value={boxcat._id}>
																{boxcat.name} {` `}
																({`от ${"minSize" in boxcat ? boxcat.minSize : '?'} до ${"maxSize" in boxcat ? boxcat.maxSize : '?'} м²`})
														</option>
												))}
										</Field>
										{isError('categoryId') && <div className="invalid-feedback">{errors.categoryId}</div>}
										*/}
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="lockId" className="col-sm-2 col-form-label">Замок</label>
				<div className="col-sm-10">
					{/*
										<Field
												name="lockId"
												type="text"
												className={`form-control ${isError('lockId') ? 'is-invalid' : ''}`}
												value={values.lockId}
										/>
								 */}
					<MaskedInput
						mask={[/\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
						placeholder="X:YY:ZZ"
						name="lockId"
						type="text"
						className={`form-control ${isError('lockId') ? 'is-invalid' : ''}`}
						value={values.lockId}
						onChange={(e) => {
							const value = e.target.value;


							if (value.replace(/[^0-9]/g, '').length !== 5) {
								setFieldError('lockId', 'MESSAGE');
							}

							setFieldValue('lockId', value);
						}}
						onBlur={handleBlur}
					/>
					{isError('lockId') && <div className="invalid-feedback">{errors.lockId}</div>}
				</div>
			</div>
			{isEdit &&
								<div className="form-group row">
									<label htmlFor="created" className="col-sm-2 col-form-label">Создано</label>
									<div className="col-sm-10">
										<input type="text" readOnly className="form-control-plaintext" id="created"
											value={values.created && moment(values.created).format(DATE_FORMAT)} />
									</div>
								</div>
			}
			<div className="row">
				<div className="col-auto">
					<button type="submit" className="btn btn-sm btn-secondary mr-3" disabled={isSubmitting}>
						{isSubmitting ? 'Подождите ...' : buttonText}
					</button>
					<Link to={prevLink} className="btn btn-sm btn-outline-secondary">Назад</Link>
				</div>
				{isEdit &&
										<div className="col text-right">
											<i>{values.updated ?
												'Последнее обн. ' + moment(values.updated).format(DATE_FORMAT) :
												'Обновлений не было'}</i>
										</div>
				}
			</div>
			{isSuccess &&
								<div className="alert alert-success mt-3" role="alert">
								Успешно
								</div>
			}
			{isDevelompent &&
								<pre className="mt-3">
									{JSON.stringify(values, null, 2)}
								</pre>
			}
		</Form>
	);
}

export default BoxForm;
