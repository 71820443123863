import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getLanguageSchema } from 'dashboard-commons/languages';

import Checkbox from 'react-simple-checkbox';

import sizeSImage from 'assets/images/size3.png';
import sizeMImage from 'assets/images/size4.png';
import sizeLImage from 'assets/images/size5.png';
import tour from 'assets/images/tour.png';
import { TOURS_URL, env } from 'utils/constants';
import { navigateToExternalUrl } from 'helpers/common';

export let sizesGroupsMap = {
	s: {
		key: 's',
		min: 0.5,
		max: 5,
		image: sizeSImage,
	},
	m: {
		key: 'm',
		min: 5.5,
		max: 8,
		image: sizeMImage,
	},
	l: {
		key: 'l',
		min: 8.5,
		max: 40,
		image: sizeLImage,
	},
};


const SizeGroups = (props) => {
	const {
		selectedStockId,
		languageSchema,
		selectedSizeGroup,
		setSelectedSizeGroup,
		fetchedBoxes,
	} = props;

	let isMobile = window.innerWidth < 700;
	const getActivityClass = (size) =>
		!_.isEmpty(selectedSizeGroup) && selectedSizeGroup.sizeGroup === size
			? 'selected'
			: '';
	const isChecked = (sizeGroup) =>
		!_.isEmpty(selectedSizeGroup) &&
		selectedSizeGroup.sizeGroup === sizeGroup;

	return (
		<div
			id="size-group"
			className={`container card mt-${isMobile ? 5 : 1}`}
		>
			<div disabled={_.isEmpty(selectedStockId)} className="row pt-4">
				<div
					className={`mb-2 mt-${isMobile ? 5 : 1}`}
					style={{ fontSize: '20px', fontWeight: 'bold' }}
				>
					{languageSchema.BoxBooking.step2SelectTheSizeOfTheBox}
				</div>
				{Object.keys(sizesGroupsMap).map((el, index) => (
					<div
						className="col-md-4 flex-vertical-center"
						key={sizesGroupsMap[el].min}
					>
						<div
							className={`group_size_selector mb-3 py-2 ${getActivityClass(
								el
							)}`}
							id={`sizes_group_${el}`}
							onClick={() => {
								if (env === 'production') {
									ym(89808973,'reachGoal', `sizes_group_${el}`); // eslint-disable-line
								}

								setSelectedSizeGroup({
									sizeGroup: el,
									sizeCodes: _.uniq(
										fetchedBoxes
											.filter(
												({ PhysicalSize }) =>
													sizesGroupsMap[el].min <=
														PhysicalSize &&
													PhysicalSize <=
														sizesGroupsMap[el].max
											)
											.map(
												({ PhysicalSize }) =>
													PhysicalSize
											)
									),
								});
							}}
							style={{ cursor: 'pointer' }}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									height: '200px',
									marginTop: '40px'
								}}
							>
								<img src={sizesGroupsMap[el].image} />
							</div>
							<div style={{
								position: 'absolute',
								right: '35px',
								display: 'flex',
								alignItems: 'center',
								color: '#ff6700',
								fontWeight: '600',
								gap: '5px'
							}}
							onClick={(e)=> navigateToExternalUrl(e, TOURS_URL[el])}>
								{languageSchema.BoxBooking.viewThisbox}
								<img src={tour} alt="tour" style={{
									padding: '0px',
									width: '50px'
								}}/>
							</div>
							<div style={{ margin: '1em' }}>
								<Checkbox
									color="#ff6700"
									size={4}
									tickAnimationDuration={40}
									backAnimationDuration={40}
									borderThickness={2}
									checked={isChecked(el)}
								/>
							</div>
							<div className="sizes_label">
								<div className="text-center">
									{sizesGroupsMap[el].min} -{' '}
									{sizesGroupsMap[el].max} m<sup>2</sup>{' '}
									<br />
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: languageSchema.BoxBooking
											.sizesMeta[el],
									}}
									style={{
										fontSize: '16px',
										margin: '10px auto',
										width: '100%',
									}}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

let mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(SizeGroups);
