const initialState = {
	data: [],
	isFetched: false
};

export default function reducer(state = initialState, action) {

	let boxcats;
		
	switch (action.type) {
	case 'FETCH_BOXCATS':
		return { ...state, data: action.payload, isFetched: true };
				
	case 'CREATE_BOXCAT':
		return { ...state, data: [...state.data, action.payload] };

	case 'UPDATE_BOXCAT':
		boxcats = state.data.map(boxcat => (boxcat._id === action.payload._id) ? { ...boxcat, ...action.payload } : boxcat);
		return { ...state, data: boxcats };

	case 'DELETE_BOXCAT':
		boxcats = state.data.filter(boxcat => boxcat._id !== action.payload);
		return { ...state, data: boxcats };

	default:
		return state;
	}

}