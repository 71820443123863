import React from 'react';
import querystring from 'querystring';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import Loader from 'components/common/OverlayLoader';
import topboxLogo from 'assets/images/topbox_large_logo.png';
import { apiUrl } from 'utils/constants';

import { toBaseUnit, displayAmount } from 'helpers/amount';

import CardSection from './CardSection';

function CheckoutForm(props) {
	const stripe = useStripe();
	const elements = useElements();

	let { paymentData, languageSchema, apiStartLoading, apiEndLoading } = props;

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		apiStartLoading(true);

		const result = await stripe.confirmCardPayment(paymentData.secret, {
			payment_method: {
				card: elements.getElement(CardElement),
				billing_details: {
					name: paymentData.userName,
				},
			}
		});

		if (result.error && result.error.code && result.error.type === 'validation_error') {
			apiEndLoading(false);
		} else {
			let redirectionUrl = getRedirectionUrl(result, languageSchema);

			window.location = redirectionUrl;

			apiEndLoading(false);
		}

	};

	return (
		<div>
			<Loader />

			<form onSubmit={handleSubmit}>
				<div className="card" style={{ maxWidth: '450px' }}>
					<div className="text-center mb-4">
						<img
							src={topboxLogo}
							alt="Rentabox"
							title="Rentabox"
							width="75%"
						/>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							{languageSchema.Payment.totalToPayString}
						</div>
						<div>
							<b>{displayAmount(toBaseUnit(paymentData.totalAmount, paymentData.currency), paymentData.currency)}</b>
						</div>
					</div>

					<div className="mb-3" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem', color: 'grey' }}>
						<div>
							{languageSchema.Payment.includeVAT}
						</div>
						<div>
							<b>{displayAmount(toBaseUnit(paymentData.vatAmount, paymentData.currency), paymentData.currency)}</b>
						</div>
					</div>

					<div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							{languageSchema.Payment.cardHolder}
						</div>
						<div>
							<b>{paymentData.userName}</b>
						</div>
					</div>

					<CardSection language={props.language}/>

					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<button style={{ width: '180px' }} className="btn btn-success success" disabled={!stripe}>
							{languageSchema.Payment.pay}
						</button>
					</div>
					<hr />

					<div>
						<small style={{ display: 'flex', alignItems: 'center' }} className="form-text">
							<i style={{ marginRight: '10px' }} className="fas fa-info-circle text-info"> </i> {'		'}
							<span>{languageSchema.Payment.threeDSecureRedirect}</span>
						</small>
					</div>
				</div>
			</form>
		</div>
	);
}

export default CheckoutForm;

function getRedirectionUrl (processorResponse, languageSchema) {
	let queryData = getPaymentDataFromProcessorResponse(processorResponse, languageSchema);

	let redirectionUrl =
		`${apiUrl}/payments/return/marker_${queryData.paymentStatus}?${querystring.stringify(queryData)}`;

	return redirectionUrl;
}

function getPaymentDataFromProcessorResponse(processorResponse, languageSchema) {
	let isFailed = processorResponse.error;

	console.log('processorResponse.error', processorResponse.error);

	let errorReason = isFailed ?
		getErrorLocalizedErrorReason(processorResponse.error.decline_code, languageSchema) :
		null;

	if (isFailed) {
		return {
			// processorTransactionId: processorResponse.error.payment_intent.id,
			// cardHolder: processorResponse.error.payment_intent.last_payment_error.payment_method.billing_details.name,
			// price: toBaseUnit(processorResponse.error.payment_intent.amount, processorResponse.error.payment_intent.currency.toUpperCase()),
			// truncatedCardNumber: `******${processorResponse.error.payment_intent.last_payment_error.payment_method.card.last4}`,
			paymentStatus: 'failed',
			isFailed,
			errorReason,
			// currency: processorResponse.error.payment_intent.currency.toUpperCase(),
		};
	}

	return {
		processorTransactionId: processorResponse.paymentIntent.id,
		price: toBaseUnit(processorResponse.paymentIntent.amount, processorResponse.paymentIntent.currency.toUpperCase()),
		paymentStatus: 'success',
	};
}

function getErrorLocalizedErrorReason(errorCode, languageSchema) {
	if (languageSchema.Payment.errors[errorCode]) {
		return languageSchema.Payment.errors[errorCode];
	}

	return languageSchema.Payment.errors.unrecognized;
}
