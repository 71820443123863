import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import NoItems from 'components/common/NoItems';
import { openStockEquipment } from 'redux/actions';
import history from 'utils/history';
import request from 'helpers/request';

import BoxItem from './BoxItem';

const ListItem = props => {
	const { languageSchema, boxes, stock, user, language } = props;
	const [firstBox] = boxes;

	const [personalInvoices, setPersonalInvoices] = useState([]);

	const cityName = stock.cityId ? stock.cityId.city : stock.city;
	const countryName = stock.cityId ? stock.cityId.country : stock.country;

	const handleOpenEquipment = (equipment) => () => {
		let itemName = (language === 'EN' && equipment.nameEN) || equipment.name;

		toastr.confirm(`${languageSchema.Stock.areYouSureYouWantToOpen} "${itemName}" ?`, {
			onOk: () => {
				props.openStockEquipment(stock._id, equipment.id);
			}
		});
	};

	useEffect(() => {
		request('GET', `users/${user._id}/personal-invoices`)
			.then((personalInvoices) => {
				setPersonalInvoices(personalInvoices.filter(({debt}) => debt > 0));
			});
	}, []);

	return (
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						{
							personalInvoices?.length ?
								<div className="mt-4 mb-4 pt-3 px-5 text-center bg-white" style={{
									border: '1px solid #f26704'
								}}>
									<Link to={`/users/${user._id}/contracts/${firstBox.ContractID}#userInvoices`}>
										<h5 dangerouslySetInnerHTML={{ __html: languageSchema.HomePage.unpaidDebts('#1890ff') }}/>
									</Link>
								</div> :
								null
						}
						<div>
							<button className="mb-3 btn btn-sm btn-outline-primary" onClick={() => history.goBack()}>
								{languageSchema.BoxBooking.back}
							</button>
						</div>
						<h5 className="card-title d-flex justify-content-between">
							<div>{languageSchema.Stock.stock} {stock.name}</div>
						</h5>
						<p className="card-text">
							{stock.address}, {stock.zip} <br />
							{cityName}, {countryName}
						</p>
						<p className="card-text">
							Wi-Fi: <b>{stock.wifiNetworkName}</b> <br />
							{languageSchema.Stock.wifiPassword}: <b>{stock.wifiPassword}</b>
						</p>
						<div className="inline-flex flex-wrap">
							{
								stock.internalEquipments &&
								stock.internalEquipments.map(equipment => (
									<div key={equipment._id}>
										<button
											variant="outline-dark"
											className="btn btn-outline-success btn-md mx-2 mb-2"
											onClick={handleOpenEquipment(equipment)}
										>
											<i className={'fas fa-key'}> {' '} </i> {(language === 'EN' && equipment.nameEN) || equipment.name}
										</button>
									</div>
								))
							}
						</div>
						<hr />
						<h5 className="card-title d-flex justify-content-between">
							{languageSchema.Stock.boxes}
						</h5>

						{
							boxes.length === 0 ?
								<NoItems /> :
								<div className="inline-flex flex-wrap">
									{
										boxes.map(
											box =>
												<BoxItem
													key={box._id}
													box={box}
													languageSchema={languageSchema}
													stock={stock}
													user={user}
												/>
										)
									}
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

let mapDispatchToProps = {
	openStockEquipment,
};

export default connect(null, mapDispatchToProps)(ListItem);
