import React from 'react';

// This component is used for mobile app. mobile app opens this urls page as chat page
class JivoPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isModalOpened: false,
			isRequesting: false,
		};
	}

	componentDidMount() {
		const script = document.createElement('script');

		script.src = null;

		script.async = true;

		document.body.appendChild(script);
	}

	render() {
		return (
			<div />
		);
	}
}

export default JivoPage;
