import React, { Component } from 'react';
import Heading from 'components/UI/Heading';
import request from 'helpers/request';

export default class SmtpSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			smtpHost: props.stock.smtpHost || '',
			smtpPort: props.stock.smtpPort || 587,
			smtpEmail: props.stock.smtpEmail || '',
			smtpPassword: props.stock.smtpPassword || '',
			smtpSender: props.stock.smtpSender || '',
			isLoading: false,
		};
	}

		saveSmtpForm = async () => {
			this.setState({ isLoading: true });
			try {
				await request('PUT', `stocks/${this.props.stock._id}`, {
					smtpHost: this.state.smtpHost,
					smtpPort: this.state.smtpPort,
					smtpEmail: this.state.smtpEmail,
					smtpPassword: this.state.smtpPassword,
					smtpSender: this.state.smtpSender,
				});

				this.setState({ isLoading: true });
			} catch(error) {
				this.setState({ isLoading: false });
			}

			setTimeout(() => {
				this.setState({ isLoading: false });
			}, 2000);
		}

		handleChangeField = fieldName => e => this.setState({ [fieldName]: e.target.value})

		render() {
			let { languageSchema, isSuperUser } = this.props;
			return (
				<div className="row">
					<div className="col-md-12">
						<Heading>Smtp Settings:</Heading>

						<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
							<label htmlFor="insuranceCoverageAmount" className="col-sm-5 col-form-label">
								SMTP host:
							</label>
							<div className="col-sm-5">
								<div className="input-group">
									<input
										name="smtpHost"
										placeholder='smtp host'
										type="string"
										className="form-control"
										value={this.state.smtpHost}
										disabled={!isSuperUser}
										onChange={this.handleChangeField('smtpHost')}
									/>
								</div>
							</div>
						</div>

						<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
							<label htmlFor="insuranceCoverageAmount" className="col-sm-5 col-form-label">
								SMTP port:
							</label>
							<div className="col-sm-5">
								<div className="input-group">
									<input
										name="smtpPort"
										placeholder='smtp port'
										type="number"
										className="form-control"
										value={this.state.smtpPort}
										disabled={!isSuperUser}
										onChange={this.handleChangeField('smtpPort')}
									/>
								</div>
							</div>
						</div>

						<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
							<label htmlFor="insuranceCoverageAmount" className="col-sm-5 col-form-label">
								SMTP Email:
							</label>
							<div className="col-sm-5">
								<div className="input-group">
									<input
										name="smtpEmail"
										placeholder='smtp sender email'
										type="string"
										className="form-control"
										value={this.state.smtpEmail}
										disabled={!isSuperUser}
										onChange={this.handleChangeField('smtpEmail')}
									/>
								</div>
							</div>
						</div>

						<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
							<label htmlFor="insuranceCoverageAmount" className="col-sm-5 col-form-label">
								SMTP Username:
							</label>
							<div className="col-sm-5">
								<div className="input-group">
									<input
										name="smtpUsername"
										placeholder='smtp username'
										type="string"
										className="form-control"
										value={this.state.smtpUsername}
										disabled={!isSuperUser}
										onChange={this.handleChangeField('smtpUsername')}
									/>
								</div>
							</div>
						</div>

						<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
							<label htmlFor="insuranceMonthlyAmount" className="col-sm-5 col-form-label">
								SMTP password:
							</label>
							<div className="col-sm-5">
								<div className="input-group">
									<input
										name="smtpPassword"
										placeholder='smtp password'
										type="string"
										className="form-control"
										value={this.state.smtpPassword}
										disabled={!isSuperUser}
										onChange={this.handleChangeField('smtpPassword')}
									/>
								</div>
							</div>
						</div>

						<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
							<label htmlFor="insuranceMonthlyAmount" className="col-sm-5 col-form-label">
								SMTP sender name:
							</label>
							<div className="col-sm-5">
								<div className="input-group">
									<input
										name="smtpSender"
										placeholder='smtp sender name'
										type="string"
										className="form-control"
										value={this.state.smtpSender}
										disabled={!isSuperUser}
										onChange={this.handleChangeField('smtpSender')}
									/>
								</div>
							</div>
						</div>

						<div style={{ textAlign: 'left' }}>
							<button
								className="btn btn-success"
								onClick={this.saveSmtpForm}
								disabled={!isSuperUser}
							>
								{this.state.isLoading && <span className="spinner-border spinner-border-sm"> </span>}
								<span> {languageSchema.Buttons.apply} </span>
							</button>
						</div>

					</div>
				</div>
			);
		}
}
