import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';

import Table from 'components/UI/Table';
import Pagination from 'components/UI/Pagination';
import DayPicker from 'components/UI/DayPicker';
import { getPageCount } from 'utils/functions';
import { DATE_FORMAT } from 'utils/constants';
import {getLanguageSchema} from 'dashboard-commons/languages';

const actions = {
	opened: 'opened',
	closed: 'closed',
};

const accountTypes = {
	superUser: 'superUser',
	client: 'client'
};

const getAccountTypeLabel = (accountType, languageSchema) => {
	return languageSchema.Role[accountTypes[accountType]];
};

const getActionLabel = (action, languageSchema) => {
	return languageSchema.Stock[actions[action]];
};

const getPrettyDate = (date, format = DATE_FORMAT) => {
	return moment(date).format(format);
};

const getPrettyTime = date => {
	return moment(date).format('HH:mm:ss');
};

const getTableHeaderFields = (type, languageSchema) => {
	switch (type) {
	case 'stock':
		return [languageSchema.Stock.date, languageSchema.Stock.box, languageSchema.Stock.account, languageSchema.Stock.action];

	case 'box':
		return [languageSchema.Stock.date, languageSchema.Stock.account, languageSchema.Stock.action];

	default:
		return [];
	}
};

const getBoxHistoryItem = (el, languageSchema) => (
	<tr key={el._id}>
		<td>
			{getPrettyDate(el.created)} <br />
			<small> {getPrettyTime(el.created)} </small>
		</td>
		<td>
			{el.userId && el.userId.email} <br />
			<small>{el.userId && el.userId.role}</small>
		</td>
		<td className={`font-${el.action.includes('open') ? 'green' : 'red'}`}>
			{getActionLabel(el.action, languageSchema)}
		</td>
	</tr>
);

const getStockHistoryItem = (el, languageSchema) => (
	<tr key={el._id}>
		<td>{getPrettyDate(el.created)} <br /> <small>{getPrettyTime(el.created)}</small></td>
		<td> {languageSchema.Stock.box} {el.size} м² <br /> <small>#{el.boxNumber}</small></td>
		{el.userId ?
			<td>
				{el.userId.email} <br />
				<small>
					{el.userId.role && getAccountTypeLabel(el.userId.role, languageSchema)}
				</small>
			</td> :
			<td>-</td>
		}
		<td className={`font-${el.action.includes('open') ? 'green' : 'red'}`}>
			{getActionLabel(el.action, languageSchema)}
		</td>
	</tr>
);

const getTableBody = (type, el, languageSchema) => {
	switch (type) {
	case 'stock':
		return getStockHistoryItem(el, languageSchema);

	case 'box':
		return getBoxHistoryItem(el, languageSchema);

	default:
		return null;
	}
};

class BoxHistory extends React.Component {
	state = {
		filterByType: 'all',
		filterByBoxNumber: '',
		from: null,
		to: null,

		pageCount: 1,
		offset: 0,
		selected: null,

		filteredHistory: null,
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value.toLowerCase() }, this.filterHistory);
	}

	handlePageClick = data => {
		const { perPage } = this.props;

		let selected = data.selected;
		let offset = Math.ceil(selected * perPage);

		this.setState({ offset, selected });
	}

	filterHistory = () => {
		const { history, type, perPage } = this.props;

		if (history.length === 0) {
			return;
		}

		const { filterByType, filterByBoxNumber, from, to } = this.state;
		const isStockHistory = type === 'stock';
		const isVisibleAll = filterByType === 'all';

		if (
			filterByBoxNumber === '' &&
					(!from && !to) &&
					isVisibleAll
		) {
			this.setState({
				filteredHistory: null,
				pageCount: getPageCount(history.length, perPage),
			});
			return;
		}

		let cloneHistory = [...history];

		if (filterByType && filterByType !== 'all') {
			cloneHistory = cloneHistory.filter(item => item.action === filterByType);
		}

		if (from && to) {
			const DATE_FORMAT_FOR_FILTER = 'YYYY-MM-DD';

			cloneHistory = cloneHistory.filter(item => {
				const prettyDate = getPrettyDate(item.created, DATE_FORMAT_FOR_FILTER);
				const prettyDateFrom = getPrettyDate(from, DATE_FORMAT_FOR_FILTER);
				const prettyDateTo = getPrettyDate(to, DATE_FORMAT_FOR_FILTER);

				return moment(prettyDate).isBetween(prettyDateFrom, prettyDateTo, null, '[]');
			});
		}

		if (isStockHistory && filterByBoxNumber) {
			cloneHistory = cloneHistory.filter(item => item.boxNumber && item.boxNumber.toLowerCase().includes(filterByBoxNumber));
		}

		this.setState({
			filteredHistory: cloneHistory,
			pageCount: getPageCount(cloneHistory.length, perPage),
			selected: 0,
			offset: 0,
		});
	}

	handleSelectDateRange = (from, to) => {
		this.setState({ from, to }, this.filterHistory);
	}

	componentDidUpdate(prevProps) {
		const { history, perPage } = this.props;
		if (prevProps.history.length !== history.length) {
			this.setState({
				pageCount: getPageCount(history.length, perPage),
			});
		}
	}

	render() {
		const { history, type, perPage, languageSchema, language } = this.props;
		const { filteredHistory, filterByType, pageCount, offset, selected } = this.state;

		const isStockHistory = type === 'stock';

		const refHistory = filteredHistory ? filteredHistory : history;

		const currentHistory = refHistory.filter((el, index) => {
			return index >= offset && index <= offset + perPage - 1;
		});

		return (
			<div>
				{currentHistory.length > 0 &&
									<div className="mb-3 d-flex justify-content-end">
										<Pagination selected={selected} pageCount={pageCount} onPageChange={this.handlePageClick} />
									</div>
				}
				<div className="custom-filter mb-3">
					<div className="form-row">
						<div className="col">
							<label>{languageSchema.Stock.date}</label>
							<DayPicker
								handleSelectDateRange={this.handleSelectDateRange}
								language={language}
								languageSchema={languageSchema}
							/>
						</div>
						{isStockHistory &&
													<div className="col">
														<label>{languageSchema.Stock.boxNumber}</label>
														<input className="form-control" name="filterByBoxNumber" onChange={this.handleChange} />
													</div>
						}
						<div className="col">
							<label>{languageSchema.Stock.action}</label>
							<select name="filterByType" value={filterByType} className="form-control" onChange={this.handleChange}>
								<option disabled>{languageSchema.Etc.choose}</option>
								<option value="all">{languageSchema.Etc.all}</option>
								{Object.keys(actions).map((action, i) =>
									<option key={i} value={action}>{getActionLabel(action, languageSchema)}</option>
								)}
							</select>
						</div>
					</div>
				</div>
				{currentHistory.length > 0
					?
					<Table header={getTableHeaderFields(type, languageSchema)}>
						{currentHistory.map(el => getTableBody(type, el, languageSchema))}
					</Table>
					:
					<div><b>{languageSchema.Stock.noHistoryRecords}</b></div>
				}
				{currentHistory.length > 0 &&
									<div className="mb-3 d-flex justify-content-end">
										<Pagination selected={selected} pageCount={pageCount} onPageChange={this.handlePageClick} />
									</div>
				}
			</div>
		);
	}
}

BoxHistory.defaultProps = {
	perPage: 10,
};

const mapStateToProps = ({common }) => ({
	languageSchema: getLanguageSchema(common.language),
	language: common.language,
});

export default connect(mapStateToProps, null)(BoxHistory);
