import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'formik';
import Heading from 'components/UI/Heading';

const ResetPwdForm = (props) => {
	const { values, touched, errors, isBack, isSubmitting, languageSchema, initialValues } = props;

	let isError = field => errors[field] && touched[field];

	return (
		<Form>
			<div className="card">
				<div className="form-group">
					<Heading>{languageSchema.User.passwordReset}</Heading>
					<label htmlFor="email">{languageSchema.User.email}:</label>
					<Field
						id="email"
						type="email"
						className={`form-control ${isError('email') ? 'is-invalid' : ''}`}
						value={values.email}
						disabled={initialValues.email}
						placeholder="Email"
					/>
					{isError('email') &&
												<div className="invalid-feedback">
													{languageSchema.errors[errors.email]}
												</div>}
				</div>
				<div className="d-flex justify-content-between">
					<button type="submit" className="btn btn-sm btn-secondary" disabled={isSubmitting}>
						{isSubmitting ? languageSchema.Etc.wait : languageSchema.User.resetPassword}
					</button>
					{isBack &&
												<Link to='/login' className="btn btn-sm btn-outline-secondary ml-3">
													{languageSchema.Buttons.back}
												</Link>
					}
				</div>
			</div>
		</Form>
	);
};

export default ResetPwdForm;
