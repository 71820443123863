import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Segmented } from 'antd';

import { mapIsDragging } from 'redux/actions';
import { displayAmount, round, getCurrencySign } from 'helpers/amount';
import { getLanguageSchema } from 'dashboard-commons/languages';
import BoxBookingContext from 'screens/BoxBookingNew/Context';
import BoxesMap from 'components/Stock/Map/BookingBoxesMap';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

const BoxesList = (props) => {
	const { fetchedBoxes, selectedSizeGroup, languageSchema, language } = props;
	const boxBookingContext = React.useContext(BoxBookingContext);
	const [viewers, setViewers] = useState({});
	let isMobile = window.innerWidth < 900;

	let [viewMode, setViewMode] = useState(isMobile ? 'list' : 'map_and_list');

	let handleChangeViewMode = (viewMode) => {
		setViewMode(viewMode);

		Object.keys(viewers).map(viewerId => {
			viewers[viewerId].zoom(1, 200, 1.2);
		});
	};

	// let [filteredBoxes] = _.values(_.pick(_.groupBy(fetchedBoxes, box => box.PhysicalSize), selectedSizeCode));
	// filteredBoxes = filteredBoxes.filter((box) => box.IsOccupied === 0);
	// console.log('FILTERED BOXES', filteredBoxes.filter((box) => box.IsOccupied == 0));

	let groupedBoxes = _.groupBy(fetchedBoxes, box => box.PhysicalSize);
	let filteredBoxes = _.pick(groupedBoxes, selectedSizeGroup.sizeCodes);

	const handleBoxSelection = (box) => () => {
		boxBookingContext.setSelectedBox(box);

		if (box) {
			boxBookingContext.setPureRate(box.MonthRate);
			boxBookingContext.setPureWeekRate(box.Weekrate);
		}
	};


	const selectModeOptions = [
		{
			label: (
				<div className="inline-flex vertical-centered">
					<div>
						<BarsOutlined /> { ' | '}
						<AppstoreOutlined />
					</div>
					<div>{languageSchema.BoxBooking.mapAndList}</div>
				</div>
			),
			value: 'map_and_list',
			isMobileHidden: true,
		},
		{
			label: (
				<div className="inline-flex vertical-centered">
					<AppstoreOutlined /> {' '}
					<div>{languageSchema.BoxBooking.map}</div>
				</div>
			),
			value: 'map',
		},
		{
			label: (
				<div className="inline-flex vertical-centered">
					<BarsOutlined /> {' '}
					<div>{languageSchema.BoxBooking.list}</div>
				</div>
			),
			value: 'list',
		},
	].filter(option => {
		if (isMobile && option.isMobileHidden) {
			return false;
		}

		return true;
	});

	let mapItems = Object.values(boxBookingContext.bookingMeta.selectedStock.maps).flat();

	return (
		<div className='container pt-4 card'>
			<div className="mb-2 mt-2" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>
					{languageSchema.BoxBooking.step3SelectABox}
				</div>
				<div className="flex-center flex w-100">
					<Segmented
						value={viewMode}
						onChange={handleChangeViewMode}
						options={selectModeOptions}
					/>
				</div>
			</div>

			<div id="boxes_map" style={{ marginLeft: '-25px', marginRight: '-25px' }} className="row">
				{
					(viewMode === 'map_and_list' || viewMode === 'map') &&
					<div style={{ overflow: 'hidden', minHeight: '550px' }} className={`col-md-${viewMode === 'map_and_list' ? 9 : 12}`}>
						<BoxesMap
							stockId={boxBookingContext.bookingMeta.selectedStockId}
							selectedSizeCodes={selectedSizeGroup.sizeCodes}
							selectBox={handleBoxSelection}
							setSideBarOpened={boxBookingContext.setSideBarOpened}
							selectedBoxUnitNumber={boxBookingContext.bookingMeta.selectedBox && boxBookingContext.bookingMeta.selectedBox.UnitNumber}
							yourChoice={languageSchema.BoxBooking.yourChoice}
							freeBoxesOfTheRightSize={languageSchema.BoxBooking.freeBoxesOfTheRightSize}
							occupiedBoxes={languageSchema.BoxBooking.occupiedBoxes}
							step3SelectABoxOnTheMap={languageSchema.BoxBooking.step3SelectABoxOnTheMap}
							objects={boxBookingContext.bookingMeta.selectedStock.maps}
							boxes={fetchedBoxes}
							languageSchema={languageSchema}
							language={language}
							setViewers={setViewers}
							viewers={viewers}
							viewMode={viewMode}
							onTouchStart={() => props.mapIsDragging(true)}
							onTouchEnd={() => props.mapIsDragging(false)}
						/>
					</div>
				}
				{
					(viewMode === 'map_and_list' || viewMode === 'list') &&
					<div style={{ zIndex: 30 }} className={`col-md-${viewMode === 'map_and_list' ? 3 : 12} mt-2 table-scroll`}>
						<div className="mb-5" id="boxesListWrapper">
							{
								selectedSizeGroup.sizeCodes.sort((a, b) => {
									if (a < b) {
										return -1;
									}

									return 1;
								}).map(key => (filteredBoxes[key]
									.filter(box => {
										let isBoxShouldBeHidden = box.statusCode !== '1' || Number(box.MonthRate) === 0;

										// TODO: deal with this.
										let isBoxFrom2ndFloor = box.UnitNumber.startsWith('2');
										let boxOnMap = mapItems.find(item => item.text === box.UnitNumber);

										return !isBoxShouldBeHidden && !box.IsOccupied && !box.IsReserved && !isBoxFrom2ndFloor && boxOnMap;
									}))
									.map(box => {
										let isActive = boxBookingContext.bookingMeta.selectedBox &&
											boxBookingContext.bookingMeta.selectedBox.UnitNumber === box.UnitNumber;

										let isCubicBox = box.UnitNumber.includes('KB');

										let [boxFloor] = box.UnitNumber;

										// boxes DA are on 0 floor
										let boxFloorString = ['0', 'D'].includes(boxFloor) ?
											'Parter' :
											`${boxFloor} ${languageSchema.Stock.floor}`;

										return (
											<div
												key={box.UnitNumber}
												id={`${box.UnitNumber}_list`}
												className={`border-card ${isActive && 'active_item'}`}
											>
												<div
													className="border-card_box_info"
													disabled={box.IsOccupied === 1}
													onClick={handleBoxSelection(box)}
												>
													<div className={`content-wrapper flex-wrap vertical-centered ${viewMode === 'list' ? 'flex-space-between' : 'flex-center'}`}>
														<div className="label-group fixed mx-2 my-2">
															<p className="title">{box.UnitNumber}</p>
															<p className="caption">{languageSchema.BoxBooking.boxNumber}</p>
														</div>

														<div className="label-group mx-2 my-2">
															<p className="title">
																{box.PhysicalSize} m<sup>{isCubicBox ? 3 : 2}</sup>
															</p>
															<p className="caption">{languageSchema.Stock.boxSpace}</p>
														</div>

														{
															viewMode === 'list' &&
															<>
																<div className="mx-2 my-2">
																	{
																		(Boolean(box.Height) ||
																		Boolean(box.Width) ||
																		Boolean(box.Depth)) &&
																		<div>
																			{
																				Boolean(box.Height) &&
																				<><b>{languageSchema.Stock.height}:</b> {box.Height}{languageSchema.Etc.metersShortLetter}{'  '} <br /></>
																			}
																			{
																				Boolean(box.Width) &&
																				<><b>{languageSchema.Stock.width}:</b> {box.Width}{languageSchema.Etc.metersShortLetter}{'  '} <br /></>
																			}
																			{
																				Boolean(box.Depth) &&
																				<><b>{languageSchema.Stock.depth}:</b> {box.Depth}{languageSchema.Etc.metersShortLetter} <br /></>
																			}
																		</div>
																	}
																</div>

																<div className="label-group mx-2 my-2">
																	<p className="title">{boxFloorString}</p>
																	<p className="caption">{languageSchema.Stock.floor}</p>
																</div>
															</>
														}

														<div className="label-group mx-2 text-center">
															<span style={{ fontSize: '18px' }}><b>{displayAmount(box.MonthRate + round(box.MonthRate * boxBookingContext.bookingMeta.vatRate / 100))}</b></span>
															{getCurrencySign(boxBookingContext.bookingMeta.selectedStock.currency, language)}/{languageSchema.BoxBooking.month}
														</div>
													</div>
												</div>

												{
													isActive &&
												<div className="btn btn-primary" onClick={boxBookingContext.setSideBarOpened}>
													{languageSchema.BoxBooking.continue} &gt;&gt;
												</div>
												}
											</div>
										);
									}))
							}
						</div>
					</div>
				}
			</div>
		</div>
	);
};

let mapStateToProps = state => ({
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language),
});

let mapDispatchToProps = {
	mapIsDragging
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxesList);
