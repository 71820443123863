import React from 'react';

const GTMTestPage = () => {
	return (
		<div>
			<div><a className="mrsCallTracking test_a_link" href="https://my.topbox.com.pl">my.topbox.com.pl</a></div>
			<button className="button_test_class" onClick={() => alert('Hello')}>my.topbox.com.pl</button>
		</div>
	);
};

export default GTMTestPage;
