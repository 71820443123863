import React from 'react';
import { Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faTimes,
	faSignOutAlt,
	faBox,
	faHistory,
	faEllipsisV,
	faCog,
	faPlus,
	faSpinner,
	faBoxOpen,
	faBoxes
} from '@fortawesome/free-solid-svg-icons';
import ReduxToastr from 'react-redux-toastr';
import { connect } from 'react-redux';

import { getLanguageSchema } from 'dashboard-commons/languages';
import history from 'utils/history';
import { DEFAULT_APP_LANGUGAE, languages } from 'utils/constants';
import Routes from 'routes';
import { setBackofficeLanguage } from 'redux/actions';
import Notifications from 'components/System/Notifications';
import qs from 'query-string';
import { setToken } from 'helpers/request';


import '../../assets/styles/App.scss';

library.add(
	faTimes, faSignOutAlt,
	faBox, faHistory,
	faEllipsisV, faCog,
	faPlus, faSpinner, faBoxOpen, faBoxes);

class App extends React.Component {
	async UNSAFE_componentWillMount() {
		let {
			authToken: authTokenFromQuery,
			lang: languageFromQuery,
		} = qs.parse(window.location.search);

		if (languageFromQuery) {
			this.setLanguage(languageFromQuery);
		}

		if (authTokenFromQuery) {
			setToken(authTokenFromQuery);
		}
	}

	setLanguage = (language) => {
		let languageToSet = language || localStorage.getItem('language');

		if (!Object.keys(languages).includes(languageToSet.toUpperCase())) {
			languageToSet = DEFAULT_APP_LANGUGAE;
		}

		this.props.setBackofficeLanguage(languageToSet.toUpperCase());
	}

	render() {
		const { languageSchema } = this.props;

		return (
			<div>
				<Notifications />
				<Router history={history}>
					<Routes />
				</Router>
				<ReduxToastr
					timeOut={4000}
					autoDismiss={true}
					newestOnTop={false}
					preventDuplicates
					position="top-right"
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					confirmOptions={{
						okText: languageSchema.Etc.yes,
						cancelText: languageSchema.Etc.no
					}}
					closeOnToastrClick={false}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, common }) => ({
	auth,
	languageSchema: getLanguageSchema(common.language),
	error: common.error,
});

const mapDispatchToProps = {
	setBackofficeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
