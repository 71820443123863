import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import LoginForm from 'components/Auth/LoginForm';
import validationSchema from 'components/Auth/validation';
import { logIn, setCurrentUserDetails, setError } from 'redux/actions';
import {  ROLES_MAP } from 'utils/constants';
import { getRoleNameFromToken, createUserInvoiceUrl } from 'utils/functions';

import { getLanguageSchema } from 'dashboard-commons/languages';

class LoginPage extends React.Component {

	handleSubmit = async (values, { setSubmitting }) => {
		try {
			let role = getRoleNameFromToken();
			let returnUrl = localStorage.getItem('returnUrl');
			let redirectUrl = '/';

			await this.props.logIn(values);

			await this.props.setCurrentUserDetails();

			if (this.props.bookingCache) {
				redirectUrl = '/book';
			} else if (this.props.invoiceCache) {
				const { user, contract, invoice } = this.props.invoiceCache;
				redirectUrl = createUserInvoiceUrl(user, contract, invoice);
			} else if (returnUrl) {
				window.location.replace(returnUrl);

				localStorage.removeItem('returnUrl');
			} else if (role === ROLES_MAP.client) {
				redirectUrl = '/my-boxes';
			}

			return this.props.history.push(redirectUrl);
		} finally {
			setSubmitting(false);
		}
	}

	render() {
		const { languageSchema } = this.props;

		return (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<div className="container">
					<div className="row">
						<div className="col-md-5 offset-md-3">
							<div className="card" style={{ paddingTop: '50px' }}>
								<Formik
									initialValues={{ email: '', password: '' }}
									onSubmit={this.handleSubmit}
									validationSchema={validationSchema(languageSchema)}
									render={
										(props) =>
											<LoginForm
												{...props}
												languageSchema={languageSchema}
												location={this.props.location}
											/>
									}
								/>
								{
									<div>
										<div style={{
											textTransform: 'uppercase',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											padding: '30px 0',
											color: '#283049',
											fontSize: '28px',
											fontWeight: 600
										}}>
											{languageSchema.Etc.or}
										</div>

										<Link
											to='/book'
											style={{
												backgroundColor: '#283049',
												fontSize: '20px'
											}}
											className="btn btn-primary d-block horizontal-centered"
										>
											{languageSchema.LogIn.goToBooking}
											<i className='ml-2 fas fa-box-open'></i>
										</Link>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	bookingCache: state.cache.booking,
	invoiceCache: state.cache.invoice,
});

export default connect(mapStateToProps, { logIn, setCurrentUserDetails, setError })(LoginPage);
