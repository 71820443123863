import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';

import OverlayLoader from 'components/common/OverlayLoader';

import Map from './Map';

const render = (status) => {
	switch (status) {
	case Status.LOADING:
		return (<OverlayLoader />);

	case Status.FAILURE:
		return (<div> Oups </div>);

	case Status.SUCCESS:
		return <></>;
	}
};

export default (props) => (
	<Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={render}>
		<Map {...props} />
	</Wrapper>
);
