import React from 'react';

import { getRoleNameFromToken } from 'utils/functions';

const Authorization = allowedRoles => WrappedComponent => {
	return (props) => {
		const role = getRoleNameFromToken();
				
		if (allowedRoles.includes(role)) {
			return <WrappedComponent {...props} />;
		} else {
			return <h1>Access denied!</h1>;
		}
	};
};

export default Authorization;