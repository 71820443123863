import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import DatePicker from 'components/common/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import BoxBookingContext from 'screens/BoxBookingNew/Context/';

import { handleKeyboardDisabled } from 'helpers/common';

import './styles.css';

function ChoiceOfDepartureDate(props) {
	const boxBookingContext = React.useContext(BoxBookingContext);
	const { bookingMeta: { departureDate, isDepartureDateNotChosenError, arrivalDate }, setInvoicingPeriodType } = boxBookingContext;
	const [weekDays, setWeekDays] = useState([]);

	useEffect(() => {
		if (arrivalDate) {
			handleDepartureDateSelection(null);
			getFirstMonthWeekDays();
		}
	}, [arrivalDate]);

	let getFirstMonthWeekDays = () => {
		const avaliableDaysInFirstMonth = [
			moment(arrivalDate).add(1, 'weeks').subtract(1, 'days').toDate(),
			moment(arrivalDate).add(2, 'weeks').subtract(1, 'days').toDate(),
			moment(arrivalDate).add(3, 'weeks').subtract(1, 'days').toDate(),
		];
		if (moment(arrivalDate).add(4, 'weeks').subtract(1, 'days').isBefore(moment(arrivalDate).add(1, 'month'))) {
			avaliableDaysInFirstMonth.push(moment(arrivalDate).add(4, 'weeks').subtract(1, 'days').toDate());
		}

		setWeekDays(avaliableDaysInFirstMonth);
	};

	let getExcludeDateIntervals = () => {
		if (weekDays?.length === 3) {
			return [
				{start: moment(arrivalDate).toDate(), end: moment(weekDays[0]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[0]).add(1, 'days').toDate(), end: moment(weekDays[1]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[1]).add(1, 'days').toDate(), end: moment(weekDays[2]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[2]).add(1, 'days').toDate(), end: moment(arrivalDate).add(1, 'months').subtract(1, 'days').toDate()},
			];
		} else if (weekDays?.length === 4) {
			return [
				{start: moment(arrivalDate).toDate(), end: moment(weekDays[0]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[0]).add(1, 'days').toDate(), end: moment(weekDays[1]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[1]).add(1, 'days').toDate(), end: moment(weekDays[2]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[2]).add(1, 'days').toDate(), end: moment(weekDays[3]).subtract(1, 'days').toDate()},
				{start: moment(weekDays[3]).add(1, 'days').toDate(), end: moment(arrivalDate).add(30, 'days').subtract(2, 'days').toDate()}
			];
		}
		return [];
	};

	let handleDepartureDateSelection = (departureDate) => {
		boxBookingContext.setDepartureDate(departureDate);

		// ADD CHECK TO WEEK RATE (IF DATE IN WEEK DAYS ARRAY)
		if (weekDays?.length && weekDays.find((date) => moment(date).isSame(moment(departureDate).toDate()))) {
			setInvoicingPeriodType('W');
		} else {
			setInvoicingPeriodType('M');
		}

		// TODO
		let contractDurationInMonths = departureDate ?
			moment(departureDate).add(1, 'days').diff(moment(arrivalDate), 'months') :
			0;

		if (contractDurationInMonths === 0) {
			contractDurationInMonths = 1;
		}

		boxBookingContext.setContractDuration(contractDurationInMonths);
	};

	const intervals = useMemo(() => getExcludeDateIntervals(), [weekDays]);

	return (
		<div id='departure_date' className="w-100 mt-3">
			<div style={{ fontSize: '20px', marginBottom: '1px' }}>
				{props.title}
			</div>
			<div className={`input-container ${isDepartureDateNotChosenError ? 'error' : ''}`}>
				<DatePicker
					wrapperClassName="choice-of-arrival-date"
					language={props.language}
					selected={departureDate}
					minDate={moment(arrivalDate).add(1, 'weeks').subtract(1, 'days').toDate()}
					maxDate={moment(arrivalDate).add(1, 'years').subtract(1, 'days').toDate()}
					onChange={handleDepartureDateSelection}
					excludeDateIntervals={intervals}
					placeholderText={props.placeholderText}
					dateFormat="dd.MM.yyyy"
					onKeyDown={handleKeyboardDisabled}
					arrivalDate={arrivalDate}
				/>
				<span
					onClick={() => handleDepartureDateSelection(null)}
					style={{ cursor: 'pointer' }}
				>
					{props.languageSchema.Etc.clear}
				</span>
			</div>
		</div>
	);
}

let mapStateToProps  = (state) => ({
	language: state.common.language,
});

export default connect(mapStateToProps)(ChoiceOfDepartureDate);
