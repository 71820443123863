import * as Yup from 'yup';

// const SUPPORTED_FORMATS = [
//		 "image/jpg",
//		 "image/jpeg",
//		 "image/gif",
//		 "image/png"
// ];

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Заполните название'),
	alias: Yup.string().required('Укажите алиас'),
	pricePerMeterQuadratic: Yup.number().required('Укажите цену за метр квадратный'),
	minSize: Yup.number().required('Укажите минимальный размер категории боксов'),
	maxSize: Yup.number().required('Укажите максимальный размер категории боксов'),
	description: Yup.string().required('Укажите описание'),
	// preview: Yup.mixed()
	//		 .test(
	//				 "fileFormat",
	//				 "Unsupported Format",
	//				 value => value && SUPPORTED_FORMATS.includes(value.type)
	//		 )
});

export default validationSchema;