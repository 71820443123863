import React, { useState, useEffect } from 'react';
import request from 'helpers/request';
import MapConstructor from 'components/Stock/Map/Constructor';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {Button,Tabs} from 'antd';

// const STOCKID = 'RI1GRWXX250320060000';
const { TabPane } = Tabs;

const BOXMAP = {
	'stroke': '#646464',
	'strokeWidth': 2,
	'radius' : 5,
	'fill' : 'lightgray',
	'blendMode' : 'normal',
	'rotate': 0,
	'fontWeight': 'normal',
	'fontStyle': 'normal',
	'textDecoration': 'none',
	'textColor': 'black',
	'fontSize': 10,
	'fontFamily': 'Open Sans',
	'type': 'rectangle-with-text'
};

function Maps(props) {
	// const [stockMapsSM, setStockMapsSM] = useState({});
	// const [stockMaps, setStockMaps] = useState({});
	const [, setStockMapsSM] = useState({});
	const [, setStockMaps] = useState({});
	let [maps, setMaps] = useState({});

	useEffect(() => {
		fetchStockMaps();
	}, []);

	const onChange = (newActiveKey) => {
		console.log(newActiveKey);
	};

	const fetchStockMapsSM = async () => {
		let stockMapsSM = await request('GET', `stocks/${props.stockId}/mapSM`);
		stockMapsSM = stockMapsSM.map(o => ({ ...o, ...BOXMAP, 'uuid': uuidv4() }));
		const grouped = await _.groupBy(stockMapsSM, map => map.MapName);

		setStockMapsSM(grouped);
		setMaps(grouped);
	};

	const fetchStockMaps = async () => {
		let stockMaps = await request('GET', `stocks/${props.stockId}/map`);

		setStockMaps(stockMaps.maps);
		setMaps(stockMaps.maps);
	};


	// const printState = () => {
	// 	console.log('StockMaps--------', stockMaps);
	// 	console.log('StockMapsSM-------', stockMapsSM);
	// 	console.log(props.stockId);
	// };

	return (
		<div>
			<Button className="btn btn-primary mb-3" onClick={fetchStockMapsSM}>Upload Maps from Space Manager</Button>
			{/* <Button className="btn btn-primary mb-3" onClick={printState}>Print Status</Button> */}
			{
				!_.isEmpty(maps) && <Tabs defaultActiveKey="1" type="card" size='small' onChange={onChange} >
					{Object.keys(maps).map((id) => {
						return (
							<TabPane tab={id} key={uuidv4()}>
								{id} MAP
								<MapConstructor
									stockId={props.stockId}
									mapId={id}
									objects={maps[id]}
								/>
							</TabPane>
						);
					})}</Tabs>
			}


		</div>
	);
}

export default Maps;
