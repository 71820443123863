import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import TagManager from 'react-gtm-module';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'antd/dist/antd.css';
import 'react-redux-toastr/src/styles/index.scss';
import './assets/styles/index.scss';

import App from './screens/App/';
import store from './redux/store';

if (process.env.REACT_APP_NODE_ENV === 'production') {
	// let tagManagerArgs = {
	// 	gtmId: 'GTM-NTH3BQD'
	// };
	//
	// TagManager.initialize(tagManagerArgs);
	// const metrics = `
	// <!-- Yandex.Metrika counter -->
	// <script type="text/javascript" >
	//     (function(m,e,t,r,i,k,a){m[i]=m[i]function(){(m[i].a=m[i].a[]).push(arguments)};
	//       m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
	//       (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
	//       ym(89808973, "init", {
	//           clickmap:true,
	//           trackLinks:true,
	//           accurateTrackBounce:true,
	//           webvisor:true
	//       });
	// </script>
	// <noscript><div><img src="https://mc.yandex.ru/watch/89808973" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
	// <!-- /Yandex.Metrika counter -->
	// <!-- Global site tag (gtag.js) - Google Analytics -->
	// <script async src="https://www.googletagmanager.com/gtag/js?id=G-GF214NFC6T"></script>
	// <script>
	//   window.dataLayer = window.dataLayer || [];
	//   function gtag(){dataLayer.push(arguments);}
	//   gtag('js', new Date());
	//   gtag('config', 'G-GF214NFC6T');
	// </script>
	// <!-- Meta Pixel Code -->
	// <script>
	//   !function(f,b,e,v,n,t,s)
	//   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
	//   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
	//   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
	//   n.queue=[];t=b.createElement(e);t.async=!0;
	//   t.src=v;s=b.getElementsByTagName(e)[0];
	//   s.parentNode.insertBefore(t,s)}(window, document,'script',
	//   'https://connect.facebook.net/en_US/fbevents.js');
	//   fbq('init', '621193199253829');
	//   fbq('track', 'PageView');
	// </script>
	// <noscript><img height="1" width="1" style="display:none"
	//   src="https://www.facebook.com/tr?id=621193199253829&ev=PageView&noscript=1"/></noscript>
	// <!-- End Meta Pixel Code -->
	// `;
	//
	// const [HEADER] = document.getElementsByTagName('head');
	// if (HEADER) {
	// 	HEADER.insertAdjacentHTML('beforeend', metrics);
	// }
}

// FIXME: debug mode - export store to window props
// if (process.env.NODE_ENV === 'development') {
// 	window._store = store;
// }

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>, MOUNT_NODE);

if (module.hot) {
	module.hot.accept();
}
