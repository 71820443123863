import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getLanguageSchema } from 'dashboard-commons/languages';
import request from 'helpers/request';

import OverlayLoader from 'components/common/OverlayLoader';
import EndingContractsList from 'components/HomePage/EndingContracts/List';
import TopDebtors from 'components/HomePage/TopDebtors/List';
import NotSentInvoiceEmails from 'components/HomePage/NotSentInvoiceEmails/List';
import PenaltyEmails from 'components/HomePage/PenaltyEmails/List';

import './HomePage.scss';

class HomePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			endingContracts: [],
			topDebtors: [],
			notSentInvoicesEmails: [],
			penaltyEmails: [],
		};
	}
	//
	UNSAFE_componentWillMount() {
		if (!this.props.user._id) {
			return <Redirect to={{ pathname: '/login' }} />;
		}
	}

	async componentDidMount() {
		this.setState({ isLoading: true });

		let [endingContracts, topDebtors, notSentInvoicesEmails, penaltyEmails] =
			await Promise.all([
				this.fetchEndingContracts(),
				this.fetchTopDebtors(),
				this.fetchNotSendedInvoicesEmails(),
				this.fetchPenaltyEmails(),
			]);

		this.setState({
			endingContracts,
			topDebtors,
			notSentInvoicesEmails,
			penaltyEmails,
			isLoading: false
		});
	}

	fetchEndingContracts = () => [] || request('GET', 'statistics/contracts', { status: this.state.status });
	fetchTopDebtors = () => [] || request('GET', 'statistics/debtors');
	fetchNotSendedInvoicesEmails = () => [] || request('GET', 'statistics/invoice-emailing');
	fetchPenaltyEmails = () => [] || request('GET', 'statistics/penalty-emailing');

	render() {
		let { endingContracts, topDebtors, notSentInvoicesEmails = [], penaltyEmails } = this.state;
		let { languageSchema } = this.props;

		return (
			<div className="homepage long-container">
				<OverlayLoader />

				<div className="row">
					<div className="col-md-6">
						<EndingContractsList endingContracts={endingContracts} languageSchema={languageSchema} />

						<NotSentInvoiceEmails
							notSentInvoicesEmails={notSentInvoicesEmails}
							languageSchema={languageSchema}
						/>
					</div>

					<div className="col-md-6">
						<TopDebtors topDebtors={topDebtors} languageSchema={languageSchema} />
						<PenaltyEmails penaltyEmails={penaltyEmails} languageSchema={languageSchema} />
					</div>

				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, common }) => ({
	user: auth.me,
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(HomePage);
