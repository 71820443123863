import React from 'react';
import { Link } from 'react-router-dom';

import { getRoleNameFromToken } from 'utils/functions';
import { ROLES_MAP } from 'utils/constants';
import TopboxLogo from '../../assets/images/topbox_large_logo.png';

export default (props) => {
	const { title, text, link } = props;
	const currentUserRole = getRoleNameFromToken();
	const isSuperUser = currentUserRole === ROLES_MAP.superUser;

	return (
		<div className="row mt-5">
			<div className="card col-md-6 offset-md-3">
				<div className="text-center">
					<img
						src={TopboxLogo}
						alt="Rentabox"
						title="Rentabox"
						width="250" 
					/>
				</div>
				<hr />
				<div className="text-center">
					<i className="far fa-check-circle text-success fs-9"></i>
					{Array.isArray(title) ?
						title.map((t, i) => <h2 key={i} className="mt-4 mb-2 text-success">{t}</h2>) :
						<h2 className="mt-4 mb-2 text-success">{title}</h2>
					}
				</div>
				<div className="alert alert-info mt-3" role="alert">
					<i className="fas fa-info-circle mr-2"></i>
					{text}
				</div>

				<div className="text-center">
					<Link to={isSuperUser ? '/' : '/my-boxes'} className="btn btn-lg btn-outline-success">
						{link}
					</Link>
				</div>
			</div>
		</div>
	);
};
