export function pow(number, power) {
	return Number(number) ** Number(power);
}

export const handleKeyboardDisabled = (e) => e.preventDefault();

export const scrollToDiv = (div) => {
	const section = document.querySelector(div);

	section.scrollIntoView({
		behavior: 'smooth',
		block: 'start'
	});
};

export const navigateToExternalUrl = (event, url, shouldOpenNewTab = true) => {
	event.stopPropagation();

	return shouldOpenNewTab ? window.open(url, '_blank') : (window.location.href = url);
};