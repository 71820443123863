import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';

import LanguagePicker from 'components/common/LanguagePicker';

import list from './headerList';
import { getRoleNameFromToken } from 'utils/functions';
import history from 'utils/history';
import { WEBSITE_URL } from 'utils/constants';
import logo from 'assets/images/topbox_large_logo.png';
import './Header.scss';

const Header = ({ user, languageSchema, logOut }) => {
	const role = getRoleNameFromToken();
	const currentList = list.filter(point => point.permissions.includes(role));

	const handleLogOut = () => {
		logOut();
		history.push('/login');
	};

	return (
		<header className="header mb-3">
			<nav className="navbar navbar-expand-lg">
				<div className="container-fluid">
					<a href={WEBSITE_URL} className="navbar-brand mx-2">
						<img className="logo" src={logo} alt=""/>
					</a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="fas fa-bars white"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav mr-auto">
							{currentList.map((point, index) => (
								<li className="nav-item" key={index}>
									{point.isDisabled ?
										<Link to={point.href} className="nav-link disabled">
											{languageSchema.HomePage[point.alias]}
										</Link> :
										<NavLink to={point.href} className="nav-link" activeClassName="active_header_item">
											{point.icon && <i className={`fas fa-${point.icon}`}/> } {languageSchema.HomePage[point.alias]}
										</NavLink>
									}
								</li>
							))}
						</ul>

						<LanguagePicker dark/>

						<ul className="navbar-nav align-items-center">
							{user &&
								<li className="nav-item dropdown">
									<span
										className="nav-link dropdown-toggle"
										id="navbarDropdown" data-toggle="dropdown"
										aria-haspopup="true" aria-expanded="false"
									>
										{user.email}
									</span>
									<div className="dropdown-menu" aria-labelledby="navbarDropdown">
										<Link to={`/users/${user._id}`} className="dropdown-item">
											{languageSchema.HomePage.profile}
										</Link>
									</div>
								</li>
							}
							<li className="nav-item">
								<div onClick={handleLogOut} className="btn btn-sm btn-outline-secondary">
									<span>{languageSchema.HomePage.exit}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};

const mapStateToProps = ({ auth, common }) => ({
	user: auth.me,
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps, { logOut })(Header);

