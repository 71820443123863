import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toastr } from 'react-redux-toastr';

import StockForm from 'components/Stock/Form';
import SmtpSettings from 'components/Stock/SmtpSetting';
import StripeKeys from 'components/Stock/StripeKeys';
import Heading from 'components/UI/Heading';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { timeout } from 'utils/functions';
import { ROLES_MAP } from 'utils/constants';
import { fetchStocks, updateStock, deleteStock, fetchCities } from 'redux/actions';
import InternalEquipments from 'components/Stock/InternalEquipments';
import Maps from 'components/Stock/Map/Maps';

import validationSchema from './validation';
import fields from './fields';

class StockPage extends Component {
		state = {
			isSuccess: false,
		}

		componentDidMount() {
			const {
				isFetchedStocks,
				isFetchedCities,
				fetchStocks,
				fetchCities
			} = this.props;

			if (!isFetchedStocks) {
				fetchStocks();
			}

			if (!isFetchedCities) {
				fetchCities();
			}
		}

		handleClickDelete = () => {
			const {
				history: { push },
				stock,
				deleteStock,
				languageSchema
			} = this.props;

			toastr.confirm(languageSchema.Stock.areYouSureYouWantToDelete, {
				onOk: () => {
					deleteStock(stock._id);
					push('/stocks');
				}
			});
		}

		handleSubmit = async (values, { setSubmitting }) => {
			toastr.confirm(this.props.languageSchema.Etc.areYouSureYouWantToSaveChanges, {
				onOk: async () => {
					try {
						let formData = { ... values };

						_.unset(formData, 'maps');

						await this.props.updateStock(formData);

						this.setState({ isSuccess: true }, async () => {
							await timeout(2000);
							this.setState({ isSuccess: false });
						});
					} finally {
						setSubmitting(false);
					}
				},
				onCancel: () => setSubmitting(false)
			});
		}

		render() {
			const { stock, cities, languageSchema, language, role } = this.props;
			const { isSuccess } = this.state;

			let isSuperUser = role === ROLES_MAP.superUser;

			if (!stock) {
				return <div>{languageSchema.Etc.loading}</div>;
			}
			return (
				<div className="row py-3">
					<div className="col-md-6">
						<div className="card">
							<Heading>{languageSchema.Stock.stockEditing}</Heading>
							<Formik
								initialValues={{ ...fields.props, ...stock }}
								onSubmit={this.handleSubmit}
								validationSchema={validationSchema(languageSchema)}
								render={props =>
									<StockForm
										type="edit"
										cities={cities}
										isSuccess={isSuccess}
										{...props}
										languageSchema={languageSchema}
										language={language}
										handleClickDelete={this.handleClickDelete}
										isSuperUser={isSuperUser}
									/>}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<div className="card">
									{
										stock.internalEquipments &&
										<InternalEquipments stock={stock} />
									}
								</div>
							</div>
						</div>


						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<SmtpSettings
										stock={stock}
										languageSchema={languageSchema}
										isSuperUser={isSuperUser}
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<StripeKeys
										stock={stock}
										languageSchema={languageSchema}
										isSuperUser={isSuperUser}
									/>
								</div>
							</div>
						</div>

					</div>

					<div className="row">
						<div className="col-md-12">
							{console.log('STOCK----->', stock)}
							<div className="mb-5 card">
								<Heading>
									{languageSchema.Stock.boxesMapConstructor}
								</Heading>

								<Maps
									stockId={stock.stockId_sm}
								/>
							</div>

						</div>
					</div>
				</div>
			);
		}
}

const mapStateToProps = ({ stocks, cities, common, auth }, props) => {
	const currentId = props.match.params.id;
	const currentStock = stocks.data.find(stock => stock._id === currentId);

	if (currentStock && currentStock.cityId && currentStock.cityId._id) {
		currentStock.cityId = currentStock.cityId._id;
	}

	return {
		role: auth.me.role,
		stock: currentStock,
		cities: cities.data,
		isFetchedStocks: stocks.isFetched,
		isFetchedCities: cities.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};
const mapDispatchToProps = {
	fetchStocks,
	updateStock,
	deleteStock,
	fetchCities,
};
export default connect(mapStateToProps, mapDispatchToProps)(StockPage);
