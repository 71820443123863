import React, { useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { Modal } from 'antd';

import { setCurrentUserDetails } from 'redux/actions';
import request from 'helpers/request';
import SignUpForm from 'components/SignUp/Form';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import validationSchema from './validation';

function SignUpPage(props) {
	let { languageSchema, isVisible, onCancel, onLogInAction } = props;

	let individualFormFields = {
		password: '',
		email: '',
		firstName: '',
		lastName: '',
		country: 'PL',
		city: '',
		phone: '',
		pesel: '',
		address: '',
		house: '',
		entrance: '',
		apartment: '',
		zip: '',
		company: '',
		isAdvertisingAgree: true,
		language: props.language,
	};

	let legalFormFields = {
		password: '',
		email: '',
		country: 'PL',
		city: '',
		phone: '',
		address: '',
		house: '',
		entrance: '',
		apartment: '',
		zip: '',
		company: '',
		registrationNumber: '',
		isAdvertisingAgree: true,
		language: props.language,
	};

	let initialValues = {
		accountType: '',
		...individualFormFields,
		...legalFormFields,
	};

	let [accountType, setAccountType] = useState(ACCOUNT_TYPES_MAP.individual);

	let getDataFields = (accType) => {
		let fields = {
			individual: [...Object.keys(individualFormFields), 'accountType'],
			legal: [...Object.keys(legalFormFields), 'accountType'],
		};

		return fields[accType];
	};

	let handleSubmitUserForm = async (values) => {
		let accountData = _.pick(values, getDataFields(accountType));

		// set all phone numbers to one format with +
		accountData = {
			...accountData,
			phone: `+${accountData.phone}`,
		};

		let signUpResult = await request('POST', 'auth/sign-up', accountData);

		props.setCurrentUserDetails(signUpResult.token);

		toastr.success(
			languageSchema.SignUp.youHaveRegistered,
			languageSchema.SignUp.checkEmail(values.email)
		);

		// props.history.push(`/login?email=${values.email}`);
		props.onSignUpCompleted(signUpResult);
	};

	return (
		<Modal visible={isVisible} footer={null} onCancel={onCancel}>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmitUserForm}
				validationSchema={validationSchema(languageSchema, accountType)}
				render={props => (
					<SignUpForm
						languageSchema={languageSchema}
						{...props}
						setAccountType={setAccountType}
						onLogInAction={onLogInAction}
					/>
				)}
			/>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	language: state.common.language,
});

const mapDispatchToProps = {
	setCurrentUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpPage));
