export const apiHost =
	process.env.NODE_ENV === 'development' ?
		'localhost' :
		(process.env.REACT_APP_API_HOST || 'api.topbox.com.pl');

export const env = process.env.NODE_ENV;

export const serverUrl =
	process.env.NODE_ENV === 'development' ?
		'http://localhost:5656' :
		`https://${apiHost}`;

export const apiUrl = `${serverUrl}/api`;

export const webSocketServerAddress =
	process.env.NODE_ENV === 'development' ?
		`ws://${apiHost}:8000/socket` :
		`wss://${apiHost}/socket`;

export const IS_KYC_ENABLED = process.env.REACT_APP_IS_KYC_ENABLED === 'true';

export const httpMethodsMap = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

export const DEFAULT_APP_LANGUGAE = process.env.REACT_APP_START_LANGUAGE || 'PL';

const langs = {
	EN: 'English',
	PL: 'Polski'
};

if (DEFAULT_APP_LANGUGAE === 'RU') {
	langs.RU = 'Russian';
}

export const languages = langs;

export const LANGUAGE_TO_COUNTRY_CODE_MAP = {
	EN: 'GB',
	PL: 'PL'
};

export const WEBSITE_URL = 'https://topbox.com.pl';
export const LOCALIZATION_WARSAW_WEBSITE_URL = 'https://topbox.com.pl/lokalizacja/warsaw';
export const REGULAMIN_URL = 'https://topbox.com.pl/regulamin-do-umow-online';
export const INSURANCE_CONDITIONS_URL = 'https://topbox.com.pl/warunki-ubezpieczenie/';
export const PRIVATE_CUSOMTER_AGREEMENT = 'https://topbox.com.pl/oswiadczenia-najemcy-osoba-prywatna/';
export const FIRMA_AGREEMENT = 'https://topbox.com.pl/oswiadczenia-najemcy-firmy/';

export const errorCodesMap = {
	somethingWentWrong: 1,
	emailIsRequired: 1000,
	enterValidEmail: 1001,
	passwordIsRequired: 1002,
	fieldIsRequired: 1003,
	invalidSymbolsAmount: 1004,
	noInvoicesFound: 1077,

	userHasActiveContracts: 5000,

	invalidDataProvided: 9001,
	sessionIsFinished: 9003,
	enterValue: 'enterValue',
	outOfBounds: 'outOfBounds',
	invalidField: 'invalidField',
	invalidCharacters: 'invalidCharacters',
	chooseField: 'chooseField',

	enterYourMessage: 'enterYourMessage',
	invalidPhoneBounds: 'invalidPhoneBounds',
	messageInvalidBounds: 'messageInvalidBounds',
};

export const PHONE_REGEXP = /^[+]?[-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
export const PHONE_INPUT_REGEXP = /^[0-9]{1,14}$/;

export const ACCOUNT_TYPES_MAP = {
	legal: 'legal',
	individual: 'individual',
};

export const CURRENCIES_MAP = {
	EUR: 'EUR',
};

export const DATE_FORMAT = 'DD.MM.YYYY';

export const MIN_RENT_MONTHS_FOR_DISCOUNT = 2;

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const DEF_STRIPE_PROCESSOR = 'stripe';
export const DEF_PAYPAL_PROCESSOR = 'payPal';
export const DEF_BLUE_MEDIA_PROCESSOR = 'bluemedia';
export const DEF_SOFORT_PROCESSOR = 'sofort';

export const DEF_ARRIVAL_DATE_ERROR = 'DEF_ARRIVAL_DATE_ERROR';
export const DEF_DOCS_CONFIRM_ERROR = 'DEF_DOCS_CONFIRM_ERROR';
export const DEF_PAYMENT_PROC_ERROR = 'DEF_PAYMENT_PROC_ERROR';

export const DEF_CONTRACT_DURATIONS = [
	{
		value: 12,
		discount: 10
	},
	{
		value: 6,
		discount: 5
	},
	{
		value: 2,
		discount: 0
	},
	{
		value: 1,
		discount: 0
	}
];

export const getAddressFields = (languageSchema) => ([
	{
		name: 'house',
		title: languageSchema.User.houseNumber,
		isRequired: true,
	},
	{
		name: 'entrance',
		title: languageSchema.User.entranceNumber,
		isRequired: false,
	},
	{
		name: 'apartment',
		title: languageSchema.User.apartmentNumber,
		isRequired: false,
	},
]);

export const ROLES_MAP = {
	superUser: 'superUser',
	client: 'client'
};

export const DEBT_PENALTY_2_ADD_FIRST_INVOICE = 'DEBT_PENALTY_2_ADD_FIRST_INVOICE';

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const TOURS_URL = {
	s: 'https://topbox.com.pl/tour/tour-small/',
	m: 'https://topbox.com.pl/tour/tour-medium/',
	l: 'https://topbox.com.pl/tour/tour-large/'
};
