import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import qs from 'query-string';
import MaskedInput from 'react-text-mask';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';

import { getLanguageSchema } from 'dashboard-commons/languages';
import Table from 'components/UI/Table';
import { displayAmount, round } from 'helpers/amount';


import { getPrettyLockStatus } from 'utils/functions';
import request from 'helpers/request';
import { fetchBoxes, clearCurrentBoxes, saveLockId, fetchStocks, openBox, } from 'redux/actions';

class BoxListScreen extends Component {
		state = {
			editable: false,
			boxExternalId: null,
			lockId: '',
			searchByBoxNumber: '',
			searchByLockId: '',
			searchByBoxSize: '',
			filteredBoxes: null,
			sheetsToDownload: null,

		}

		MaskedInputRef = React.createRef();

		componentDidMount() {
			this.props.fetchBoxes(this.props.stockId);
			this.props.fetchStocks();
		}

		componentWillUnmount() {
			this.props.clearCurrentBoxes();
		}

		componentDidUpdate(prevProps) {
			let { boxes } = this.props;
			let isBoxesChanged = _.differenceWith(prevProps.boxes, boxes, _.isEqual).length;
			if (isBoxesChanged) {
				this.transformResultData();
			}
		}

		handleUploadSheet = async (options) => {
			const { file } = options;

			const formData = new FormData();
			formData.append('file', file);
			formData.append('stockId', this.props.stock.stockId_sm);

			try {
				await request('POST', 'boxes/import/sheets', formData);
				await this.props.fetchBoxesOld(this.props.stock.stockId_sm);

				toastr.success(this.props.languageSchema.Etc.success);
			}
			catch(err) {
				console.log(err);
			}
		}

		handleExportSheets = async () => {
			const data = await request('GET', 'boxes/export/sheets', {
				stockId: this.props.stock.stockId_sm,
			});
			this.setState({ sheetsToDownload: data });
		};

		setEditMode = (lockId, boxExternalId) => {
			this.setState({
				editable: true,
				boxExternalId,
				lockId: lockId || '',
			}, () => {
				this.MaskedInputRef.current.inputElement.focus();
			});
		}

		saveChanges = async (box) => {
			const { stockId } = this.props;
			const { lockId } = this.state;

			if (lockId.includes('_') || lockId.length === 0) {
				this.setState({
					editable: false,
					boxExternalId: null,
					lockId: ''
				});
				return;
			}

			try {
				await this.props.saveLockId({ ...box, lockId, stockId });
				await this.props.fetchBoxes(this.props.stockId);

				this.setState({
					editable: false,
					boxExternalId: null,
					lockId: ''
				});
			} catch (error) {
				console.log(error);
			}

		}

		handleChangeLockId = (e) => {
			let { value } = e.target;
			this.setState({ lockId: value });
		}

		handleChangeSearchField = (e) => {
			const { name, value } = e.target;

			this.setState({ [name]: value }, this.transformResultData);
		}

		handleFilterBoxesByLocksState = (e) => {
			const { value } = e.target;

			if (value === 'all') {
				this.transformResultData();
			} else {
				let itemsToFilter = this.state.filteredBoxes && (0 < this.state.filteredBoxes.length) ?
					this.state.filteredBoxes :
					this.props.boxes;

				let filteredBoxes = itemsToFilter.filter(box => box.lockStatus === value);

				this.setState({ filteredBoxes });
			}
		}

		transformResultData = () => {
			const { boxes } = this.props;
			const { searchByBoxNumber, searchByLockId, searchByBoxSize } = this.state;

			let filteredBoxes = [...boxes];

			if (searchByBoxNumber.length > 0) {
				filteredBoxes = filteredBoxes.filter(
					box => box.UnitNumber && box.UnitNumber.toLowerCase().includes(searchByBoxNumber.toLowerCase())
				);
			}

			if (searchByLockId.length > 0) {
				filteredBoxes = filteredBoxes.filter(
					box => box.lockId && box.lockId.toLowerCase().includes(searchByLockId.toLowerCase())
				);
			}

			if (searchByBoxSize.length > 0) {
				filteredBoxes = filteredBoxes.filter(
					box => box.size && box.size.toString().includes(searchByBoxSize.toLowerCase())
				);
			}

			this.setState({ filteredBoxes });
		}

		openBox = (box) => async () => {
			toastr.confirm(this.props.languageSchema.Stock.areYouSureYouWantToOpenBox, {
				onOk: () => {
					this.props.openBox(box);
				}
			});
		}

		getUserName = (user) => {
			let { firstName, lastName, company, accountType } = user;
			let userNameMapper = {
				individual: `${firstName} ${lastName}`,
				legal: company,
			};

			return userNameMapper[accountType];
		}


		syncBoxesWithSM = async () => {
			await request('POST', 'boxes/sync', { stockId: this.props.stock.stockId_sm });

			this.props.fetchBoxes(this.props.stockId);
		}

		render() {
			const { boxes, stock, languageSchema } = this.props;
			const { editable, boxExternalId, lockId, filteredBoxes } = this.state;

			const renderingBoxes = filteredBoxes ? filteredBoxes : boxes;

			const debouncedOnChange = _.debounce(this.handleChangeSearchField, 500);

			return (
				<div className="card">
					<h3 className="mt-5">
						{stock.name}. {languageSchema.Stock.totalBoxes}: <b>{boxes.length}</b>
					</h3>

					<div className="my-2 flex-wrap inline-flex justify-content-between">
						<div className="align-self-center ">
							<Button style={{ marginRight: '5px', marginTop: '5px', marginBottom: '5px' }} onClick={this.syncBoxesWithSM} >
								<i className={'fas fa-sync'}> {' '} </i>
							Fetch from SM
							</Button>
							<Upload
								showUploadList={false}
								multiple={false}
								accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv'
								name="file"
								customRequest={this.handleUploadSheet}
							>
								<Button style={{ marginRight: '5px', marginTop: '5px', marginBottom: '5px' }} icon={<UploadOutlined />}>Click to import locks numbers</Button>
							</Upload>
							{this.state.sheetsToDownload ? (
								<a
									className="ant-btn ant-btn-default my-2"
									href={window.URL.createObjectURL(
										new Blob(
											[
												new Uint8Array(
													this.state.sheetsToDownload.data
												).buffer,
											],
											{
												type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
											}
										)
									)}
									download={`boxes_with_locks_${this.props.stock.stockId_sm}.xlsx`}
								>
								Upload
								</a>
							) : (
								<Button
									onClick={this.handleExportSheets}>
								Generate Sheets
								</Button>
							)}
						</div>
					</div>

					<div className="row my-2">
						<div className="col-md-3 mb-2">
							<input
								type="search" name="searchByBoxNumber"
								placeholder={languageSchema.Stock.boxNumber} className="form-control"
								onChange={e => {
									e.persist();
									debouncedOnChange(e);
								}}
							/>
						</div>
						<div className="col-md-3 mb-2">
							<input
								type="search" name="searchByBoxSize"
								placeholder={languageSchema.Stock.size} className="form-control"
								onChange={e => {
									e.persist();
									debouncedOnChange(e);
								}}
							/>
						</div>
						<div className="col-md-3 mb-2">
							<input
								type="search" name="searchByLockId"
								placeholder={languageSchema.Stock.lockNumber} className="form-control"
								onChange={e => {
									e.persist();
									debouncedOnChange(e);
								}}
							/>
						</div>

						<div className="col-md-3 mb-2">
							<label> {languageSchema.Stock.locksState}: </label>
							<select
								type="select"
								name="status"
								placeholder={languageSchema.Stock.locksState}
								className="form-control"
								onChange={this.handleFilterBoxesByLocksState}
							>
								<option value="all"> {languageSchema.Stock.all} </option>
								<option value="opened"> {languageSchema.Stock.opened} </option>
								<option value="closed"> {languageSchema.Stock.closed} </option>
							</select>
						</div>
					</div>

					<Table
						header={[
							languageSchema.Stock.boxNumber,
							languageSchema.Stock.size,
							languageSchema.Stock.monthRate,
							languageSchema.Stock.lockNumber,
							languageSchema.Stock.currentUser,
							languageSchema.Stock.control,
						]}
					>
						{renderingBoxes.map(box => {
							let editableRow = editable && box.UnitID === boxExternalId;
							let status = getPrettyLockStatus(box.lockStatus, languageSchema);

							return (
								<tr key={box.UnitID} style={{ backgroundColor: box.statusCode === '10' ? 'rgba(0,44,58,0.2)' : (box.ReservationID ? '#43e72a36' : 'inherit')}}>
									<td>
										{box.UnitNumber}
										<span className={`icon-status ${status.color}`}>{status.title}</span>
									</td>
									<td>{box.PhysicalSize} m²</td>
									<td>{displayAmount(round(box.MonthRate), stock.currency)}/{languageSchema.Etc.monthShort}</td>
									<td>
										{editableRow ?
											<MaskedInput
												className="form-control form-control-sm"
												placeholder="_:__:__"
												mask={[/\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
												ref={this.MaskedInputRef}
												onChange={this.handleChangeLockId}
												value={lockId}
											/> :
											<>{box.lockId || languageSchema.Stock.notSet}</>
										}
									</td>
									<td>
										{
											box.statusCode === '10' ?
												<div style={{ textDecoration: 'underline', textAlign:'center' }}>Own needs</div> :
												(box.customerId && !box.ReservationID ?
													<Link to={`/users/${box.customerId}`}>
														{box.customerFullName}
													</Link> :
													(box.ReservationID ?
														<div>
															<span className='mr-1'>reserved by</span>
															<Link to={`/users/${box.customerId}`}>
																{box.customerFullName}
															</Link>
														</div> :
														<span> {box.customerFullName} </span> ))
										}


									</td>
									<td>
										{editableRow ?
											<button
												className="btn btn-sm btn-light"
												onClick={() => { this.saveChanges(box); }}
											>
												{languageSchema.Buttons.save}
											</button> :
											<button
												className="btn btn-sm btn-light"
												onClick={() => { this.setEditMode(box.lockId, box.UnitID); }}
											>
												{languageSchema.Buttons.edit}
											</button>
										}{' '}
										{box.lockId &&
																				<Link
																					to={{
																						pathname: `/boxes/${box._id}`,
																						state: {
																							ContractID: box.ContractID,
																							invoices: box.invoices,
																							stockId: stock._id,
																							boxId: box._id,
																						}
																					}}
																					className="btn btn-sm btn-light">
																					{languageSchema.Stock.history}
																				</Link>
										}

										{box.lockId &&
																				<button style={{ marginLeft: '5px' }}
																					className="btn btn-sm btn-success"
																					onClick={this.openBox(box)}
																				>
																					<i className={'fas fa-key'}> {' '} </i>
																					{' '} {languageSchema.Buttons.open}
																				</button>
										}
									</td>
								</tr>
							);
						})}
					</Table>
				</div>
			);
		}
}

const mapStateToProps = ({ boxes, stocks, common }, props) => {
	const currentStockId = qs.parse(props.location.search).stockId;
	const currentStock = stocks.data.find(stock => stock._id === currentStockId);

	return {
		boxes: boxes.data,
		stockId: currentStockId,
		stock: currentStock || {},
		languageSchema: getLanguageSchema(common.language)
	};
};

const mapDispatchToProps = { fetchBoxes, clearCurrentBoxes, saveLockId, fetchStocks, openBox };

export default connect(mapStateToProps, mapDispatchToProps)(BoxListScreen);
