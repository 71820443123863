import * as Yup from 'yup';

import { errorCodesMap } from 'utils/constants';

const validationSchema = () => Yup.object().shape({
	email: Yup.string()
		.email(errorCodesMap.enterValidEmail)
		.required(errorCodesMap.emailIsRequired),
	password: Yup.string().required(errorCodesMap.passwordIsRequired),
});

export default validationSchema;
