import React, { useState } from 'react';
import { Form } from 'formik';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Checkbox from 'react-simple-checkbox';

import { ACCOUNT_TYPES_MAP, env } from 'utils/constants';
import LegalUserFormFields from 'components/User/LegalUserFormFields';
import IndividualUserFormFields from 'components/User/IndividualUserFormFields';

import topboxLogo from 'assets/images/topbox_large_logo.png';


let getFormTypeSelectOptions = (languageSchema) => Object.keys(ACCOUNT_TYPES_MAP)
	.map(accountType => ({
		value: accountType,
		label: languageSchema.User[accountType],
	}));

function SignUpForm(props) {
	let	 {
		values,
		touched,
		isSuccess,
		errors,
		languageSchema,
		language,
		setFieldValue,
		setAccountType,
		onLogInAction
	} = props;

	let [formType, setFormType] = useState(values.accountType);
	let [isAgeConfirmed, toggleAgeConfirmation] = useState(false);

	let isError = field => touched[field] && errors[field];

	let onFormTypeChange = type => {
		setFormType(type);
		setFieldValue('accountType', type.value);
		setAccountType(type.value);

		if (env === 'production') {
			ym(89808973,'reachGoal', 'account_type_signup_modal_select'); // eslint-disable-line
		}
	};

	let handleFormTypeChange = (type) => () => {
		onFormTypeChange(type);
	};

	let getFormFields = (type, props) => ({
		[ACCOUNT_TYPES_MAP.legal]: <LegalUserFormFields {...props} />,
		[ACCOUNT_TYPES_MAP.individual]: <IndividualUserFormFields {...props} />,
	}[type] || null);

	return (
		<div>
			<div className="text-center mb-3">
				<img
					src={topboxLogo}
					alt="TopBox"
					title="TopBox"
					width="55%"
				/>
			</div>
			<Form>
				<div className="form-group">
					<label>
						{languageSchema.User.accountType}
					</label>
					<div>
						<div className="row">
							{
								getFormTypeSelectOptions(languageSchema).map(type=> {
									let isActive = values.accountType === type.value;

									return (
										<div className="col-md-6" key={type.value}>
											<div
												onClick={handleFormTypeChange(type)}
												className={`account-type-item ${isActive ? 'selected' : ''}`}
											>
												{type.label}
											</div>
										</div>
									);
								})
							}
						</div>
					</div>
				</div>

				{
					formType &&
					<>
						{
							getFormFields(
								formType.value,
								{
									isEdit: false,
									language,
									languageSchema,
									setFieldValue,
									errors,
									isError,
									values
								})
						}
					</>
				}

				{
					values.accountType === ACCOUNT_TYPES_MAP.individual &&
					<div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div>
							<Checkbox
								color="#3cab26"
								size={3}
								tickAnimationDuration={100}
								backAnimationDuration={100}
								borderThickness={2}
								checked={isAgeConfirmed}
								onChange={toggleAgeConfirmation}
							/>
						</div>
						<div>
							<label style={{ marginTop: '10px', marginLeft: '7px' }} className=" col-form-label">
								{languageSchema.User.isEighteenAge}
								{<span style={{ color: 'red', fontSize: '22px' }}>*</span>}
							</label>
						</div>
					</div>

				}

				{isSuccess && (
					<div className="alert alert-success mt-3" role="alert">
						{languageSchema.Etc.success}
					</div>
				)}
				<div>
					{
						formType.value &&
						<div style={{ marginTop: '40px' }} className="d-flex flex-column">
							<button
								disabled={values.accountType === ACCOUNT_TYPES_MAP.individual && !isAgeConfirmed}
								type="submit"
								className="btn btn-success mb-2"
								style={{ fontSize: '20px' }}
							>
								{languageSchema.SignUp.signUp}
							</button>
						</div>
					}

					<div style={{
						textTransform: 'uppercase',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '30px 0',
						color: '#283049',
						fontSize: '28px',
						fontWeight: 600
					}}>
						{languageSchema.Etc.or}
					</div>
					{
						onLogInAction ?
							<div
								onClick={onLogInAction}
								style={{
									backgroundColor: '#283049',
									fontSize: '20px',
									width: '100%'
								}}
								className="btn btn-secondary"
							>
								{languageSchema.LogIn.enter}
							</div> :
							<Link
								to='/login'
								style={{
									backgroundColor: '#283049',
									fontSize: '20px',
									width: '100%'
								}}
								className="btn btn-secondary"
							>
								{languageSchema.LogIn.enter}
							</Link>
					}
				</div>
			</Form>
		</div>
	);
}

let mapStateToProps = ({ auth, users, common }) => {
	return {
		isFetchedUsers: users.isFetched,
		language: common.language,
		loggedInUser: auth.me
	};
};

export default connect(mapStateToProps)(SignUpForm);
