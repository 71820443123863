import React, { Component } from 'react';
import _ from 'lodash';

import StocksListItem from './StocksListItem';

class StockList extends Component {
	render() {
		const { boxes, stocks, languageSchema, user, language } = this.props;
		const grouppedBoxes = _.groupBy(boxes, 'SiteID');

		return (
			<div className="mb-3">
				{stocks.map(stock =>
					grouppedBoxes[stock.stockId_sm] && (
						<StocksListItem
							key={stock._id}
							stock={stock}
							language={language}
							languageSchema={languageSchema}
							user={user}
							boxes={grouppedBoxes[stock.stockId_sm]}
						/>
					))}
			</div>
		);
	}
}

export default StockList;
