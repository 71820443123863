import React, { Component } from 'react';
import ModalImage from 'react-modal-image';
import { toastr } from 'react-redux-toastr';

import { apiUrl, IS_KYC_ENABLED } from 'utils/constants.js';
import { Formik, Field } from 'formik';
import request from 'helpers/request';
import { Modal } from 'react-bootstrap';
import { isAdmin } from 'utils/functions';
import ToggleBlock from 'components/common/InfoToggler';
import Heading from 'components/UI/Heading';
import kycRejectValidation from './KYCvalidation';

export default class UserPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			selfieImageUrl: null,
			authToken: null,
			isSelfieImageAvailable: false,
			isPassportImageAvailable: false,
			isRejectModalOpened: false,
			kycFileTypeToReject: 'null'
		};
	}

	async componentDidMount() {
		let { user } = this.props;
		if (!user) {
			return;
		}

		if (IS_KYC_ENABLED) {
			await this.checkKYCPhotoAvailability(user);
		}
	}

		openRejectKYCFileModal = fileType => () => this.setState({ isRejectModalOpened: true, kycFileTypeToReject: fileType });
		closeRejectKYCFileModal = () => this.setState({ isRejectModalOpened: false });

		handleKYCFileReject = async (values) => {
			toastr.confirm(this.props.languageSchema.User.areYouSureYouWantToRejectDocument, {
				onOk: async () => {
					try {
						let { kycFileTypeToReject } = this.state;

						await request('PUT', `users/${this.props.user._id}/kyc/${this.props.user._id}_${kycFileTypeToReject}/reject`, {
							reason: values.reason,
						});

						this.closeRejectKYCFileModal();
						await this.checkKYCPhotoAvailability(this.props.user);
						await this.props.fetchUsers();
					} catch (error) {
						throw error;
					}
				},
			});
		}

		checkKYCPhotoAvailability = async (user) => {
			let authToken = localStorage.getItem('accessToken');
			let selfieImageEndpoint = `uploads/kyc/${user._id}_selfie.jpeg?authToken=${authToken}`;
			let selfieImageUrl = `${apiUrl}/${selfieImageEndpoint}`;
			let passportImageEndpoint = `uploads/kyc/${user._id}_passport.jpeg?authToken=${authToken}`;
			let passportImageUrl = `${apiUrl}/${passportImageEndpoint}`;

			this.setState({ selfieImageUrl, passportImageUrl });

			try {
				await request('GET', selfieImageEndpoint);

				this.setState({ isSelfieImageAvailable: true });
			} catch(error) {
				this.setState({ isSelfieImageAvailable: false });
			}

			try {
				await request('GET', passportImageEndpoint);

				this.setState({ isPassportImageAvailable: true });
			} catch(error) {
				this.setState({ isPassportImageAvailable: false });
			}
		}

		setVerification = (isVerificationPassed) => async () => {
			let toastrText = isVerificationPassed ?
				this.props.languageSchema.User.areYouSureYouWantToMarkProfileAsVerified :
				this.props.languageSchema.User.areYouSureYouWantToCancelProfileVerification;

			toastr.confirm(toastrText, {
				onOk: async () => {
					try {
						this.setState({ isLoading: true });
						await request('PUT', `users/${this.props.user._id}`, { isKYCVerified: isVerificationPassed });

						await this.props.fetchUsers();

						this.setState({ isLoading: false });
					} catch(error) {
						this.setState({ isLoading: false });
					}
				},
			});
		}

		render() {
			if (!IS_KYC_ENABLED) {
				return null;
			}

			let { user, languageSchema, loggedInUser } = this.props;
			let isCurrentUserAdmin = loggedInUser.role && isAdmin(loggedInUser.role);
			let {
				selfieImageUrl, passportImageUrl, isSelfieImageAvailable,
				isPassportImageAvailable, isLoading
			} = this.state;

			return (
				<div className="card">
					<Heading>{languageSchema.User.kycHeader}</Heading>

					<div className="mb-1">
						{
							isCurrentUserAdmin &&
												<ToggleBlock
													text={[languageSchema.User.checkPhotosInfoBlock]}
													alertType="info"
												/>
						}
						<ToggleBlock
							text={[languageSchema.User.docRejectionDescription, languageSchema.User.userNeedToReupload]}
							alertType="warning"
						/>
					</div>

					<div className="row mb-3">
						<div className={`col-md-${isCurrentUserAdmin ? '4' : '6'} mb-3`}>
							{
								isSelfieImageAvailable && !user.selfieKYCRejectionReason &&
																<div className="text-center">
																	<ModalImage
																		small={selfieImageUrl}
																		large={selfieImageUrl}
																	/>
																	{
																		isCurrentUserAdmin &&
																		<button
																			onClick={this.openRejectKYCFileModal('selfie')}
																			className="btn btn-sm btn-secondary mt-3"
																		>
																			{languageSchema.Buttons.reject}
																		</button>
																	}
																</div>
							}

							{
								!isSelfieImageAvailable && !user.selfieKYCRejectionReason &&
																<div className="flex-card">
																	{languageSchema.User.selfieWasNotUploaded}
																</div>
							}

							{
								user.selfieKYCRejectionReason &&
																<div className="flex-card">
																	<div>
																		{languageSchema.User.selfieWasRejected} <br /><br />
																		<b>{languageSchema.User.reason}</b>: <br />
																		{ user.selfieKYCRejectionReason }
																	</div>
																</div>
							}
						</div>

						<div className={`col-md-${isCurrentUserAdmin ? '4' : '6'} mb-3`}>
							{
								isPassportImageAvailable && !user.passportKYCRejectionReason &&
																<div className="text-center">
																	<ModalImage
																		small={passportImageUrl}
																		large={passportImageUrl}
																	/>
																	{
																		isCurrentUserAdmin &&
																		<button
																			onClick={this.openRejectKYCFileModal('passport')}
																			className="btn btn-sm btn-secondary mt-3"
																		>
																			{languageSchema.Buttons.reject}
																		</button>
																	}
																</div>
							}

							{
								!isPassportImageAvailable && !user.passportKYCRejectionReason &&
																<div className="flex-card">
																	{languageSchema.User.passportPhotoWasNotUploaded}
																</div>
							}

							{
								user.passportKYCRejectionReason &&
																<div className="flex-card">
																	<div>

																		{languageSchema.User.passportPhotoWasRejected}.
																		<br /><br /> <b>{languageSchema.User.reason}</b>: <br />
																		{ user.passportKYCRejectionReason }
																	</div>
																</div>
							}
						</div>

						{
							isCurrentUserAdmin &&
												<div className="col-md-4 text-center mt-3">
													<div className="mb-3">
														<i className={`fas fa-4x fa-user-check ${user.isKYCVerified ? 'green' : 'red'}`}></i>
													</div>
													<div>
														{
															user.isKYCVerified ?
																<div className="mb-3">
																	<button disabled={isLoading} className={`${isLoading ? 'disabled' : ''} btn-sm btn-danger`} onClick={this.setVerification(false)}>
																		{isLoading ? languageSchema.Etc.wait : languageSchema.User.cancelVerification}
																	</button>
																</div> :
																<div className="mb-3">
																	<button
																		disabled={isLoading || !isPassportImageAvailable || !isSelfieImageAvailable}
																		className={`${isLoading ? 'disabled' : ''} btn btn-sm btn-success`}
																		onClick={this.setVerification(true)}
																	>
																		{isLoading ? languageSchema.Etc.wait : languageSchema.User.verify}
																	</button>
																</div>
														}
													</div>
												</div>
						}
					</div>

					{ this.state.isRejectModalOpened &&
										<Modal
											show={this.state.isRejectModalOpened}
											onHide={this.closeRejectKYCFileModal}
										>
											<Formik
												initialValues={{ reason: '' }}
												onSubmit={this.handleKYCFileReject}
												validationSchema={kycRejectValidation}
												render={({ errors, touched, handleSubmit }) => {
													const isError = field => errors[field] && touched[field];

													return (
														<div>
															<Modal.Header closeButton>
																<Modal.Title>{languageSchema.User.rejectionReason}</Modal.Title>
															</Modal.Header>
															<Modal.Body>
																<div className="col-sm-12">
																	<Field
																		name="reason"
																		component="textarea"
																		className={`form-control ${isError('reason') ? 'is-invalid' : ''}`}
																		placeholder={languageSchema.User.setRejectionReason}
																		style={{ width: '100%' }}
																	></Field>
																	{
																		isError('reason') &&
																										<div className="invalid-feedback">
																											{errors.reason}
																										</div>
																	}
																</div>

																<div className="mt-3 text-right">
																	<button
																		onClick={handleSubmit}
																		type="submit"
																		className="btn btn-sm btn-secondary mr-3"
																	>
																		{languageSchema.Buttons.reject}
																	</button>
																	<button
																		className="btn btn-sm btn-outline-secondary mr-3"
																		onClick={this.closeRejectKYCFileModal}
																	>
																		{languageSchema.Buttons.close}
																	</button>
																</div>
															</Modal.Body>
														</div>
													);
												}}
											/>
										</Modal>
					}
				</div>
			);
		}
}
