import { useEffect, useRef, useState } from 'react';

export const useThrottle = (value, interval = 500) => {
	const [throttledValue, setThrottledValue] = useState(value);
	const lastExecuted = useRef(Date.now());

	useEffect(() => {
		if (Date.now() >= lastExecuted.current + interval) {
			lastExecuted.current = Date.now();
			setThrottledValue(value);
		} else {
			const timerId = setTimeout(() => {
				lastExecuted.current = Date.now();
				setThrottledValue(value);
			}, interval);

			return () => clearTimeout(timerId);
		}
	}, [value, interval]);

	return throttledValue;
};

export const useDebounce = (value, milliSeconds = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
   
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, milliSeconds);
   
		return () => {
			clearTimeout(handler);
		};
	}, [value, milliSeconds]);
   
	return debouncedValue;
};
