import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import qs from 'query-string';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { apiUrl, httpMethodsMap, errorCodesMap } from 'utils/constants';
import { getRoleNameFromToken, isAdmin } from 'utils/functions';
import history from 'utils/history';

let isInitialized = false;
const tokenKey = 'accessToken';
let token;

export async function init(options) {
	let {
		reduxStore, initRequestCallback, finishRequestCallback,
		formatRequest, formatResponse,
	} = options;

	token = getToken(tokenKey);

	let setLoadingState = () => reduxStore.dispatch(initRequestCallback());
	let offLoadingState = () => {
		let state = reduxStore.getState();
		let minLoadingsNumberForDelayEndLoading = 1;
		let endLoadingDelayMS = 700;

		// this statement is needed to prevent loader's interruption on API requests
		if (state.common.loadings <= minLoadingsNumberForDelayEndLoading) {
			return setTimeout(() => reduxStore.dispatch(finishRequestCallback()), endLoadingDelayMS);
		}

		return reduxStore.dispatch(finishRequestCallback());
	};

	let unsetLoadingState = (requestResult) => {
		offLoadingState();

		return requestResult;
	};

	let handleRequestError = (error) => {
		unsetLoadingState(error);

		return Promise.reject(error);
	};

	isInitialized = true;

	// second argumets here are used in error cases
	axios.interceptors.request.use(
		(config) => {
			setLoadingState();

			return config;
		},
		unsetLoadingState,
	);

	axios.interceptors.response.use(unsetLoadingState, handleRequestError);

	axios.dispatchError = (errors, code) => {
		if (code === 404) {
			console.error('Error 404');
		}

		let role = getRoleNameFromToken();

		if (code === 401) {
			if (window.location.href.includes('invoiceId')) {
				localStorage.setItem('returnUrl', window.location.href);
			} 

			history.push('/login');
		}

		if (errors) {
			let language = reduxStore.getState().common.language;
			let languageSchema = getLanguageSchema(language);

			errors.forEach(error => {
				let isSomethingWrong = !error.errorCode ||
					!languageSchema.errors[error.errorCode] ||
					error.errorCode === errorCodesMap.somethingWentWrong;

				if (isSomethingWrong && !isAdmin(role)) {
					// history.push('/something-went-wrong');
				}

				toastr.error(
					'',
					languageSchema.errors[error.errorCode || 'unrecognized']
				);
			});
		}
	};

	axios.formatRequest = formatRequest;
	axios.formatResponse = formatResponse;
}

export function unsetToken() {
	token = null;

	localStorage.removeItem(tokenKey);
}

export function setToken(newToken) {
	token = newToken;

	localStorage.setItem(tokenKey, newToken);
}

export function getToken() {
	return localStorage.getItem(tokenKey);
}

export function isTokenExist() {
	return token !== null;
}

export default async function request(method, endpoint, data = {}, params = {}) {
	if (!isInitialized) {
		throw new Error('Please, initialize request before it\'s usage');
	}

	let [gaCookie] = document.cookie.split(';');
	let [, , ...cid] = gaCookie.split('.');

	let requestParams = {
		method,
		url: `${apiUrl}/${endpoint}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'CID': cid.join('.'),
		},
	};
	console.log('---REQUEST INFO:--------------');
	console.log(requestParams);
	console.log('------------------------------');

	if (method === httpMethodsMap.GET) {
		let stringifiedQuery = qs.stringify(data);
		let queryString = stringifiedQuery ? `?${stringifiedQuery}` : '';

		requestParams.url += queryString;
	} else {
		requestParams.data = data;
	}

	if (method === httpMethodsMap.PUT) {
		let stringifiedQuery = qs.stringify(params);
		let queryString = stringifiedQuery ? `?${stringifiedQuery}` : '';

		requestParams.url += queryString;
	}

	return axios(requestParams)
		.then((res) => res.data)
		.catch(handleFailedRequest);
}

function handleFailedRequest(error) {
	console.log('---REQUEST ERROR:-------------');
	// Error
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		console.log('Error response', error.response);

		let errors = error.response.data.errors;
		axios.dispatchError(errors, error.response.status);
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		console.log('Error request', error.request);
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', error.message);
	}
	console.log('Error config', error.config);
	console.log('------------------------------');

	throw error;
}
