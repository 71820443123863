import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preview from 'components/common/Designer/Preview';
import { Rect, Path, Circle, RectWithText, PathWithText, Text } from 'components/common/Designer';
import _ from 'lodash';
import { Tabs, Popover, Tooltip } from 'antd';
import { displayAmount, round } from 'helpers/amount';
import { LOCALIZATION_WARSAW_WEBSITE_URL, env } from 'utils/constants';
import { getLanguageSchema } from 'dashboard-commons/languages';

import './Map.style.scss';

const { TabPane } = Tabs;
const redFillColor = '#D9ADAD';
const redStrokeColor = '#D75656';
const greenFillColor = '#3CAB26';
const greenStrokeColor = '#137400';
const freeBoxesFillColor = '#FED53047';
const freeBoxesStrokeColor = '#FED530';

// TODO: refactor to functional component
class BoxBookingMap extends Component {
	constructor(props) {
		super(props);

		this.state = {
			objects: [],
			activeKey: undefined, // undefined is needed to set default item in Tabs component
			clickedUnitNumber: null,
		};
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.isMapDragging !== this.props.isMapDragging) {
			this.handleClickedUnitNumber(null);
			this.props.selectBox(null)();
		}

		if (newProps.selectedBoxUnitNumber && this.props.selectedBoxUnitNumber !== newProps.selectedBoxUnitNumber) {
			let boxTabPane = Object.keys(this.props.objects).find(tab =>
				this.props.objects[tab]
					.find(el => el.text === newProps.selectedBoxUnitNumber)
			);

			this.setState({ activeKey: boxTabPane }, () => {
				if (this.props.viewers && newProps.selectedBoxUnitNumber) {
					let [floorNumber] = newProps.selectedBoxUnitNumber;
					let floor = Object.keys(this.props.objects).find(el => el.includes(floorNumber)) || 'parter';

					let object = this.props.objects[floor] &&
						this.props.objects[floor].find(el => el.text === newProps.selectedBoxUnitNumber);

					let correspondedMapViewer = this.props.viewers[floor];

					setTimeout(() => {
						if (correspondedMapViewer) {
							correspondedMapViewer.reset();
							correspondedMapViewer.setPointOnViewerCenter(object.x, object.y, 1);
						}
					}, 300);
				}
			});
		}
	}

	getTabsName(id) {
		if (this.props.language === 'EN') {
			return id.replace('piętro', 'floor').replace('parter', 'ground');
		}

		return id;
	}

	handleClickedUnitNumber = (unitNumber) => {
		this.setState({ clickedUnitNumber: unitNumber });

		let isMobile = window.innerWidth < 900;

		if (isMobile && unitNumber) {
			let [floorNumber] = unitNumber;

			let floor = Object.keys(this.props.objects).find(el => el.includes(floorNumber)) || 'parter';

			let object = this.props.objects[floor] &&
				this.props.objects[floor].find(el => el.text === unitNumber);

			let correspondedMapViewer = this.props.viewers[floor];

			setTimeout(() => {
				if (correspondedMapViewer) {
					correspondedMapViewer.reset();
					correspondedMapViewer.setPointOnViewerCenter(object.x, object.y, 1);
				}
			}, 300);
		}
	}

	render() {
		return (
			<div className="mb-5">
				{
					!_.isEmpty(this.props.objects) &&
					<div className="mt-2">
						<Tabs
							defaultActiveKey="1"
							activeKey={this.state.activeKey}
							type="card"
							size='small'
							onChange={(key) => {
								this.setState({ activeKey: key });
								// temporary, case 4th floor map is not full yet
								// if (key === 'piętro #4') {
								// 	setTimeout(() => {
								// 		let correspondedMapViewer = this.props.viewers['piętro #4'];
								//
								// 		if (correspondedMapViewer) {
								// 			correspondedMapViewer.reset();
								// 			correspondedMapViewer.setPointOnViewerCenter('1101', '103', 0.85);
								// 		}
								// 	}, 300);
								// }
							}}
						>
							{Object.keys(this.props.objects).map((id) => {
								return (
									<TabPane key={id} tab={this.getTabsName(id)}>
										<div
											id={`floor_tab_${id}`}
											className="map_wrapper mt-2"
											onClick={() => {
												if (env === 'production') {
													ym(89808973,'reachGoal', `floor_tab_${id}`); // eslint-disable-line
												}
											}}
										>
											<Preview
												objects={this.props.objects[id]}
												height={800}
												width={1600}
												objectTypes={{
													'text': Text,
													'rectangle': Rect,
													'polygon': Path,
													circle: Circle,
													'rectangle-with-text': (props) => {
														return getRectangleObject({
															...props,
															...this.props,
															handleClickedUnitNumber: this.handleClickedUnitNumber,
															clickedUnitNumber: this.state.clickedUnitNumber,
														});
													},
													'polygon-with-text': (props) => getPolygonObject({
														...props,
														...this.props,
														handleClickedUnitNumber: this.handleClickedUnitNumber,
														clickedUnitNumber: this.state.clickedUnitNumber,
													})
												}}
												selectedBoxUnitNumber={this.props.selectedBoxUnitNumber}
												setViewers={this.props.setViewers}
												viewers={this.props.viewers}
												onTouchStart={this.props.onTouchStart}
												onTouchEnd={this.props.onTouchEnd}
												mapName={id}
												selectedSizeCodes={this.props.selectedSizeCodes}
												clickedUnitNumber={this.state.clickedUnitNumber}
											/>
										</div>
									</TabPane>
								);
							})}
						</Tabs>
					</div>
				}
				<div className="flex flex-inline flex-center flex-wrap">
					<div className="legenda mt-2">
						<BoxLegenda
							fill={greenFillColor}
							stroke={greenStrokeColor}
						/>
						<span className="legenda-text">
							{this.props.freeBoxesOfTheRightSize}
						</span>
					</div>

					<div className="legenda mt-2">
						<BoxLegenda
							fill={redFillColor}
							stroke={redStrokeColor}
						/>
						<span className="legenda-text">
							{this.props.occupiedBoxes}
						</span>
					</div>

					<div className="legenda mt-2">
						<BoxLegenda
							fill={freeBoxesFillColor}
							stroke={freeBoxesStrokeColor}
						/>
						<span className="legenda-text">
							{this.props.yourChoice}
						</span>
					</div>

					<div className="legenda mt-2">
						<BoxLegenda
							fill="lightgrey"
							stroke="#646464"
						/>
						<span className="legenda-text">
							{this.props.languageSchema.BoxBooking.boxesFromAnotherCategory}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

let mapStateToProps = state => {
	return {
		isMapDragging: state.boxes.isMapDragging,
	};
};

export default connect(mapStateToProps)(BoxBookingMap);

function getRectangleObject(props) {
	let mapStateToProps = state => {
		return {
			isMapDragging: state.boxes.isMapDragging,
			languageSchema: getLanguageSchema(state.common.language),
		};
	};

	let ReduxConnectedComponent = connect(mapStateToProps)(BoxOfRectangleForm);

	return (
		<ReduxConnectedComponent
			{..._.omit(props, ['MapName', 'Mapid', 'step3SelectABoxOnTheMap'])}
			handleClick={props.selectBox}
			viewMode={props.viewMode}
			handleClickedUnitNumber={props.handleClickedUnitNumber}
			clickedUnitNumber={props.clickedUnitNumber}
		/>
	);
}

function getPolygonObject(props) {
	let mapStateToProps = state => {
		return {
			isMapDragging: state.boxes.isMapDragging,
			languageSchema: getLanguageSchema(state.common.language),
		};
	};

	let ReduxConnectedComponent = connect(mapStateToProps)(BoxOfPolygonForm);

	return (
		<ReduxConnectedComponent
			{..._.omit(props, ['MapName', 'Mapid', 'step3SelectABoxOnTheMap'])}
			handleClick={props.selectBox}
			viewMode={props.viewMode}
			handleClickedUnitNumber={props.handleClickedUnitNumber}
			clickedUnitNumber={props.clickedUnitNumber}
		/>
	);
}

class BoxOfRectangleForm extends RectWithText {
	constructor(props) {
		super(props);

		this.state = {
			isPopoverShown: false,
		};
	}

	onElementClick = (box) => () => {
		this.props.handleClick(box)();

		let boxesListItem = document.getElementById(`${box.UnitNumber}_list`);

		// scroll in boxes list
		setTimeout(
			() => {
				if (boxesListItem) {
					boxesListItem.scrollIntoView({
						block: 'center',
						behavior: 'auto'
					});
				}
			},
			600
		);
	};

	render() {
		let { object, boxes, languageSchema, clickedUnitNumber } = this.props;
		let {
			rotate,
			textColor,
			stroke: defaultStroke,
			fill: defaultFill,
			strokeWidth,
			radius,
			...restOfAttributes
		} = this.getObjectAttributes();

		let box = boxes.find(box => box.UnitNumber === object.text) || {};

		if (!box) {
			return null;
		}

		// Number(box.MonthRate) === 0; -- Maria asked to do this to hide redundant boxes
		let isBusy = box.IsOccupied || Boolean(box.IsReserved) || box.statusCode === '10' || Number(box.MonthRate) === 0;
		let isBoxCantBeSelected = box.statusCode !== '1' || !this.props.selectedSizeCodes.includes(Number(box.PhysicalSize));
		let isSelected = this.props.selectedBoxUnitNumber === box.UnitNumber;
		let isBulkBox = box.Sizecode && box.Sizecode.includes('BULK');

		const getColor = () => {
			if (isBusy) {
				return { fill: '#D9ADAD', stroke: defaultStroke };
			}

			if (!box || isBoxCantBeSelected) {
				return { stroke: defaultStroke, fill: defaultFill };
			}

			if (isSelected) {
				return { fill: '#FED53047', stroke: '#FED530' };
			}

			return { fill: '#3CAB26', stroke: '#137400' };
		};

		const { fill, stroke } = getColor();

		// TODO: fix calculationg with vat rate
		const VAT_RATE = 23;

		let isExternalBox = box && box.UnitNumber && box.UnitNumber.includes('D');

		let externalBoxTitle = (
			<div dangerouslySetInnerHTML={{ __html: languageSchema.BoxBooking.externalBoxDescription(LOCALIZATION_WARSAW_WEBSITE_URL) }} />
		);

		const content=(box, boxStatus) => (
			<ul style={{ minWidth: 230, maxWidth: 310 }} className="list-unstyled">
				<li className="external-box">
					<div>
						<b>{languageSchema.BoxBooking.box}:</b> {'  '}
						{box.UnitNumber}
						{isExternalBox ?
							<>
								<br />
								<br />
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<br />
									<span className="external-box__icon">&#10071;</span> {externalBoxTitle}
								</div>
								<br />
							</> :
							null
						}
					</div>
				</li>
				{!isBusy && !isBulkBox && <li><b>{languageSchema.Stock.monthRate}:</b> {displayAmount(box.MonthRate + round(box.MonthRate * VAT_RATE / 100), 'PLN')}</li>}
				{
					(Boolean(box.Height) ||
					Boolean(box.Width) ||
					Boolean(box.Depth)) &&
					<li>
						{
							Boolean(box.Height) &&
							<><b>{languageSchema.Stock.height}:</b> {box.Height}{languageSchema.Etc.metersShortLetter}{'  '}</>
						}
						{
							Boolean(box.Width) &&
							<><b>{languageSchema.Stock.width}:</b> {box.Width}{languageSchema.Etc.metersShortLetter}{'  '}</>
						}
						{
							Boolean(box.Depth) &&
							<><b>{languageSchema.Stock.depth}:</b> {box.Depth}{languageSchema.Etc.metersShortLetter}</>
						}
					</li>
				}
				{
					Boolean(box.PhysicalSize) &&
					<li>
						<b>{languageSchema.Stock.square}:</b> {(box.PhysicalSize).toFixed(2)} {languageSchema.Etc.metersShortLetter}²
					</li>
				}
				<div
					onClick={() => {
						this.props.handleClickedUnitNumber(null);

						if (isSelected) {
							this.props.handleClick(null)();
						}
					}}
					onTouchEnd={() => {
						this.props.handleClickedUnitNumber(null);

						if (isSelected) {
							this.props.handleClick(null)();
						}
					}}
					className="btn btn-sm btn-outline-secondary mt-2 mr-4"
				>
					{languageSchema.BoxBooking.close}
				</div>
				{
					!(boxStatus.isBoxCantBeSelected || boxStatus.isBusy) &&
						<div
							onClick={() => {
								this.onElementClick(box)();
								this.props.setSideBarOpened(true);
								this.props.handleClickedUnitNumber(null);

								if (env === 'production') {
									ym(89808973,'reachGoal', 'continue_after_box_selected'); // eslint-disable-line
								}
							}}
							onTouchEnd={() => {
								this.onElementClick(box)();
								this.props.setSideBarOpened(true);
								this.props.handleClickedUnitNumber(null);

								if (env === 'production') {
									ym(89808973,'reachGoal', 'continue_after_box_selected'); // eslint-disable-line
								}
							}}
							className="btn btn-sm btn-outline-primary mt-2"
						>
							{languageSchema.BoxBooking.continue} &gt;&gt;
						</div>
				}
			</ul>
		);

		let isCubicBox = box && box.UnitNumber && box.UnitNumber.includes('KB');

		let svgAttributes = _.pick(restOfAttributes, ['blendMode', 'fontFamily', 'fontSize', 'fontStyle', 'fontWeight', 'height', 'text', 'textDecoration', 'transform', 'width', 'x', 'y']);

		return (
			box.UnitNumber ?
				<Popover
					mouseEnterDelay={0.4}
					mouseLeaveDelay={0.9}
					destroyTooltipOnHide={true}
					content={content(box, { isBusy, isBoxCantBeSelected })}
					trigger="click"
					visible={(clickedUnitNumber === box.UnitNumber) && !this.props.isMapDragging}
					style={{ overflow: 'hidden' }}
					zIndex={100}
				>
					<g
						onClick={() => {
							this.props.handleClickedUnitNumber(clickedUnitNumber !== box.UnitNumber ? box.UnitNumber : null);

							if (!isBusy && !isBoxCantBeSelected) {
								this.props.handleClick(box)();

								if (env === 'production') {
									ym(89808973,'reachGoal', 'free_box_clicked_on_map'); // eslint-disable-line
								}

								let boxesListItem = document.getElementById(`${box.UnitNumber}_list`);

								// scroll in boxes list
								setTimeout(
									() => {
										if (boxesListItem) {
											boxesListItem.scrollIntoView({
												block: 'center',
												behavior: 'auto'
											});
										}
									},
									600
								);
							}
						}}
						onTouchEnd={() => {
							// to prevent openings during dragging
							if (!this.props.isMapDragging) {
								this.props.handleClickedUnitNumber(clickedUnitNumber !== box.UnitNumber ? box.UnitNumber : null);

								if (!isBusy && !isBoxCantBeSelected) {
									this.props.handleClick(box)();

									if (env === 'production') {
										ym(89808973,'reachGoal', 'free_box_clicked_on_map'); // eslint-disable-line
									}

									let boxesListItem = document.getElementById(`${box.UnitNumber}_list`);

									// scroll in boxes list
									setTimeout(
										() => {
											if (boxesListItem) {
												boxesListItem.scrollIntoView({
													block: 'center',
													behavior: 'auto'
												});
											}
										},
										600
									);
								}
							}
						}}
					>
						<rect
							style={{ ...this.getStyle(), cursor: (isBusy || isBoxCantBeSelected) ? 'default' : 'pointer' }}
							{...svgAttributes}
							rx={object.radius}
							width={object.width}
							height={object.height}
							rotate={rotate}
							stroke={stroke}
							fill={fill}
							strokeWidth={strokeWidth}
							radius={radius}
							id={`map_${box.UnitNumber}`}
						/>
					</g>
				</Popover> :
				<g>
					<rect
						style={{ ...this.getStyle(), cursor: (isBusy || isBoxCantBeSelected) ? 'default' : 'pointer' }}
						{...svgAttributes}
						rx={object.radius}
						width={object.width}
						height={object.height}
						rotate={rotate}
						stroke={stroke}
						fill={fill}
						strokeWidth={strokeWidth}
						radius={radius}
						id={`map_${box.UnitNumber}`}
					/>

					<text style={{ ...this.getStyle(), cursor: (isBusy || isBoxCantBeSelected) ? 'default' : 'pointer' }}
						{...svgAttributes}
						textAnchor="right"
						fontSize={object.fontSize}
						fontFamily={object.fontFamily}
						fill={textColor}
						{...this.setTextToCenter()}
					>
						{object.text}
					</text>
				</g>
		);
	}
}

class BoxOfPolygonForm extends PathWithText {
	constructor(props) {
		super(props);

		this.state = {
			isPopoverShown: false,
		};
	}

	onElementClick = (box) => () => {
		this.props.handleClick(box)();

		let boxesListItem = document.getElementById(`${box.UnitNumber}_list`);

		// scroll in boxes list
		setTimeout(
			() => {
				if (boxesListItem) {
					boxesListItem.scrollIntoView({
						block: 'center',
						behavior: 'auto'
					});
				}
			},
			600
		);
	};

	render() {
		let { object, boxes, languageSchema, clickedUnitNumber } = this.props;
		let {
			rotate,
			textColor,
			stroke: defaultStroke,
			fill: defaultFill,
			strokeWidth,
			radius,
			...restOfAttributes
		} = this.getObjectAttributes();

		let box = boxes.find(box => box.UnitNumber === object.text) || {};

		if (!box) {
			return null;
		}

		let isBusy = box.IsOccupied || Boolean(box.IsReserved) || box.statusCode === '10';
		let isBoxCantBeSelected = !this.props.selectedSizeCodes.includes(Number(box.PhysicalSize));
		let isSelected = this.props.selectedBoxUnitNumber === box.UnitNumber;
		let isBulkBox = box.Sizecode && box.Sizecode.includes('BULK');

		const getColor = () => {
			if (isBusy) {
				return { fill: '#D9ADAD', stroke: defaultStroke };
			}

			if (!box || isBoxCantBeSelected) {
				return { stroke: defaultStroke, fill: defaultFill };
			}

			if (isSelected) {
				return { fill: '#FED53047', stroke: '#FED530' };
			}

			return { fill: '#3CAB26', stroke: '#137400' };
		};

		const { fill, stroke } = getColor();

		// TODO: fix calculationg with vat rate
		const VAT_RATE = 23;

		let externalBox = box && box.UnitNumber && box.UnitNumber.includes('D');

		let externalBoxTitle = (
			<div dangerouslySetInnerHTML={{ __html: languageSchema.BoxBooking.externalBoxDescription(LOCALIZATION_WARSAW_WEBSITE_URL) }} />
		);

		const content=(box, boxStatus) => (
			<ul style={{ minWidth: 230 }} className="list-unstyled">
				<li className="external-box">
					<div>
						<b>{languageSchema.BoxBooking.box}:</b>
						{box.UnitNumber}
					</div>
					{externalBox &&
						<Tooltip title={externalBoxTitle}>
							<span className="external-box__icon">&#10071;</span>
						</Tooltip>
					}
				</li>
				{!isBusy && !isBulkBox && <li><b>{languageSchema.Stock.monthRate}:</b> {displayAmount(box.MonthRate + round(box.MonthRate * VAT_RATE / 100), 'PLN')}</li>}
				{
					(Boolean(box.Height) ||
					Boolean(box.Width) ||
					Boolean(box.Depth)) &&
					<li>
						{
							Boolean(box.Height) &&
							<><b>{languageSchema.Stock.height}:</b> {box.Height}{languageSchema.Etc.metersShortLetter}{'  '}</>
						}
						{
							Boolean(box.Width) &&
							<><b>{languageSchema.Stock.width}:</b> {box.Width}{languageSchema.Etc.metersShortLetter}{'  '}</>
						}
						{
							Boolean(box.Depth) &&
							<><b>{languageSchema.Stock.depth}:</b> {box.Depth}{languageSchema.Etc.metersShortLetter}</>
						}
					</li>
				}
				{
					Boolean(box.PhysicalSize) &&
					<li>
						<b>{languageSchema.Stock.square}:</b> {(box.PhysicalSize).toFixed(2)} {languageSchema.Etc.metersShortLetter}²
					</li>
				}
				<div
					onClick={() => {
						this.props.handleClickedUnitNumber(null);
						if (isSelected) {
							this.props.handleClick(null)();
						}
					}}
					onTouchEnd={() => {
						this.props.handleClickedUnitNumber(null);
						if (isSelected) {
							this.props.handleClick(null)();
						}

					}}
					className="btn btn-sm btn-outline-secondary mt-2 mr-4"
				>
					{languageSchema.BoxBooking.close}
				</div>
				{
					!(boxStatus.isBoxCantBeSelected || boxStatus.isBusy) &&
						<div
							onClick={() => {
								this.onElementClick(box)();
								this.props.setSideBarOpened(true);
								this.props.handleClickedUnitNumber(null);
							}}
							onTouchEnd={() => {
								this.onElementClick(box)();
								this.props.setSideBarOpened(true);
								this.props.handleClickedUnitNumber(null);
							}}
							className="btn btn-sm btn-outline-primary mt-2"
						>
							{languageSchema.BoxBooking.continue} &gt;&gt;
						</div>
				}
			</ul>
		);

		return (
			<Popover
				mouseEnterDelay={0.4}
				mouseLeaveDelay={0.9}
				destroyTooltipOnHide={true}
				content={content(box, { isBusy, isBoxCantBeSelected })}
				trigger="click"
				visible={(clickedUnitNumber === box.UnitNumber) && !this.props.isMapDragging}
				style={{ overflow: 'hidden' }}
				zIndex={100}
			>
				<g>
					<path
						style={this.getStyle(object)}
						{...this.getObjectAttributes()}
						d={this.buildPath(object)}
						fill={fill}
						onClick={() => {
							this.props.handleClickedUnitNumber(clickedUnitNumber !== box.UnitNumber ? box.UnitNumber : null);

							if (!isBusy && !isBoxCantBeSelected) {
								this.props.handleClick(box)();
								let boxesListItem = document.getElementById(`${box.UnitNumber}_list`);

								// scroll in boxes list
								setTimeout(
									() => {
										if (boxesListItem) {
											boxesListItem.scrollIntoView({
												block: 'center',
												behavior: 'auto'
											});
										}
									},
									600
								);
							}
						}}
						onTouchEnd={() => {
							if (!isBusy && !isBoxCantBeSelected) {
								this.props.handleClick(box)();
								let boxesListItem = document.getElementById(`${box.UnitNumber}_list`);

								// scroll in boxes list
								setTimeout(
									() => {
										if (boxesListItem) {
											boxesListItem.scrollIntoView({
												block: 'center',
												behavior: 'auto'
											});
										}
									},
									600
								);
							}

							// to prevent openings during dragging
							if (!this.props.isMapDragging) {
								this.props.handleClickedUnitNumber(clickedUnitNumber !== box.UnitNumber ? box.UnitNumber : null);
							}
						}}
					/>
				</g>
			</Popover>
		);
	}
}


function BoxLegenda(props) {
	return (
		<svg width={20} height={20} viewBox={'0 0 32 32'} fill={'none'}>
			<rect
				x={1}
				y={1}
				width={26}
				height={26}
				rx={5}
				fill={props.fill}
				stroke={props.stroke}
				strokeWidth={2}
			/>
		</svg>
	);
}
