import * as React from 'react';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';

import stockMapMarker from 'assets/images/stock_map_marker.png';
import { GOOGLE_MAPS_API_KEY, env } from 'utils/constants';

import StockSelector from './StockSelector';

import './styles.scss';

const DEFAULT_MAP_COORDINATES = {
	lat: 52.3137413672396,
	lng: 20.97801851231157
};

const DEFAULT_ZOOM = 11;

const MapComponent = (props) => {
	const { stocks = [], setStock, languageSchema, defaultSelectedStock } = props;
	const [, setClicks] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const [zoom, setZoom] = React.useState(DEFAULT_ZOOM); // initial zoom
	const [selectedStockId, setSelectedStockId] = React.useState(defaultSelectedStock ? defaultSelectedStock._id : null);
	const [selectedDirectionToStockId, setSelectedDirectionToStockId] = React.useState(null);

	React.useEffect(() => {
		if (defaultSelectedStock) {
			setSelectedStockId(defaultSelectedStock._id);
			setCenter({ lat: defaultSelectedStock.latitude, lng: defaultSelectedStock.longitude });
		}
	}, [defaultSelectedStock]);

	const [center, setCenter] = React.useState(DEFAULT_MAP_COORDINATES);

	const [coordinatesToRoute, setCoordinatesToRoute] = React.useState(null);
	const [totalDistance, setTotalDistance] = React.useState(null);
	const [currentPosition, setCurrentPosition] = React.useState(null);

	let isMobile = window.innerWidth < 900;

	const onClick = (e) => {
		// avoid directly mutating state
		setClicks((prev) => [...prev, e.latLng]);
	};

	const onIdle = (m) => {
		setZoom(m.getZoom());
		setCenter(m.getCenter().toJSON());
	};

	const handleStockSelect = (stock) => () => {
		setCenter({ lat: stock.latitude, lng: stock.longitude });
		setSelectedStockId(stock._id);
		setStock(stock);
	};

	const onCancelRoute = () => {
		setSelectedDirectionToStockId(null);
		setSelectedStockId(null);
		setTotalDistance(null);
	};

	const onShowRoute = (event, stocksCoordinates, stock) => {
		setIsLoading(true);
		setSelectedDirectionToStockId(stock._id);
		setCoordinatesToRoute(stocksCoordinates);

		if (!currentPosition) {
			navigator.geolocation.getCurrentPosition(
				({coords}) => {
					setCurrentPosition(coords);
				},
				(err) => {
					fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAPS_API_KEY}`, {
						method: 'POST',
					}).then((data) => data.json()).then((res) => {
						setCurrentPosition({
							latitude: res.location.lat,
							longitude: res.location.lng
						});
					}).catch((error) => {
						console.log(error);
						console.warn(`ERROR(${error.code}): ${error.message}`);
						setSelectedDirectionToStockId(null);
					}).finally(() => {
						setIsLoading(false);
					});
				});
		}
	};

	return (
		<div>
			<div className="relative">
				{/* className cant be passed */}
				<Map
					center={center}
					onClick={onClick}
					onIdle={onIdle}
					zoom={zoom}
					style={{
						flexGrow: '1',
						height: '600px',
						borderTop: '2px solid #F93822',
						borderBottom: '2px solid #F93822'
					}}
					coordinatesToRoute={coordinatesToRoute}
					currentPosition={currentPosition}
					isMobile={isMobile}
					setIsLoading={setIsLoading}
					setTotalDistance={setTotalDistance}
					selectedDirectionToStockId={selectedDirectionToStockId}
					setSelectedDirectionToStockId={setSelectedDirectionToStockId}
				>
					{
						stocks.map((stock, i) =>
							<Marker
								key={i}
								position={{
									lat: Number(stock.latitude),
									lng: Number(stock.longitude)
								}}
								stock={stock}
								onClick={handleStockSelect(stock)}
								isStockSelected={selectedStockId === stock._id}
								languageSchema={languageSchema}
							/>
						)
					}
				</Map>

				{
					0 < stocks.length &&
					<StockSelector
						stocks={stocks}
						onStockSelected={handleStockSelect}
						selectedDirectionToStockId={selectedDirectionToStockId}
						selectedStockId={selectedStockId}
						setMapCenter={setCenter}
						onShowRoute={onShowRoute}
						onCancelRoute={onCancelRoute}
						isLoading={isLoading}
						totalDistance={totalDistance}
						setStock={setStock}
					/>
				}
			</div>
		</div>
	);
};

const Map = ({
	onClick,
	onIdle,
	children,
	style,
	coordinatesToRoute,
	currentPosition,
	isMobile,
	setIsLoading,
	setTotalDistance,
	selectedDirectionToStockId,
	setSelectedDirectionToStockId,
	...options
}) => {
	const ref = React.useRef(null);
	const [map, setMap] = React.useState();
	const [directionsService, setDirectionsService] = React.useState();
	const [directionsRenderer, setDirectionsRenderer] = React.useState();

	const currentPositionMarker = React.useRef(new window.google.maps.Marker());

	React.useEffect(() => {
		if (currentPosition && selectedDirectionToStockId && map) {
			currentPositionMarker.current.setMap(map);
			currentPositionMarker.current.setPosition({
				lat: currentPosition.latitude,
				lng: currentPosition.longitude,
			});
		} else {
			currentPositionMarker.current.setMap(null);
		}
	}, [currentPosition, selectedDirectionToStockId]);

	React.useEffect(() => {
		if (ref.current && !map) {
			setDirectionsService(new window.google.maps.DirectionsService());
			setDirectionsRenderer(new window.google.maps.DirectionsRenderer({suppressMarkers: true}));
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map]);

	React.useEffect(() => {
		if (coordinatesToRoute && currentPosition) {
			let start = `${currentPosition.latitude}, ${currentPosition.longitude}`;
			let end = `${coordinatesToRoute.latitude}, ${coordinatesToRoute.longitude}`;
			calculateAndDisplayRoute(directionsService, directionsRenderer, `${start}`, `${end}`);
		}
	}, [coordinatesToRoute, currentPosition]);

	// because React does not do deep comparisons, a custom hook is used
	// see discussion in https://github.com/googlemaps/js-samples/issues/946
	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions(options);
		}
	}, [map, options]);

	useDeepCompareEffectForMaps(() => {
		directionsRenderer && directionsRenderer.setMap(map);
	}, [map]);

	React.useEffect(() => {
		if (map) {
			['click', 'idle'].forEach(eventName =>
				/* eslint-disable-next-line no-undef */
				google.maps.event.clearListeners(map, eventName)
			);

			if (onClick) {
				map.addListener('click', onClick);
			}

			if (onIdle) {
				map.addListener('idle', () => onIdle(map));
			}
		}
	}, [map, onClick, onIdle]);

	React.useEffect(() => {
		if (!selectedDirectionToStockId && directionsRenderer) {
			directionsRenderer.setDirections({routes: []});
		}
	}, [selectedDirectionToStockId, directionsRenderer]);

	const calculateAndDisplayRoute = (directionsService, directionsRenderer, start, end) => {
		directionsRenderer.setMap(map);
		directionsService.route({
			origin: start,
			destination: end,
			travelMode: 'DRIVING',
		})
			.then((response) => {
				directionsRenderer.setDirections(response);
				setTotalDistance(computeTotalDistance(response));
				setIsLoading(false);
			})
			.catch((err) => {
				console.warn(err.message);
				setSelectedDirectionToStockId(null);
				setIsLoading(false);
			});
	};

	const computeTotalDistance = (result) => {
		const [myroute] = result.routes;

		if (!myroute) {
			return 0;
		}

		let total = myroute.legs.reduce((acc, elem) => {
			return acc += elem.distance.value;
		}, 0);

		return (total / 1000).toFixed(1);
	};

	if (isMobile) {
		return <div style={style} />;
	}

	return (
		<>
			<div ref={ref} style={style} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
};

const Marker = (options) => {
	const [marker, setMarker] = React.useState(null);
	const [isInfoWindowDisplayed, setInfoWindowDisplayed] = React.useState(false);

	/* eslint-disable-next-line no-undef */
	const { current: infoWindow } = React.useRef(new google.maps.InfoWindow({}));

	infoWindow.addListener('closeclick', () => {
		infoWindow.close();
		setInfoWindowDisplayed(false);
	});

	React.useEffect(() => {
		infoWindow.setContent(`
		<div style="width: 210px">
			<div class="stock_item__address_info_wrapper">
				<div style="font-weight: bold; font-size: 18px; margin-bottom: 6px"> ${options.stock.name} </div>
			</div>
			<div style="line-height: 1.0; color: grey; margin-bottom: 7px">
				${options.stock.address} <br />
				${options.stock.zip} ${options.stock.cityId.city}
			</div>

			<a href="#size-group" style="color: orange; font-weight: bold" onclick="ym(89808973,'reachGoal','choose_a_box_map_link_${options.stock.name.replaceAll(' ', '_')}')">
				${options.languageSchema.BoxBooking.chooseABox}
			</a>
		</div>
		`);
		if (marker && isInfoWindowDisplayed) {
			infoWindow.close();
			infoWindow.open(marker.getMap(), marker);
		}

	}, [options.languageSchema.BoxBooking.chooseABox]);

	React.useEffect(() => {
		if (marker && options.isStockSelected) {
			infoWindow.close();

			if(!isInfoWindowDisplayed) {
				infoWindow.open(marker.getMap(), marker);
				setInfoWindowDisplayed(true);
			}
		}

		if (!options.isStockSelected) {
			infoWindow.close();
			setInfoWindowDisplayed(false);
			setMarker(null);
		}
	}, [options.isStockSelected]);

	React.useEffect(() => {
		if (!marker) {
			/* eslint-disable-next-line no-undef */
			const newMarker = new google.maps.Marker({
				icon: stockMapMarker,
			});

			newMarker.addListener('click', () => {
				if (isInfoWindowDisplayed) {
					infoWindow.close();
					setInfoWindowDisplayed(false);
				} else {
					setTimeout(() => infoWindow.open(newMarker.getMap(), newMarker));
					setInfoWindowDisplayed(true);
				}
				options.onClick();
			});

			setMarker(newMarker);
		}

		// remove marker from map on unmount
		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);

	React.useEffect(() => {
		if (marker) {
			marker.setOptions(options);
		}
	}, [marker, options]);

	return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) =>
	(a, b) => {
		if (
			isLatLngLiteral(a) ||
			/* eslint-disable-next-line no-undef */
			a instanceof google.maps.LatLng ||
			isLatLngLiteral(b) ||
			/* eslint-disable-next-line no-undef */
			b instanceof google.maps.LatLng
		) {
			/* eslint-disable-next-line no-undef */
			return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
		}

		// TODO extend to other types

		// use fast-equals for other objects
		return deepEqual(a, b);
	}
);

function useDeepCompareMemoize(value) {
	const ref = React.useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
	React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default MapComponent;
