import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { env } from 'utils/constants';
import { setCurrentUserDetails } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import request, { getToken } from 'helpers/request';
import { isTokenValid } from 'utils/functions';
import SignUpModal from 'components/SignUp/Modal';
import LogInModal from 'components/Auth/Modal';
import Loader from 'components/common/OverlayLoader';

import NavBar from './components/NavBar';
import StocksMap from 'components/StocksMap';
import SizeGroups,  { sizesGroupsMap } from './components/SizeGroups';
import BoxesList from './components/BoxesList';
import SideBar from './components/SideBar';
import BoxBookingContextProvider from './Context/Provider';
import BoxBookingContext from './Context/';

import { scrollToDiv } from 'helpers/common';

import 'antd/dist/antd.css';

function BoxBookingNew(props) {
	const boxBookingContext = React.useContext(BoxBookingContext);

	const [stocks, setStocks] = useState([]);
	const [fetchedBoxes, setFetchedBoxes] = useState([]);
	const [selectedSizeCode, setSelectedSizeCode] = useState(null);
	const [isSignUpModalOpened, setSignUpModalOpened] = useState(false);
	const [isLogInModalOpened, setLogInModalOpened] = useState(false);

	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const stockIdFromQuery = query.get('stockId');
	const physicalSizeFromQuery = query.get('physicalSize');

	useEffect(() => {
		fetchStocks();
		// if user was signed in some time ago and opened this booking page, need to set his details
		if (isTokenValid()) {
			props.setCurrentUserDetails(getToken())
				.then(boxBookingContext.setUser);
		}
	}, []);

	useEffect(() => {
		if (stockIdFromQuery) {
			let stock = stocks.find(({ _id }) => _id === stockIdFromQuery);

			if (stock) {
				handleSetStock(stock);
			}
		}
	}, [stocks.length]);

	useEffect(() => {
		if (boxBookingContext.bookingMeta.selectedStockId) {
			fetchBoxes()
				.then(() => {
					if (window.innerWidth < 900) {
						scrollToDiv('#size-group');
					}
				});

			boxBookingContext.setSizeGroup(null);
			setSelectedSizeCode(null);
		}
	}, [boxBookingContext.bookingMeta.selectedStockId]);

	useEffect(() => {
		if (!_.isEmpty(boxBookingContext.bookingMeta.selectedSizeGroup)) {
			setTimeout(() => {
				scrollToDiv('#boxes-list');
			}, 300);
		}
	}, [boxBookingContext.bookingMeta.selectedSizeGroup]);

	//
	// useEffect(() => {
	// 	// TODO: replace with API request: fetch vat schemes from api.
	// 	const vatRate = (boxBookingContext.bookingMeta.user &&
	// 		boxBookingContext.bookingMeta.user.accountType === ACCOUNT_TYPES_MAP.legal) ?
	// 		19 :
	// 		0;
	//
	// 	boxBookingContext.setVatRate(vatRate);
	// }, [boxBookingContext.bookingMeta.user]);

	useEffect(() => {
		if (isSignUpModalOpened || isLogInModalOpened) {
			boxBookingContext.setSideBarOpened(false);
		}

		// if (boxBookingContext.bookingMeta.selectedBox && !isSignUpModalOpened && !isLogInModalOpened) {
		if (boxBookingContext.bookingMeta.selectedBox && !isSignUpModalOpened && !isLogInModalOpened) {
			boxBookingContext.setSideBarOpened(true);
		}
	}, [
		isSignUpModalOpened,
		isLogInModalOpened
	]);

	const fetchStocks = async () => {
		let fetchedStocks = await request('GET', 'stocks');

		if (!_.isEmpty(fetchedStocks)) {
			setStocks(fetchedStocks);
		}
	};

	const fetchBoxes = async () => {
		let fetchedBoxes = await request('GET', `boxes/fetch?stockId=${boxBookingContext.bookingMeta.selectedStockId}`);

		if (!_.isEmpty(fetchedBoxes)) {
			setFetchedBoxes(fetchedBoxes.boxes);

			if (physicalSizeFromQuery) {
				let corresopndedGroup = Object.keys(sizesGroupsMap).find(group => {
					let isSizeInGroup = sizesGroupsMap[group].min <= physicalSizeFromQuery &&
						physicalSizeFromQuery <= sizesGroupsMap[group].max;

					return isSizeInGroup;
				});

				if (corresopndedGroup && sizesGroupsMap[corresopndedGroup]) {
					boxBookingContext.setSizeGroup({
						sizeGroup: corresopndedGroup,
						sizeCodes: _.uniq(fetchedBoxes.boxes
							.filter(({ PhysicalSize }) => sizesGroupsMap[corresopndedGroup].min <= PhysicalSize && PhysicalSize <= sizesGroupsMap[corresopndedGroup].max)
							.map(({ PhysicalSize }) => PhysicalSize))
					});
				}
			}
		}
	};

	const handleSetStock = (stock) => {
		boxBookingContext.setStock(stock);
		boxBookingContext.setStockId(stock.stockId_sm);
	};

	const closeSideBar = () => {
		// boxBookingContext.setSelectedBox(null);
		boxBookingContext.setSideBarOpened(false);
	};

	const openSideBar = () => {
		boxBookingContext.setSideBarOpened(true);
	};

	const openSignUpModal = () => {
		setSignUpModalOpened(true);
		setLogInModalOpened(false);
	};

	const closeSignUpModal = () => setSignUpModalOpened(false);

	const openLogInModal = () => {
		setSignUpModalOpened(false);
		setLogInModalOpened(true);

		if (env === 'production') {
			ym(89808973,'reachGoal', 'login_modal_opened'); // eslint-disable-line
		}
	};
	const closeLogInModal = () => setLogInModalOpened(false);

	const handleSignUpCompleted = (signUpResult) => {
		boxBookingContext.setUser(signUpResult);
		closeSignUpModal();

		if (env === 'production') {
			ym(89808973,'reachGoal', 'signup_completed'); // eslint-disable-line
		}
	};

	const handleLogInCompleted = (logInResult) => {
		boxBookingContext.setUser(logInResult.user);
		closeLogInModal();
	};

	return (
		<div className='mb-5 pb-5'>
			<Loader />

			<NavBar
				selectedSizeGroup={boxBookingContext.bookingMeta.selectedSizeGroup}
				selectedSizeCode={selectedSizeCode}
			/>

			<StocksMap
				stocks={stocks}
				setStock={handleSetStock}
				languageSchema={props.languageSchema}
				language={props.language}
				defaultSelectedStock={boxBookingContext.bookingMeta.selectedStock}
			/>

			{
				boxBookingContext.bookingMeta.selectedStockId &&
				<>
					<SizeGroups
						selectedStockId={boxBookingContext.bookingMeta.selectedStockId}
						selectedSizeGroup={boxBookingContext.bookingMeta.selectedSizeGroup}
						setSelectedSizeGroup={boxBookingContext.setSizeGroup}
						fetchedBoxes={fetchedBoxes}
					/>
					<div id='boxes-list'></div>
				</>
			}

			{/* {
				selectedSizeGroup &&
				<SizeCodes
					fetchedBoxes={fetchedBoxes}
					selectedSizeCode={selectedSizeCode}
					setSelectedSizeCode={setSelectedSizeCode}
					selectedSizeGroup={selectedSizeGroup}
				/>
			} */}

			{
				boxBookingContext.bookingMeta.selectedSizeGroup &&
				<BoxesList
					selectedSizeGroup={boxBookingContext.bookingMeta.selectedSizeGroup}
					fetchedBoxes={fetchedBoxes}
				/>
			}

			<SideBar
				selectedBox={boxBookingContext.bookingMeta.selectedBox}
				selectedStockId={boxBookingContext.bookingMeta.selectedStockId}
				isOpened={boxBookingContext.bookingMeta.isSidebarOpened}
				close={closeSideBar}
				open={openSideBar}
				openSignUpModal={openSignUpModal}
			/>

			{
				isSignUpModalOpened &&
				<SignUpModal
					isVisible={isSignUpModalOpened}
					onSignUpCompleted={handleSignUpCompleted}
					onCancel={closeSignUpModal}
					onLogInAction={openLogInModal}
				/>
			}

			{
				isLogInModalOpened &&
				<LogInModal
					isVisible={isLogInModalOpened}
					onLogInCompleted={handleLogInCompleted}
					onCancel={closeLogInModal}
					onSignUpAction={openSignUpModal}
				/>
			}
		</div>
	);
}

const mapStateToProps = state => ({
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language),
	currentUser: state.auth.me
});

const mapDispatchToProps = {
	setCurrentUserDetails
};

const BoxBookingWithConnect = connect(mapStateToProps, mapDispatchToProps)(BoxBookingNew);

export default () => {
	return (
		<BoxBookingContextProvider>
			<BoxBookingWithConnect />
		</BoxBookingContextProvider>
	);
};
