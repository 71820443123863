import React from 'react';

import Table from 'components/UI/Table';
import Heading from 'components/UI/Heading';
import NoItems from 'components/common/NoItems';

import ListItem from './ListItem';

class EndingContractsList extends React.PureComponent {
	renderList = penaltyEmails => (
		<Table
			centerHeader
			header={[
				'Client',
				'Invoice Id',
				'Email Type',
			]}
		>
			{
				penaltyEmails.map(email =>
					<ListItem
						key={email._id}
						email={email}
						languageSchema={this.props.languageSchema}
					/>
				)
			}

		</Table>
	);

	render() {
		let { languageSchema, penaltyEmails } = this.props;

		return (
			<div className="card">
				<Heading>
					{languageSchema.HomePage.notificationsOfPenalty}
				</Heading>

				<div className="mb-3" style={{ fontSize: '14px' }}>
					{penaltyEmails.filter((email) => email.isCompleted === false).length > 0 ?
						this.renderList(penaltyEmails) :
						<div className="center"><NoItems isLogo={false} /></div>
					}
				</div>
			</div>
		);
	}
}

export default EndingContractsList;
