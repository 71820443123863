import React from 'react';
import { Link } from 'react-router-dom';

import {
	Alert,
} from 'antd';
import { connect } from 'react-redux';
import { openBox } from 'redux/actions';
import { toastr } from 'react-redux-toastr';

import Card from 'components/UI/Card';

const ListItem = props => {
	const { box, languageSchema, user, stock } = props;
	const isBoxPaid = box.paidAhead >= 0;

	const openBox = (box) => async () => {
		toastr.confirm(props.languageSchema.Stock.areYouSureYouWantToOpenBox, {
			onOk: () => {
				props.openBox(box);
			}
		});
	};

	return (
		<Card grid={6}>
			<div className="card-title flex-wrap d-flex justify-content-between">
				<div>
					<Link
						style={{ textDecoration: 'underline' }}
						to={{
							pathname: `/boxes/${box._id}`,
							state: {
								ContractID: box.ContractID,
								invoices: box.invoices,
								stockId: stock._id,
								boxId: box._id,
							}
						}}
					>
						<h5>{box.UnitNumber}</h5>
					</Link>
				</div>
				<div>
					<Link
						className="btn btn-outline-primary"
						to={{
							pathname: `/users/${user._id}/contracts/${box.ContractID}`,
						}}
					>
						{languageSchema.Contract.contractDetails}
					</Link>
				</div>
			</div>
			<div className="card-text">
				<div>
					{languageSchema.Contract.boxsize}: <b>{box.PhysicalSize}{languageSchema.Etc.metersShortLetter}²</b>
					{
						box.Depth && box.Width && box.Height &&
						<span className="text-bold">{'   '}({box.Height}m x {box.Depth}m x {box.Width}m) </span>
					}
				</div>
			</div>
			<div className="d-flex justify-content-between">
				<div>
					<div className={`${!isBoxPaid ? 'font-red' : ''}`}>
						{languageSchema.Contract.paidInDays}: {isBoxPaid ? box.paidAhead : 0}
						{
							box.paidAhead <= 0 &&
							<Alert
								message={
									<div>
										<span>
											{languageSchema.Contract.zeroPaidDaysDescription }
										</span>
									</div>
								}
								type="info"
							/>
						}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between flex-wrap">
				<div>
					<div>
						{languageSchema.User.pinCode}: <b>*{box.Password}#</b>
					</div>
					<div className='mt-1'>
						{languageSchema.User.lockAvailability}: <b className={`${box.lockStatus === 'closed' ? 'font-red' : 'font-green'}`}>{languageSchema.User[box.lockStatus]}</b>
					</div>
				</div>

				{
					Boolean(box.lockId) &&
					<div>
						<button style={{ marginLeft: '5px', marginTop: '10px' }}
							className="btn btn-md btn-success"
							onClick={openBox(box)}
						>
							<i className={'fas fa-key'}> {' '} </i>
							{' '} {languageSchema.Buttons.open}
						</button>
					</div>
				}

			</div>
		</Card>
	);
};


let mapStateToProps = ({ common }) => {
	return {
		language: common.language,
	};
};

let mapDispatchToProps = {
	openBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
