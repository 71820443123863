import React, { useState, useEffect, useRef } from 'react';
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_PAN } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';
import {connect} from 'react-redux';
import { useLongPress } from 'react-use';
import { mapIsDragging } from 'redux/actions';
import { useDebounce } from 'utils/hooks';

window.addEventListener('touchstart', function(event) {
	// some logic
	event.preventDefault(); // <-- that should not be used in passive
	// some other magic
});
const SVGRendererWithZoom = (props) => {
	let {
		objects,
		onRender,
		onMouseOver = () => {},
		selectedBoxUnitNumber,
		objectTypes,
		setViewers,
		viewers,
		mapName,
		onTouchStart,
		onTouchEnd,
		selectedSizeCodes,
		clickedUnitNumber,
		language,
		isMapDragging,
	} = props;

	let [tool, setTool] = useState(TOOL_PAN);
	let [value, setValue] = useState(INITIAL_VALUE);
	let [zoom, setZoom] = useState(INITIAL_VALUE);
	let [boxesElements, setBoxesElements] = useState(null);
	let Viewer = useRef(null);

	let debouncedZoom = useDebounce(zoom, 200);

	let getObjectComponent = (type) => {
		return objectTypes[type];
	};

	let handleOnLongPress = () => {
		props.mapIsDragging(true);
	};

	let handleOnMouseUp = () => {
		if (isMapDragging) {
			props.mapIsDragging(false);
		}
	};

	let defaultOptions = {
		isPreventDefault: true,
		delay: 170,
	};

	const longPressEvent = useLongPress(handleOnLongPress, defaultOptions);

	const renderObject = (object, index) => {
		let Renderer = getObjectComponent(object.type);

		return (
			<Renderer
				/*	onRender={(ref) => objectRefs[index] = ref} temporary commented, cause unclear behavior with Text components*/
				onMouseOver={onMouseOver.bind(this, index)}
				object={object}
				key={index}
				index={index}
			/>
		);
	};

	useEffect(() => {
		Viewer.zoomOnViewerCenter(0.8);

		setViewers({
			...viewers,
			[mapName]: Viewer
		});
	}, []);

	useEffect(() => {
		if (!isMapDragging) {
			setBoxesElements(objects.map(renderObject));
		}
	}, [selectedBoxUnitNumber, selectedSizeCodes, clickedUnitNumber, language, isMapDragging, debouncedZoom]);

	const changeTool = (nextTool) => {
		if (nextTool === 'zoom-in') {
			return Viewer.zoomOnViewerCenter(1.1);
		}

		if (nextTool === 'zoom-out') {
			return Viewer.zoomOnViewerCenter(0.9);
		}

		return setTool(nextTool);
	};


	return (
		<div {...longPressEvent} style={{  minWidth: '320px', minHeight: '335px', flexGrow: 2, marginLeft: '-10px' }}>
			<div onMouseUp={handleOnMouseUp} style={{ width: '100%', height: '100%', minHeight: window.innerHeight * 0.65, }}>
				<AutoSizer>
					{({ width, height }) => {
						return (
							<ReactSVGPanZoom
								width={width}
								height={height}
								ref={viewer => (Viewer = viewer)}
								tool={'auto'}
								onChangeTool={tool => changeTool(tool)}
								value={value}
								onChangeValue={setValue}
								background="#FFF"
								detectAutoPan={false}
								detectPinchGesture={true}
								detectWheel={true}
								scaleFactorMin={0.6}
								toolbarProps={{
									position: 'none'
								}}
								miniatureProps={{
									position: 'none'
								}}
								onTouchMove={onTouchStart ? onTouchStart : () => {}}
								onTouchEnd={onTouchEnd ? onTouchEnd : () => {}}
								onZoom={setZoom}
							>
								<svg
									ref={onRender}
									width={900}
									height={height}
									isRoot={true}
								>
									{ boxesElements }
								</svg>
							</ReactSVGPanZoom>
						);}}
				</AutoSizer>
			</div>
		</div>
	);
};

export const styles = {
	canvas: {
		backgroundSize: 400
	},
	grid: {
		backgroundSize: 'auto'
	}
};

const mapStateToProps = ({ common, boxes }) => ({
	isMapDragging: boxes.isMapDragging,
	language: common.language,
});

let mapDispatchToProps = {
	mapIsDragging
};

export default connect(mapStateToProps, mapDispatchToProps)(SVGRendererWithZoom);