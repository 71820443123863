import _ from 'lodash';
const initialState = {
	data: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case 'CALL_REQUEST':
		return { ...state};

	case 'APPEAL_ADDED': {
		return {
			...state,
			data: [
				action.payload.data,
				...state.data,
			]
		};
	}

	case 'APPEAL_HANDLED': {
		let handledAppeal = action.payload;
						
		let indexOfAppealToUpdate = _.findIndex(
			state.data,
			({ _id }) => _id === handledAppeal._id
		);

		let newAppealsArray = [...state.data];
		newAppealsArray[indexOfAppealToUpdate].status = handledAppeal.status;

		return {
			...state,
			data: newAppealsArray,
		};
	}

	case 'FETCH_APPEALS':
		return {
			...state,
			data: action.payload,
		};

	default:
		return state;
	}
}
