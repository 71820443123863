import React from 'react';
import { connect } from 'react-redux';
import NoItems from 'components/common/NoItems';
import history from 'utils/history';
import { getLanguageSchema } from 'dashboard-commons/languages';
import ReservationItem from './ReservationItem';

const ReservationsList = props => {
	const { languageSchema, reservations, fetchData, cancelReservation, user } = props;

	return (
		<div>
			<div className="col-md-12">
				<div className="card">
					<div>
						<button className="mb-3 btn btn-sm btn-outline-primary" onClick={() => history.goBack()}>
							{languageSchema.BoxBooking.back}
						</button>
					</div>
					<h5 className="card-title d-flex justify-content-between">
						{languageSchema.BoxBooking.reservations}
					</h5>

					{ /*
					<div className="mb-3">
						<div className="alert alert-info alert-dismissible fade show" role="alert">
							{languageSchema.BoxBooking.enterIntoContractExplanation}
						</div>
					</div>
					*/}

					{
						reservations.length === 0 ?
							<NoItems /> :
							<div className="inline-flex flex-wrap">
								{
									reservations.map(
										reservation =>
											<ReservationItem
												key={reservation.ReservationID}
												reservation={reservation}
												languageSchema={languageSchema}
												fetchData={fetchData}
												cancelReservation={cancelReservation}
												user={user}
											/>
									)
								}
							</div>
					}
				</div>
			</div>
		</div>
	);
};

let mapStateToProps = ({ common }) => {
	return {
		language: common.language,
		languageSchema: getLanguageSchema(common.language),
	};
};

export default connect(mapStateToProps)(ReservationsList);
