import React from 'react';
import { Field } from 'formik';

import { PHONE_INPUT_REGEXP } from 'utils/constants';
import CountryPicker from 'components/common/CountryPicker';
import PasswordInput from 'components/common/PasswordInput';

export default function IndividualUserFormFields(props) {
	let {
		isEditForm, isCurrentUserAdmin, languageSchema, values, errors, isError,
		language, setFieldValue,
	} = props;

	let isDisabledField = isEditForm && !isCurrentUserAdmin;
	let isNeedToShowRedStar = !isEditForm;

	let handlePhoneChange = (e) => {
		if (e.target.value === '' || PHONE_INPUT_REGEXP.test(e.target.value)) {
			setFieldValue('phone', e.target.value);
		}
	};

	let handlePeselChange = (e) => {
		setFieldValue('pesel', e.target.value);
	};

	React.useEffect(() => {
		if (values.phone.includes('+')) {
			setFieldValue('phone', values.phone.replace('+', ''));
		}
	}, [values.phone]);

	return (
		<div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group ">
						<label htmlFor="firstName" className=" col-form-label">
							{languageSchema.User.name}
							{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
						</label>
						<div>
							<Field
								disabled={isDisabledField}
								type="text"
								className={`form-control ${isError('firstName') ? 'is-invalid' : ''}`}
								id="firstName"
								name="firstName"
								value={values.firstName}
							/>
							{isError('firstName') && (
								<div className="invalid-feedback">{errors.firstName}</div>
							)}
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<div className="form-group ">
						<label htmlFor="lastName" className=" col-form-label">
							{languageSchema.User.sureName}
							{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
						</label>
						<div>
							<Field
								type="text"
								disabled={isDisabledField}
								className={`form-control ${isError('lastName') ? 'is-invalid' : ''}`}
								id="lastName"
								name="lastName"
								value={values.lastName}
							/>
							{isError('lastName') && (
								<div className="invalid-feedback">{errors.lastName}</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label htmlFor="address" className=" col-form-label">
							{languageSchema.User.address}
							{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
						</label>
						<div>
							<Field
								type="text"
								disabled={isDisabledField}
								className={`form-control ${isError('address') ? 'is-invalid' : ''}`}
								id="address"
								value={values.address}
							/>
							{isError('address') && (
								<div className="invalid-feedback">{languageSchema.User.addressRequired}</div>
							)}
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<div className="form-group">
						<label htmlFor="house" className=" col-form-label">
							{languageSchema.User.houseNumber}
							{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
						</label>
						<div>
							<Field
								disabled={isDisabledField}
								type="text"
								className={`form-control ${isError('house') ? 'is-invalid' : ''}`}
								id="house"
								name="house"
								value={values.house}
							/>
							{isError('house') && (
								<div className="invalid-feedback">{languageSchema.User.houseRequired}</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="form-group " style={{ clear: 'both'}}>
				<label htmlFor="pesel" className=" col-form-label">
					{languageSchema.User.pesel}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div>
					<Field
						type="text"
						disabled={isDisabledField}
						className={`form-control ${isError('pesel') ? 'is-invalid' : ''}`}
						id="pesel"
						value={values.pesel}
						onChange={handlePeselChange}
					/>
					{isError('pesel') && <div className="invalid-feedback">{errors.pesel}</div>}
				</div>
			</div>

			<div className="form-group " style={{ clear: 'both'}}>
				<label htmlFor="zip" className=" col-form-label">
					{languageSchema.User.zip}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div>
					<Field
						type="text"
						disabled={isDisabledField}
						className={`form-control ${isError('zip') ? 'is-invalid' : ''}`}
						id="zip"
						value={values.zip}
					/>
					{isError('zip') && <div className="invalid-feedback">{errors.zip}</div>}
				</div>
			</div>

			<div className="form-group ">
				<label htmlFor="city" className=" col-form-label">
					{languageSchema.User.city}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div>
					<Field
						type="text"
						disabled={isDisabledField}
						className={`form-control ${isError('city') ? 'is-invalid' : ''}`}
						id="city"
						value={values.city}
					/>
					{isError('city') && <div className="invalid-feedback">{errors.city}</div>}
				</div>
			</div>

			<div className="form-group ">
				<label htmlFor="country" className=" col-form-label">
					{languageSchema.User.country}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div>
					<Field
						component={CountryPicker}
						disabled={isDisabledField}
						className={`form-control ${isError('country') ? 'is-invalid' : ''}`}
						id="country"
						name="country"
						value={values.country}
						languageSchema={languageSchema}
						language={language}
					/>
					{isError('country') && (
						<div className="invalid-feedback">{errors.country}</div>
					)}
				</div>
			</div>

			<div className="form-group ">
				<label htmlFor="phone" className="col-form-label">
					{languageSchema.User.phone}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div className="d-flex align-items-center">
					<span className="mr-1">+</span>
					<Field
						type="tel"
						disabled={isDisabledField}
						className={`form-control ${isError('phone') ? 'is-invalid' : ''}`}
						id="phone"
						value={values.phone}
						placeholder="481231231231"
						onChange={handlePhoneChange}
					/>
					{isError('phone') && (
						<div className="invalid-feedback">{errors.phone}</div>
					)}
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="email" className=" col-form-label">
					{languageSchema.User.email}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div>
					<Field
						disabled={isDisabledField}
						type="text"
						className={`form-control ${isError('email') ? 'is-invalid' : ''}`}
						id="email"
						value={values.email}
					/>
					{isError('email') && (
						<div className="invalid-feedback">{errors.email}</div>
					)}
				</div>
			</div>

			<div className="form-group ">
				<label htmlFor="password" className=" col-form-label">
					{languageSchema.User.password}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<div>
					<PasswordInput
						id="password"
						name="password"
						className={`form-control ${isError('password') ? 'is-invalid' : ''}`}
						value={values.password}
						placeholder={languageSchema.LogIn.password}
						languageSchema={languageSchema}
					/>
					{isError('password') && (
						<div className="invalid-feedback">{errors.password}</div>
					)}
				</div>
			</div>

		</div>
	);
}
