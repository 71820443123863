import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toastr } from 'react-redux-toastr';
import { Link, Redirect } from 'react-router-dom';

import { getLanguageSchema } from 'dashboard-commons/languages';
import { timeout, getCurrentUserIdFromToken } from 'utils/functions';
import { ROLES_MAP } from 'utils/constants';
import UserForm from 'components/User/Form';
import ChangePwdForm from 'components/User/ChangePwdForm';
import Heading from 'components/UI/Heading';
import KYCForm from 'components/User/KYCForm';
import {
	fetchUsers,
	updateUser,
	deleteUser,
	resetUserPassword,
} from 'redux/actions';
import request from 'helpers/request';

import { validationChangePasswordSchema } from './validation';


class UserPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authToken: null,
			isSuccess: false,
			user: null,
		};
	}
	async componentDidMount() {
		const {
			isFetchedUsers,
			fetchUsers,
			match
		} = this.props;

		if (!isFetchedUsers) {
			fetchUsers();
		}

		let userId = match.params.id;

		let user = await request('GET', `users/${userId}`);

		this.setState({ user });
	}

	handleDeleteClick = () => {
		const {
			history: { push },
			deleteUser,
			languageSchema
		} = this.props;

		let { user } = this.state;

		toastr.confirm(languageSchema.User.areYouSureYouWantToDeleteAccount, {
			onOk: async () => {
				await deleteUser(user._id);
				push('/users');
			}
		});

	}

	handleSubmitUserForm = async (values, { setSubmitting }) => {
		toastr.confirm(this.props.languageSchema.Etc.areYouSureYouWantToSaveChanges, {
			onOk: async () => {
				try {
					const { updateUser, fetchUsers } = this.props;

					// set all phone numbers to one format with +
					let accountData = {
						...values,
						phone: `+${values.phone}`,
					};

					setSubmitting(true);

					await updateUser(this.state.user._id, accountData);

					this.setState({ isSuccess: true }, async () => {
						await timeout(2000);
						this.setState({ isSuccess: false });
					});
					await fetchUsers();
				} finally {
					setSubmitting(false);
				}
			},
			onCancel: () => setSubmitting(false),
		});
	}

	handleSubmitPasswordChange = async (values, { setSubmitting, setFieldError }) => {
		let { updateUser, languageSchema } = this.props;

		if (values.password !== values.passwordConfirmation) {
			setFieldError('password', languageSchema.validation.validatePasswordConfirmation());
			setFieldError('passwordConfirmation', languageSchema.validation.validatePasswordConfirmation());
			setSubmitting(false);

			return;
		}

		toastr.confirm(languageSchema.User.areYouSureYouWantToChangePassword, {
			onOk: async () => {
				try {
					setSubmitting(true);

					await updateUser(this.state.user._id, values);

					toastr.success(
						languageSchema.User.passwordChanged,
						languageSchema.User.passwordWasSuccessfullyChanged
					);
				} finally {
					setSubmitting(false);
				}
			},
			onCancel: () => setSubmitting(false),
		});
	}

	setAccountType = accountType => this.setState({ accountType });

	render() {
		const { isSuccess, user } = this.state;
		const { fetchUsers, languageSchema, language, loggedInUser } = this.props;

		const currentUserId = getCurrentUserIdFromToken();

		if (!user) {
			return null;
		}

		const ADMIN_ROLES = [ROLES_MAP.superUser];

		let isCurrentUserAdmin = loggedInUser.role && ADMIN_ROLES.includes(loggedInUser.role);
		let isCurrentUserOwner = currentUserId === user._id;

		if (user._id && !isCurrentUserAdmin && !isCurrentUserOwner) {
			return <Redirect to="/" />;
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="card">
							<Heading>{languageSchema.User.accountDetails}</Heading>
							<div className="d-flex flex-space-between mb-2">
								{
									isCurrentUserAdmin &&
									<div className="text-left">
										<button className="btn btn-danger btn-sm" onClick={this.handleDeleteClick}>
											{languageSchema.Buttons.delete}
										</button>
									</div>
								}
								{
									isCurrentUserAdmin &&
									<Link
										to={{
											pathname: `/users/${user._id}/contracts`,
										}}
										className="text-right"
									>
										{languageSchema.User.goToContracts}
									</Link>
								}
							</div>

							<Formik
								initialValues={{ ...user }}
								onSubmit={this.handleSubmitUserForm}
								render={props =>
									<UserForm
										type="edit"
										{...props}
										languageSchema={languageSchema}
										currentUser={loggedInUser}
										language={language}
										isSuccess={isSuccess}
										setAccountType={this.setAccountType}
									/>
								}
							/>
							<hr />
						</div>
					</div>

					<div className="col-md-6">
						<KYCForm
							languageSchema={languageSchema}
							user={user}
							fetchUsers={fetchUsers}
							loggedInUser={loggedInUser}
						/>

						{
							user._id === loggedInUser._id &&
							<div>
								<Formik
									initialValues={{ password: '', passwordConfirmation: '' }}
									onSubmit={this.handleSubmitPasswordChange}
									validationSchema={validationChangePasswordSchema(languageSchema)}
									render={props =>
										<ChangePwdForm
											{...props}
											user={user}
											languageSchema={languageSchema}
										/>
									}
								/>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, common }, props) => {
	const currentId = props.match.params.id;
	const currentUser = users.data.find(user => user._id === currentId);

	return {
		user: currentUser,
		isFetchedUsers: users.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
		loggedInUser: auth.me,
	};
};
const mapDispatchToProps = {
	fetchUsers,
	updateUser,
	deleteUser,
	resetUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
