import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import querystring from 'querystring';

import { Elements } from '@stripe/react-stripe-js';
import {loadStripe } from '@stripe/stripe-js';
// import { stripePublicKey } from 'utils/constants';
import { apiStartLoading, apiEndLoading } from 'redux/actions/common';

import { getLanguageSchema } from 'dashboard-commons/languages';

import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function Checkout(props) {
	let [stripe, setUpStripe] = useState(null);

	let paymentData = props.paymentData ||
		querystring.parse(props.location.search.slice(1));

	console.log('paymentData', paymentData);
	useEffect(() => {
		if (paymentData && paymentData.publicKey) {

			let stripePromise = loadStripe(paymentData.publicKey, {
				locale: props.language.toLowerCase()
			});

			setUpStripe(stripePromise);
		}
	}, []);

	if (!stripe) {
		return null;
	}

	return (
		<div className="payment_form">
			<div className="flex-center">
				<Elements
					stripe={stripe}
					locale={props.language.toLowerCase()}
				>
					<CheckoutForm
						paymentData={paymentData}
						languageSchema={props.languageSchema}
						apiStartLoading={props.apiStartLoading}
						apiEndLoading={props.apiEndLoading}
					/>
				</Elements>
			</div>
		</div>
	);
}

let mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};

let mapDispatchToProps = {
	apiStartLoading,
	apiEndLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
