import React, { useEffect, useRef } from 'react';

import SVGRendererWithZoom from './SVGRendererWithZoom';
import {Text, Path, Rect, Circle, Image, RectWithText, PathWithText} from './objects';

const Preview = (props) => {
	const defaultObjectTypes = {
		'text': Text,
		'rectangle': Rect,
		'circle': Circle,
		'polygon': Path,
		'image': Image,
		'rectangle-with-text': RectWithText,
		'polygon-with-text': PathWithText,
	};
	let {width, height, objects, objectTypes = defaultObjectTypes, responsive = false, selectedBoxUnitNumber} = props;
	let objectRefs = useRef(null);
	let svgElement = useRef(null);

	let canvas = {
		width: '100%' || responsive ? '100%' : width,
		height: '100%' || responsive ? '100%' : height,
		canvasWidth: responsive ? '100%' : width,
		canvasHeight: responsive ? '100%' : height
	};

	useEffect(() => {
		if (responsive) {
			objects = objects.map(object => ({
				...object,
				width: (object.width / width) * 100 + '%',
				height: (object.height / height) * 100 + '%',
				x: (object.x / width)*100 + '%',
				y: (object.y / height)*100 + '%',
			}));
		}
	}, [responsive]);


	return (
		<div>
			<SVGRendererWithZoom
				objects={objects}
				objectRefs={objectRefs}
				objectTypes={objectTypes}
				onRender={(ref) => svgElement = ref}
				canvas={canvas}
				setViewers={props.setViewers}
				viewers={props.viewers}
				mapName={props.mapName}
				onTouchEnd={props.onTouchEnd}
				onTouchStart={props.onTouchStart}
				selectedBoxUnitNumber={selectedBoxUnitNumber}
				selectedSizeCodes={props.selectedSizeCodes}
				clickedUnitNumber={props.clickedUnitNumber}
			/>
		</div>
	);
};

export default Preview;
