import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAdmin } from 'utils/functions';
import Card from 'components/UI/Card';
import countries from 'i18n-iso-countries';

import enLocale from 'i18n-iso-countries/langs/en.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';

countries.registerLocale(enLocale);
countries.registerLocale(deLocale);
countries.registerLocale(ruLocale);

const ListItem = props => {
	const { languageSchema, user, language: lang, hasBoxes} = props;
	const isCurrentUserAdmin = user.role && isAdmin(user.role);
	const language = lang.toLowerCase();

	const cityName = props.cityId ? props.cityId.city : props.city;
	const countryName = props.cityId ? props.cityId.country : props.country;

	return (
		<Card>
			<h5 className="card-title d-flex justify-content-between">
				<div>{props.name}</div>
			</h5>
			{hasBoxes &&
				<div className="badge badge-success">
					{languageSchema.Stock.boxRenting}
				</div>
			}
			<p className="card-text">
				{props.address}<br />
				{cityName}, {countries.getName(countryName, language)}
			</p>
			<div className="d-flex justify-content-between">
				{
					isCurrentUserAdmin &&
					<div>
						<Link to={`/stocks/${props._id}`} className="btn btn-sm btn-outline-secondary mr-2">
							{ languageSchema.Buttons.settings }
						</Link>
						<Link to={`/stocks/${props._id}/history`} className="btn btn-sm btn-outline-secondary">
							<span>{languageSchema.Stock.history}</span>
							<FontAwesomeIcon icon="history" />
						</Link>
					</div>
				}
				<div>
					{hasBoxes &&
						<Link to={`/boxes?stockId=${props._id}`} className="btn btn-sm btn-outline-primary mr-2">
							{languageSchema.Stock.boxes}
						</Link>
					}
				</div>

			</div>
		</Card>
	);
};

export default ListItem;
